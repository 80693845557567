import React, { useMemo, useState } from 'react';
import LocalizedStrings from 'react-localization';
import {
  FlatList,
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
} from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { ANALYTICS } from '../../gql/gqls';
import { distanceInTime } from '../../components/misc/helper';
import { myAccountVar } from '../../reactiveVariables';
import Avatar from '../../components/Avatar';
import Stat from '../../components/Stat';
import Fetching from '../../components/Fetching';
import Select from '../../components/Select';
import ProfileCard from '../../components/ProfileCard';
import config from '../../config';
import infoIcon from '../../assets/icons/info.png';

import {
  colors,
  fonts,
  gutters,
  mainTitle,
  mediaQueries,
  screenStyles,
  useMobileSize,
  commonStyles,
  fontsize,
} from '../../theme';

export default function Events() {
  const isMobile = useMobileSize();
  const history = useHistory();
  const user = useReactiveVar(myAccountVar);
  const [eventType, setEventType] = useState('all');
  const { data, loading, error } = useQuery(ANALYTICS, {
    fetchPolicy: 'network-only',
    pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
    variables: {
      type: eventType,
    },
    onCompleted: (data) => {
      // alert(JSON.stringify(data.partnerAnalytics));
    },
  });

  const selectOptions = [
    {
      value: 'all',
      text: strings.all,
    },
    {
      value: 'click',
      text: strings.click,
    },
    {
      value: 'recruiter',
      text: strings.recruiter,
    },
    {
      value: 'subfee',
      text: strings.recruitedSubscribers,
    },
    {
      value: 'recUser',
      text: strings.recruitedUsers,
    },
    {
      value: 'recChannel',
      text: strings.recruitedChannels,
    },
    {
      value: 'sponsorGeneric',
      text: strings.sponsorGeneric,
    },
    {
      value: 'sponsorChannel',
      text: strings.sponsorChannel,
    },
    {
      value: 'sponsorAd',
      text: strings.sponsorAd,
    },
    {
      value: 'recSeller',
      text: strings.sales,
    },
    {
      value: 'join',
      text: strings.join,
    },
    {
      value: 'launch',
      text: strings.launch,
    },
  ];

  const DATA = data?.partnerAnalytics;
  const analytics = DATA?.analytics;
  const myTeam = DATA?.myTeam;
  const events = DATA?.events;

  const statPairStyle = useMemo(
    () => ({
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'center',
      justifyContent: isMobile ? 'flex-start' : 'space-around',
      flexGrow: 1,
    }),
    [isMobile]
  );

  const statSingleStyle = useMemo(
    () => ({
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      paddingLeft: isMobile ? 0 : 25,
      flexGrow: 1,
    }),
    [isMobile]
  );

  return (
    <View style={[{ paddingHorizontal: isMobile ? 15 : 0 }, screenStyles.main]}>
      <View style={screenStyles.innerBody}>
        <View
          style={{
            maxWidth: mediaQueries.tablet,
            width: '100%',
            marginBottom: 20,
          }}
        >
          <Text style={mainTitle(isMobile)}>{strings.shareAnalytics}</Text>
          <View style={styles.row}>
            <Text style={[commonStyles.h3, { marginBottom: 16 }]}>{strings.yourShare}</Text>
            {/* <TouchableOpacity onPress={() => history.push(`/stat-helper`)}>
              <Image source={infoIcon} style={{ height: 24, width: 24, marginBottom: 10  }} />
            </TouchableOpacity> */}
          </View>
          <View style={styles.statContainer}>
            <Title
              color={colors.green}
              text={strings.recruitmentOf}
              textStyle={{ font: fonts.medium }}
              isMobile={isMobile}
            />
            <View style={styles.statRow}>
              <View style={statPairStyle}>
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.recruitedSponsors}
                  value={analytics?.recruitedSponsors | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.recruitedSalesmen}
                  value={analytics?.recruitedSalesmen | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.recruitedUsers}
                  value={analytics?.recruitedUsers | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />

                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.recruitedChannels}
                  value={analytics?.recruitedChannels | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.recruitedSubscribers}
                  value={analytics?.recruitedSubscribers | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
              </View>
            </View>
          </View>
          <View style={styles.statContainer}>
            <Title
              color={colors.deepBlue}
              text={strings.revenueFrom}
              isMobile={isMobile}
            />
            <View style={styles.statRow}>
              <View style={statPairStyle}>
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.subscriberReward}
                  value={
                    ((analytics?.subscriberReward | 0) * user.veoRate).toFixed(
                      2
                    ) +
                    ' ' +
                    user.currency
                  }
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.sponsorsGenericReward}
                  value={
                    (
                      (analytics?.sponsorsGenericReward | 0) *
                      user.veoRate
                    ).toFixed(2).replace(/[.,]00$/, "") +
                    ' ' +
                    user.currency
                  }
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.sponsorsChannelReward}
                  value={
                    (
                      (analytics?.sponsorsChannelReward | 0) *
                      user.veoRate
                    ).toFixed(2).replace(/[.,]00$/, "") +
                    ' ' +
                    user.currency
                  }
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.sponsorsAdsReward}
                  value={
                    ((analytics?.sponsorsAdsReward | 0) * user.veoRate).toFixed(
                      2
                    ) +
                    ' ' +
                    user.currency
                  }
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.recruiterReward}
                  value={
                    ((analytics?.recruiterReward | 0) * user.veoRate).toFixed(
                      2
                    ) +
                    ' ' +
                    user.currency
                  }
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
              </View>
            </View>
          </View>
          <View style={styles.statContainer}>
            <Title
              color={colors.orange}
              text={strings.other}
              isMobile={isMobile}
            />
            <View style={styles.statRow}>
              <View style={statPairStyle}>
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.shareClicks}
                  value={analytics?.shareClicks | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
                {/* <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.registeredJoin}
                  value={analytics?.registeredJoin | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                /> */}
                {/* <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.registeredLaunch}
                  value={analytics?.registeredLaunch | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                /> */}
              </View>
            </View>
          </View>
          {/* <View style={styles.statContainer}>
            <Title
              color={colors.orange}
              text={strings.other}
              isMobile={isMobile}
            />
            <View style={styles.statRow}>
              <View style={statSingleStyle}>
                
              </View>
            </View>
          </View> */}
          <View style={styles.row}>
            <Text style={[commonStyles.h3, { marginBottom: 16 }]}>{strings.teamShare}</Text>
            {/* <TouchableOpacity onPress={() => history.push(`/stat-helper`)}>
              <Image source={infoIcon} style={{ height: 24, width: 24, marginBottom: 10 }} />
            </TouchableOpacity> */}
          </View>
          <View style={styles.statContainer}>
            <Title
              color={colors.green}
              text={strings.recruitmentOf}
              isMobile={isMobile}
            />
            <View style={styles.statRow}>
              <View style={statPairStyle}>
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.recruitedSponsors}
                  value={analytics?.teamRecruitedSponsors | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.recruitedUsers}
                  value={analytics?.teamRecruitedUsers | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.recruitedChannels}
                  value={analytics?.teamRecruitedChannels | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.recruitedSubscribers}
                  value={analytics?.teamRecruitedSubscribers | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
              </View>
            </View>
          </View>
          <View style={styles.statContainer}>
            <Title
              color={colors.deepBlue}
              text={strings.revenueFrom}
              isMobile={isMobile}
            />
            <View style={styles.statRow}>
              <View style={statPairStyle}>
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.subscriberReward}
                  value={
                    (
                      (analytics?.teamSubscriberReward | 0) *
                      user.veoRate
                    ).toFixed(2).replace(/[.,]00$/, "") +
                    ' ' +
                    user.currency
                  }
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />

                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.sponsorsGenericReward}
                  value={
                    (
                      (analytics?.teamSponsorsGenericReward | 0) *
                      user.veoRate
                    ).toFixed(2).replace(/[.,]00$/, "") +
                    ' ' +
                    user.currency
                  }
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.sponsorsChannelReward}
                  value={
                    (
                      (analytics?.teamSponsorsChannelReward | 0) *
                      user.veoRate
                    ).toFixed(2).replace(/[.,]00$/, "") +
                    ' ' +
                    user.currency
                  }
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.sponsorsAdsReward}
                  value={
                    (
                      (analytics?.teamSponsorsAdsReward | 0) *
                      user.veoRate
                    ).toFixed(2).replace(/[.,]00$/, "") +
                    ' ' +
                    user.currency
                  }
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />

              </View>
            </View>
          </View>
          <View style={styles.statContainer}>
            <Title
              color={colors.orange}
              text={strings.other}
              isMobile={isMobile}
            />
            <View style={styles.statRow}>
              <View style={statPairStyle}>

                <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.teamShareClicks}
                  value={analytics?.teamShareClicks | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />

                {/* <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.registeredJoin}
                  value={analytics?.teamRegisteredJoin | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                /> */}

                {/* <Stat
                  labelStyle={{ color: colors.black }}
                  label={strings.registeredLaunch}
                  value={analytics?.teamRegisteredLaunch | 0}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                /> */}
                <Stat
                  labelStyle={{ color: colors.black }}
                  label={''}
                  value={''}
                  extraStyles={{ flexGrow: 1, marginTop: isMobile ? 10 : 0 }}
                />
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text style={[commonStyles.h3, { marginBottom: 16 }]}>
            {strings.yourTeam}
          </Text>

          <FlatList
            style={{
              paddingHorizontal: isMobile ? gutters.pageGutter : 0,
            }}
            data={myTeam}
            keyExtractor={(item) => item.id}
            ListEmptyComponent={<Text style={{fontFamily: fonts.mono}}>{strings.teamText}</Text>}
            //ListEmptyComponent={<Fetching error={error} loading={loading} />}
            renderItem={({ item }) => (
              <ProfileCard
                image={item.smlImage}
                avatarSize={40}
                name={item.name}
                extrStyles={{ marginVertical: 0 }}
              />
            )}
            ItemSeparatorComponent={() => <View style={{ height: 20 }} />}
            ListFooterComponent={() => <View style={{ height: 60 }} />}
          />
        </View>
        <View>
          <Text style={[commonStyles.h3, { marginBottom: 16 }]}>
            {strings.eventLog}
          </Text>
        </View>
        <Select
          selected={selectOptions.find((o) => o.value === eventType).text}
          onChange={(value) => setEventType(value)}
          options={selectOptions}
        />
        <FlatList
          style={{
            marginTop: 20,
            minHeight: 500,
            paddingHorizontal: isMobile ? gutters.pageGutter : 0,
          }}
          data={events}
          keyExtractor={(event) => event.id}
          //ListEmptyComponent={<Fetching error={error} loading={loading} />}
          renderItem={({ item }) =>
            item.type !== 'join' && item.type !== 'launch' ? (
              <EventItem event={item} user={user} isMobile={isMobile} />
            ) : item.type === 'join' ? (
              <JoinItem event={item} user={user} isMobile={isMobile} />
            ) : item.type === 'launch' ? (
              <LaunchItem event={item} user={user} isMobile={isMobile} />
            ) : (
              <View />
            )
          }
          ItemSeparatorComponent={() => <View style={{ height: 10 }} />}
          ListFooterComponent={() => <View style={{ height: 60 }} />}
        />
      </View>
    </View>
  );
}

const Title = ({ color, text, isMobile }) => (
  <View
    style={{
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: 15,
    }}
  >
    <View
      style={{
        backgroundColor: color,
        height: 8,
        width: 8,
        marginRight: 10,
        borderRadius: 8,
      }}
    />
    <Text
      style={{
        color: colors.black,
        fontSize: fontsize.medium,
        fontFamily: fonts.medium,
      }}
    >
      {text}
    </Text>
  </View>
);

const EventItem = ({ event, user, isMobile }) => {
  let text = '';
  let link = '';
  let locationText =
    event.city && event.country
      ? strings.formatString(strings.locationText, {
        city: event.city,
        country: event.country,
      })
      : '';
  if (event.type === 'click') {
    if (event.linkType === 'team') {
      link = `${config.baseUrl}/partner/team/`;
    } else if (event.linkType === 'partner') {
      link = `${config.baseUrl}/partner/s/`;
    } else if (event.linkType === 'index') {
      link = `${config.foundationUrl}/`;
    } else if (event.linkType === 'join') {
      link = `${config.foundationUrl}/join/`;
    } else if (event.linkType === 'launch') {
      link = `${config.foundationUrl}/launch/`;
    } else {
      link = `${config.baseUrl}/s/`;
    }
    if (event.recruiterId === user.id) {
      text = strings.formatString(strings.clickText, {
        location: locationText,
        link: link,
      });
    }
    if (event.leaderTeamId === user.id) {
      text = strings.formatString(strings.clickTeamText, {
        seller: event.recruiterName,
        location: locationText,
        link: link,
      });
    }
    if (event.leaderGroupId === user.id) {
      text = strings.formatString(strings.clickGroupText, {
        location: locationText,
        link: link,
      });
    }
  }
  if (event.type === 'recruiter') {
    text = strings.formatString(strings.recruiterRewardText, {
      amount: (event.amountRec * user.veoRate).toFixed(3) + ' ' + user.currency,
    });
  }
  if (event.type === 'subfee') {
    if (event.recruiterId === user.id) {
      text = strings.formatString(strings.subscriberText, {
        amount:
          (event.amountRec * user.veoRate).toFixed(3) + ' ' + user.currency,
      });
    }
    if (event.leaderTeamId === user.id) {
      text = strings.formatString(strings.subscriberTeamText, {
        seller: event.recruiterName,
        amount:
          (event.amountTeam * user.veoRate).toFixed(3) + ' ' + user.currency,
      });
    }
    if (event.leaderGroupId === user.id) {
      text = strings.formatString(strings.subscriberGroupText, {
        amount:
          (event.amountGroup * user.veoRate).toFixed(3) + ' ' + user.currency,
      });
    }
  }
  if (event.type === 'recUser') {
    if (event.recruiterId === user.id) {
      text = strings.formatString(strings.registerText, {
        user: event.newUserName,
      });
    }
    if (event.leaderTeamId === user.id) {
      text = strings.formatString(strings.registerTeamText, {
        seller: event.recruiterName,
      });
    }
    if (event.leaderGroupId === user.id) {
      text = strings.registerGroupText;
    }
  }
  if (event.type === 'recChannel') {
    if (event.recruiterId === user.id) {
      text = strings.formatString(strings.channelText, {
        user: event.newUserName,
      });
    }
    if (event.leaderTeamId === user.id) {
      text = strings.formatString(strings.channelTeamText, {
        seller: event.recruiterName,
      });
    }
    if (event.leaderGroupId === user.id) {
      text = strings.channelGroupText;
    }
  }
  if (event.type === 'sponsorGeneric') {
    if (event.recruiterId === user.id) {
      text = strings.formatString(strings.sponsorGenericText, {
        amount:
          (event.amountRec * user.veoRate).toFixed(3) + ' ' + user.currency,
      });
    }
    if (event.leaderTeamId === user.id) {
      text = strings.formatString(strings.sponsorGenericTeamText, {
        seller: event.recruiterName,
        amount:
          (event.amountTeam * user.veoRate).toFixed(3) + ' ' + user.currency,
      });
    }
    if (event.leaderGroupId === user.id) {
      text = strings.formatString(strings.sponsorGenericGroupText, {
        amount:
          (event.amountGroup * user.veoRate).toFixed(3) + ' ' + user.currency,
      });
    }
  }
  if (event.type === 'sponsorChannel') {
    if (event.recruiterId === user.id) {
      text = strings.formatString(strings.sponsorChannelText, {
        amount:
          (event.amountRec * user.veoRate).toFixed(3) + ' ' + user.currency,
      });
    }
    if (event.leaderTeamId === user.id) {
      text = strings.formatString(strings.sponsorChannelTeamText, {
        seller: event.recruiterName,
        amount:
          (event.amountTeam * user.veoRate).toFixed(3) + ' ' + user.currency,
      });
    }
    if (event.leaderGroupId === user.id) {
      text = strings.formatString(strings.sponsorChannelGroupText, {
        amount: event.amountGroup,
      });
    }
  }
  if (event.type === 'sponsorAd') {
    if (event.recruiterId === user.id) {
      text = strings.formatString(strings.sponsorAdText, {
        amount:
          (event.amountRec * user.veoRate).toFixed(3) + ' ' + user.currency,
      });
    }
    if (event.leaderTeamId === user.id) {
      text = strings.formatString(strings.sponsorAdTeamText, {
        seller: event.recruiterName,
        amount:
          (event.amountTeam * user.veoRate).toFixed(3) + ' ' + user.currency,
      });
    }
    if (event.leaderGroupId === user.id) {
      text = strings.formatString(strings.sponsorAdGroupText, {
        amount:
          (event.amountGroup * user.veoRate).toFixed(3) + ' ' + user.currency,
      });
    }
  }
  if (event.type === 'recSeller') {
    if (event.recruiterId === user.id) {
      text = strings.formatString(strings.sellerTeamText, {
        seller: event.newUserName,
      });
    }
    if (event.leaderTeamId === user.id) {
      text = strings.sellerGroupText;
    }
    if (event.leaderGroupId === user.id) {
      text = strings.sellerGroupText;
    }
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderWidth: 1,
        borderColor: colors.greySolo,
        backgroundColor: colors.greyBoba,
      }}
    >
      <Text style={{fontFamily: fonts.regular}}>{text}</Text>
      <View
        style={{
          alignItems: 'flex-end',
          textAlign: 'right',
          padding: 10,
          borderRadius: 4,
          backgroundColor: colors.white,
          minWidth: 'auto',
          marginLeft: 20,
        }}
      >
        <Text style={{fontFamily: fonts.mono, fontSize: 12}}>{distanceInTime({ timestamp: event.date })}</Text>
      </View>
    </View>
  );
};

const JoinItem = ({ event, user, isMobile }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexGrow: 1,
        padding: 20,
        borderWidth: 1,
        borderColor: colors.greySolo,
        backgroundColor: colors.greyBoba,
      }}
    >
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          maxWidth: '65%',
        }}
      >
        <Text>{strings.joinText}</Text>
        <Text style={{ fontFamily: fonts.medium, marginTop: 5 }}>
          {strings.email}:{' '}
          <Text>{event.email}</Text>
        </Text>
        <Text style={{ marginTop: 5 }}>
          {strings.followers}:{' '}
          <Text>{event.followers}</Text>
        </Text>
      </View>
      <View
        style={{
          alignItems: 'center',
          padding: 5,
          borderWidth: 1,
          borderRadius: 8,
          borderColor: colors.greySolo,
          backgroundColor: colors.white,
          minWidth: 100,
          marginLeft: 20,
        }}
      >
        <Text>{distanceInTime({ timestamp: event.date })}</Text>
      </View>
    </View>
  );
};

const LaunchItem = ({ event, user, isMobile }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexGrow: 1,
        padding: 20,
        borderWidth: 1,
        borderColor: colors.greySolo,
        backgroundColor: colors.greyBoba,
      }}
    >
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          maxWidth: '65%',
        }}
      >
        <Text>{strings.launchText}</Text>
        <Text style={{ marginTop: 5 }}>
          {strings.name}:{' '}
          <Text>{event.name}</Text>
        </Text>
        <Text style={{ marginTop: 5 }}>
          {strings.email}:{' '}
          <Text>{event.email}</Text>
        </Text>
        <Text style={{ marginTop: 5 }}>
          {strings.reasonForJoiningVEO}:{' '}
          <Text>{event.reason}</Text>
        </Text>
        <Text style={{ marginTop: 5 }}>
          {strings.numberOfFollowersToday}:{' '}
          <Text>{event.followers}</Text>
        </Text>
        <Text style={{ marginTop: 5 }}>
          {strings.estimatedToJoinVEO}:{' '}
          <Text>{event.estimatedPercent}%</Text>
        </Text>
        <Text style={{ marginTop: 5 }}>
          {strings.whyWillYouJoin}:
          {event.whyMove ? (
            <View style={{ width: '100%' }}>
              <Text>- {strings.moveContent}</Text>
            </View>
          ) : (
            <View />
          )}
          {event.whyMain ? (
            <View style={{ width: '100%' }}>
              <Text>- {strings.mainChannel}</Text>
            </View>
          ) : (
            <View />
          )}
          {event.whyFew ? (
            <View style={{ width: '100%' }}>
              <Text>- {strings.justChannel}</Text>
            </View>
          ) : (
            <View />
          )}
        </Text>
        <Text style={{ marginTop: 5 }}>
          {strings.advertising}:
          {event.wantAds ? (
            <View style={{ width: '100%' }}>
              <Text>
                - {strings.connectAdvertisers}
              </Text>
            </View>
          ) : (
            <View />
          )}
          {event.wantNoAds ? (
            <View style={{ width: '100%' }}>
              <Text>
                - {strings.notAdvertisers}
              </Text>
            </View>
          ) : (
            <View />
          )}
        </Text>
      </View>
      <View
        style={{
          alignItems: 'center',
          padding: 5,
          borderWidth: 1,
          borderRadius: 8,
          borderColor: colors.greySolo,
          backgroundColor: colors.white,
          minWidth: 100,
          marginLeft: 20,
        }}
      >
        <Text>{distanceInTime({ timestamp: event.date })}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  statContainer: {
    borderColor: colors.greyLuke,
    padding: 30,
    borderWidth: 1,
    borderRadius: 8,
    marginBottom: 20,
  },
  statRow: {
    flexDirection: 'row',
    width: '100%',
    flexShrink: 1,
    justifyContent: 'space-between',
  },
  row: {
    marginTop: 20,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

const strings = new LocalizedStrings({
  en: {
    shareAnalytics: 'Share analytics',
    all: 'All',
    click: 'Link clicks',
    sales: 'Recruited salesmen',
    join: 'Registered foundation join',
    launch: 'Registered foundation launch',
    yourShare: 'Personal result',
    shareClicks: 'Clicks on your shared links',
    recruiter: 'Recruiter reward',
    recruitedUsers: 'Users',
    recruitedChannels: 'Channels',
    recruitedSubscribers: 'Subscriptions',
    sponsorChannel: 'Channel sponsors',
    sponsorGeneric: 'VEO sponsors',
    sponsorAd: 'Ad sponsors',
    sponsorsGenericReward: 'VEO sponsor',
    sponsorsChannelReward: 'Channel sponsor',
    sponsorsAdsReward: 'Ads sponsor',
    recruitedSponsors: 'Sponsors',
    recruiterReward: 'Recruiting',
    subscriberReward: 'Subscription',
    recruitedSalesmen: 'Sellers',
    registeredJoin: 'Registered join',
    registeredLaunch: 'Registered launch',
    teamShare:
      'Your sales team result',
    teamShareClicks: 'Clicks on shared links',
    yourTeam: 'Your sales team',
    teamText:
      'Invite new salespeople using the "Share" feature. Once these recruited salespeople start working, they will appear in your results. As the leader of the sales team, you will receive compensation based on their sales performance.',
    eventLog: 'Events',
    eventLogText:
      'When someone clicks on your shared links or when you through this shared links manage to recruit someone, you will see that event in this log.',
    ago: 'ago',
    locationText: 'in {city}, {country}',
    recruiterRewardText: 'You have recieved a recruiting reward of {amount}.',
    clickText:
      'A customer{location} who you have shared to just clicked on {link}',
    clickTeamText:
      'A customer{location} that sale team member {seller} shared to has clicked on {link}',
    clickGroupText:
      'A customer{location} that a sale group member shared to has clicked on {link}',
    subscriberText:
      'You have recieved a reward of {amount} for a subscription.',
    subscriberTeamText:
      'A sale team member {seller} has recieved a reward for a subscription. You have recieved team leader reward of {amount}.',
    subscriberGroupText:
      'A sale group member has recieved a reward for a subscription. You have recieved group leader reward of {amount}.',
    registerText: 'You have succeded to get a new customer {user} to register.',
    registerTeamText:
      'A sale team member {seller} have succeded to get a new customer to register.',
    registerGroupText:
      'A sale group member have succeded to get a new customer to register.',
    channelText:
      'You have succeded to recruit a new channel {user} and will therefore recieve a sale reward for every new paying subscriber to the channel. You will also recieve 1% recruiting reward of the channels total income from its content.',
    channelTeamText:
      'A sale team member {seller} has succeded to recruit a new channel. You will recieve a team leader reward for every new paying subscriber to the channel.',
    channelGroupText:
      'A sale group member has succeded to recruit a new channel. You will recieve a group leader reward for every new paying subscriber to the channel.',
    sponsorGenericText:
      'You have recieved a reward of {amount} for a VEO-sponsorship.',
    sponsorGenericTeamText:
      'A sale team member {seller} has recieved a reward for a VEO-sponsorship. You have recived a team leader reward of {amount}.',
    sponsorGenericGroupText:
      'A sale team member has recieved a reward for a VEO-sponsorship. You have recived a group leader reward of {amount}.',
    sponsorChannelText:
      'You have recieved a reward of {amount} for a channel-sponsorship.',
    sponsorChannelTeamText:
      'A sale team member {seller} has recieved a reward for a channel-sponsorship. You have recived a team leader reward of {amount}.',
    sponsorChannelGroupText:
      'A sale team member has recieved a reward for a channel-sponsorship. You have recived a group leader reward of {amount}.',
    sponsorAdText:
      'You have recieved a reward of {amount} for an ads-sponsorship.',
    sponsorAdTeamText:
      'A sale team member {seller} has recieved a reward for an ads-sponsorship. You have recived a team leader reward of {amount}.',
    sponsorAdGroupText:
      'A sale team member has recieved a reward for an ads-sponsorship. You have recived a group leader reward of {amount}.',
    sellerTeamText:
      'You have succeded to recruit a new seller {seller} to your team. You will recieve a team leader reward of 10% of the total sale reward for this seller.',
    sellerGroupText:
      'A new seller has been recruited to your group. You will recieve a group leader reward of 5% of the total sale reward for this seller.',
    joinText:
      'A new customer who you have shared a link to has registered on foundation.org/join',
    launchText:
      'A new customer who you have shared a link to has registered on foundation.org/launch',
    email: 'Email',
    followers: 'Number of Followers',
    name: 'Name',
    reasonForJoiningVEO: 'Reason for joining VEO',
    numberOfFollowersToday: 'Number of followers today',
    estimatedToJoinVEO: 'Estimated percentage of followers to join VEO',
    whyWillYouJoin: 'Why will you join',
    moveContent: ' I intend to move all content to VEO and use it exclusively',
    mainChannel: 'VEO will be my main channel, with supplements',
    justChannel: 'VEO will just be one of few channels I will use',
    existingAdvertisers: 'Existing advertisers that will likely follow',
    estimatedBudget: 'Estimated ads budget in EUR per year',
    advertising: 'Advertising',
    connectAdvertisers: 'I want to connect with advertisers',
    notAdvertisers: 'I do not want to have ads in my channel',
    recruitmentOf: 'Recruitment of:',
    revenueFrom: 'Revenue from:',
    other: 'Other:',
  },
  sv: {
    shareAnalytics: 'Analys',
    all: 'Alla',
    click: 'Länk-klick',
    sales: 'Rekryterade säljare',
    join: 'Fyllt i foundation join',
    launch: 'Fyllt i foundation launch',
    yourShare: 'Personliga resultat',
    shareClicks: 'Klick på delade länkar',
    recruiter: 'Rekryteringsersättning',
    recruitedUsers: 'Användare',
    recruitedChannels: 'Kanaler',
    recruitedSubscribers: 'Prenumeranter',
    sponsorChannel: 'Kanal-sponsorer',
    sponsorGeneric: 'VEO-sponsorer',
    sponsorAd: 'Annons-sponsorer',
    recruiterReward: 'Rekrytering',
    subscriberReward: 'Prenumeranter',
    sponsorsGenericReward: 'VEO sponsorer',
    sponsorsChannelReward: 'Kanalsponsorer',
    sponsorsAdsReward: 'Annonsörer',
    recruitedSponsors: 'Sponsorer',
    recruitedSalesmen: 'Säljare',
    registeredJoin: 'Fyllt i join',
    registeredLaunch: 'Fyllt i launch',
    teamShare: 'Ditt säljteams resultat',
    teamShareClicks: 'Klick på delade länkar',
    yourTeam: 'Ditt säljteam',
    teamText:
      'Genom funktionen "Dela" kan du bjuda in nya säljare. När dessa rekryterade säljare börjar arbeta, kommer de att visas i dina resultat. Som ledare för säljteamet kommer du att få ersättning baserat på deras försäljningsresultat.',
    eventLog: 'Händelser',
    eventLogText:
      'När någon klickar på dina delade länkar eller när du genom dessa delade länkar lyckas rekrytera någon så hittar du denna händelse i denna logg.',
    ago: 'sedan',
    locationText: 'i {city}, {country}',
    recruiterRewardText:
      'Du har mottagit en rekryteringsersättning på {amount}.',
    clickText: 'En kund{location} som du delat till klickade på {link}',
    clickTeamText:
      'En kund{location} som säljteam-medlem {seller} delat till klickade på {link}',
    clickGroupText:
      'En kund{location} som säljgrupp-medlem delat till klickade på {link}',
    subscriberText:
      'Du har erhållit ersättning på {amount} för en prenumerant.',
    subscriberTeamText:
      'En säljteam-medlem {seller} har erhållit ersättning för en prenumerant. Du har fått ledarersättning på {amount}.',
    subscriberGroupText:
      'En säljgrupp-medlem har erhållit ersättning för en prenumerant. Du har fått ledarersättning på {amount}.',
    registerText: 'Du har lyckats få en ny kund {user} att registrera sig.',
    registerTeamText:
      'En säljteam-medlem {seller} har lyckats få en ny kund att registrera sig.',
    registerGroupText:
      'En säljgrupp-medlem har lyckats få en ny kund att registrera sig.',
    channelText:
      'Du har lyckats rekrytera en ny kanal {user} och kommer därför erhålla säljarersättning för varje betalande prenumerant till kanalen samt 1% rekryteringsersättning av kanalens innehållsintäkter.',
    channelTeamText:
      'En säljteam-medlem {seller} har lyckats rekrytera en ny kanal. Du kommer få ledarersättning för varje betalande prenumerant till kanalen.',
    channelGroupText:
      'En säljgrupp-medlem har lyckats rekrytera en ny kanal. Du kommer få ledarersättning för varje betalande prenumerant till kanalen.',
    sponsorGenericText:
      'Du har erhållit ersättning på {amount} för en VEO-sponsring.',
    sponsorGenericTeamText:
      'En säljteam-medlem {seller} har erhållit ersättning för en VEO-sponsring. Du har fått ledarersättning på {amount}.',
    sponsorGenericGroupText:
      'En säljgrupp-medlem har erhållit ersättning för en VEO-sponsring. Du har fått ledarersättning på {amount}.',
    sponsorChannelText:
      'Du har erhållit ersättning på {amount} för en kanalsponsring.',
    sponsorChannelTeamText:
      'En säljteam-medlem {seller} har erhållit ersättning för en kanalsponsring. Du har fått ledarersättning på {amount}.',
    sponsorChannelGroupText:
      'En säljgrupp-medlem har erhållit ersättning för en kanalsponsring. Du har fått ledarersättning på {amount}.',
    sponsorAdText:
      'Du har erhållit ersättning på {amount} för en annonssponsring.',
    sponsorAdTeamText:
      'En säljteam-medlem {seller} har erhållit ersättning för en annonssponsring. Du har fått ledarersättning på {amount}.',
    sponsorAdGroupText:
      'En säljgrupp-medlem har erhållit ersättning för en annonssponsring. Du har fått ledarersättning på {amount}.',
    sellerTeamText:
      'Du har lyckats rekrytera en ny säljare {seller} till ditt team. Du kommer erhålla ledarersättning på 10% av säljarersättningen för denna säljare.',
    sellerGroupText:
      'En ny säljare har rekryterats till din säljgrupp. Du kommer erhålla ledarersättning på 5% av säljarersättningen för denna säljare.',
    joinText:
      'En ny kund som du delat till har registrerat sig på foundation.org/join',
    launchText:
      'En ny kund som du delat till har registrerat sig på foundation.org/launch',
    email: 'Email',
    followers: 'Antal följare',
    name: 'Namn',
    reasonForJoiningVEO: 'Anledning att joina VEO',
    numberOfFollowersToday: 'Antal följare idag',
    estimatedToJoinVEO: 'Uppskattat antal procent av följarna att joina VEO',
    whyWillYouJoin: 'Varför vill du joina',
    moveContent: 'Jag tänker flytta allt mitt innehåll till VEO exklusivt',
    mainChannel: 'VEO kommer va min huvudkanal, med kompleteringar',
    justChannel: 'VEO kommer bara bli en av fåtal kanaler jag använder',
    existingAdvertisers:
      'Befintligt antal annonsörer som sannolikt vill följa efter',
    estimatedBudget: 'Estimerad annonsbudget i EUR per år',
    advertising: 'Annonsering',
    connectAdvertisers: 'Jag vill ansluta med annonsörer',
    notAdvertisers: 'Jag vill inte ha annonser i min kanal',
    recruitmentOf: 'Rekrytering av:',
    revenueFrom: 'Ersättning för:',
    other: 'Övrigt:',
  },
});
