import { resetReactiveVars } from '../reactiveVariables';

export const getCookie = (name) => {
  let matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const signout = ({ client, history, path }) => {
  document.cookie = `userToken=;Max-Age=0;expires=Thu, 01 Jan 1970 00:00:00;path=/;${process.env.REACT_APP_ENV !== 'development' && 'secure;'}`;
  client.clearStore();
  resetReactiveVars();
  history.push(path.includes('sponsor') ? `/sponsor/signin` : '/signin');
}
