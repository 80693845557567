import React, { useRef, useState } from 'react';
import LocalizedStrings from 'react-localization';
import {
  View,
  Text,
  ScrollView,
  Linking,
  TouchableOpacity
} from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { PROFILE } from '../../gql/gqls';
import ProfileCard from '../../components/ProfileCard';
import { myAccountVar } from '../../reactiveVariables';
import Info from '../../components/Info';
import PageLoader from '../../components/PageLoader';


import {
  mediaQueries,
  gutters,
  screenStyles,
  useMobileSize,
  fonts,
  fontsize,
  colors,
} from '../../theme';

const mock = [{
  id: 'd536310e-df9f-4d65-8baa-eed7d935c17c',
  image: null,
  name: 'olle',
  progress: 80,
}, {
  id: 'd536310e-df9f-4d65-8baa-eed7d935c17c',
  image: null,
  name: 'nils',
  progress: 100,
  }, {
    id: 'd536310e-df9f-4d65-8baa-eed7d935c17c',
  image: null,
  name: 'kalle',
  progress: 46,
}]

export default function SponsorDistScreen() {

  const user = useReactiveVar(myAccountVar);
  const currency = user.currency;

  const isMobile = useMobileSize();
  const { id } = useParams();
  const history = useHistory();

  const { error, data, loading } = useQuery(PROFILE, {
    variables: {
      id: id,
    },
  });

  console.log(data);
  console.log(currency, 'currencycurrency');
  const {
    smlImage,
    name,
    infoLink,
    infoPhone,
    infoEmail,
    description,
  } = data?.profile || {};

  return <PageLoader loading={loading} error={error}>
    <View style={screenStyles.main}>
      <ScrollView
        scrollIndicatorInsets={{ right: 1 }}
        style={{
          maxWidth: mediaQueries.tablet,
          width: '100%',
          paddingHorizontal: isMobile ? gutters.pageGutter : 0,
          paddingBottom: 90
      }}>
        <View style={{
          marginVertical: 20
        }}>
          <ProfileCard
            image={smlImage}
            avatarSize={40}
            name={name}
          />
        </View>
          {(infoEmail || infoPhone || infoLink) && <View style={{
            backgroundColor: colors.greyBoba,
            padding: 20,
            paddingTop: isMobile ? 0 : 20
          }}>
            {infoEmail && <Info label={strings.infoEmail} value={infoEmail} isMobile={isMobile} onPress={() => Linking.openURL(`mailto:${infoEmail}`)} />}
            {infoPhone && <Info label={strings.infoPhone} value={infoPhone} isMobile={isMobile} onPress={() => Linking.openURL(`tel:${infoPhone}`)} />}
            {infoLink && <Info label={strings.infoLink} value={infoLink} isMobile={isMobile} onPress={() => Linking.openURL(infoLink)} />}
          </View>}
         {description?.length > 0 && <View 
         style={{
            marginVertical: 20
          }}>
            <Text style={{
              marginBottom: 10,
              fontSize: fontsize.small,
              color: colors.greyLeia,
              fontFamily: fonts.regular
            }}>
              {strings.about}
            </Text>
            <Text style={{
              fontSize: fontsize.regular,
              color: colors.black,
              fontFamily: fonts.regular
            }}>
              {description}
            </Text>
        </View>}
        <View
          style={{
            boxShadow: '0px 4px 12px rgba(218, 218, 218, 0.5)',
            padding: 20,
            marginHorizontal: 2,
            overflow: 'visible'
          }}
        >
          <Text style={{
            
          }}>
            {strings.responsible}
          </Text>
          {mock.map(i => <TouchableOpacity
            key={i.id}
            onPress={() => history.push(`/discover/${i.id}`)}
            style={{
              paddingVertical: 5,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
          }}>
            <ProfileCard
              image={i.smlImage}
              avatarSize={40}
              name={i.name}
            />
            <View style={{
              width: 40,
              height: 40,
              borderRadius: 20,
              backgroundColor: i.progress === 100 ? colors.goGreen : colors.deepBlue,
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Text style={{
                color: colors.white,
                fontSize: fontsize.small,
                fontFamily: fonts.regular
              }}>
                {i.progress + '%'}
              </Text>
            </View>
          </TouchableOpacity>)}
        </View>
      </ScrollView>
    </View>
  </PageLoader>
};

const strings = new LocalizedStrings({
  en: {
    about: 'About me',
    error: 'Something went wrong. Try to refresh the page',
    infoEmail: 'Email',
    infoPhone: 'Phone',
    infoLink: 'Website',
    responsible: 'Responsible for'
  },
  sv: {
    about: 'Om mig',
    error: 'Något blev fel. Pröva ladda om sidan.',
    infoEmail: 'Epost',
    infoPhone: 'Telefon',
    infoLink: 'Webbplats',
    responsible: 'Ansvarig för'
  }
});
