import React, { useRef, useEffect, useState } from 'react';
import LocalizedStrings from 'react-localization';
import {
  StyleSheet,
  FlatList,
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  ScrollView,
  Linking,
} from 'react-native';
import { useReactiveVar, useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { NEW_USER } from '../../gql/gqls';
import WideButton from '../../components/misc/WideButton';
import { myAccountVar } from '../../reactiveVariables';
import CopyLink from '../../components/CopyLink';

import verifiedIcon from '../../assets/icons/verified.png';

import {
  commonStyles,
  mediaQueries,
  gutters,
  mainTitle,
  screenStyles,
  useMobileSize,
  colors,
  fonts,
  fontsize,
} from '../../theme';
import config from '../../config';
import { useHistory } from 'react-router-dom';

const IS_SPONSOR = gql`
  query {
    checkIfSponsor {
      state
      limit
      invited
    }
  }
`;

export default function ShareScreen() {
  const isMobile = useMobileSize();
  const user = useReactiveVar(myAccountVar);
  const history = useHistory();
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const [userLimit, setUserLimit] = useState(0);
  const [invited, setInvited] = useState(0);

  const [newUser, { loading: newUserLoading, error: newUserError }] =
    useMutation(NEW_USER, {
      onCompleted: (data) => {
        if (data.newUser === 'newUser')
          alert(strings.newUserSuccess + ' ' + userName);
        if (data.newUser === 'activateSeller') alert(strings.activateSellerSuccess + ' ' + userName);
        if (data.newUser === 'removeSeller') alert(strings.removeSellerSuccess);
        if (data.newUser === 'shareApproved') alert(strings.shareApprovedSuccess);
        if (data.newUser === 'shareDenied') alert(strings.shareDeniedSuccess);
        if (data.newUser === 'bannedUser') alert(strings.banSuccess);
        if (data.newUser === 'unbannedUser') alert(strings.unbanSuccess);
        // setUserName('');
        // setEmail('');
      },
      onError: (err) => {
        if (err && err.graphQLErrors && err.graphQLErrors.length !== 0) {
          if (err.graphQLErrors[0].message === 'errExist') {
            alert(strings.errNewUser);
          } else {
            alert(err.graphQLErrors[0].message);
          }
        }
      },
    });

  useQuery(IS_SPONSOR, {
    notifyOnNetworkStatusChange: true,
    pollInterval: 5000,
    onCompleted: (data) => {
      setUserLimit(
        data.checkIfSponsor.limit
          ? user.isSellerAdmin
            ? data.checkIfSponsor.limit
            : data.checkIfSponsor.limit < 100
            ? data.checkIfSponsor.limit
            : 100
          : 0
      );
      setInvited(data.checkIfSponsor.invited ? data.checkIfSponsor.invited : 0);
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <View style={screenStyles.main}>
      <ScrollView
        scrollIndicatorInsets={{ right: 1 }}
        style={{
          maxWidth: mediaQueries.tablet,
          width: '100%',
          marginBottom: 100,
          paddingHorizontal: isMobile ? gutters.pageGutter : 0,
        }}
      >
        {user.isSeller && (
          <View style={{ marginBottom: 40 }}>
            <Text
              style={[
                commonStyles.h2,
                {
                  marginTop: gutters.spacing[4],
                  marginBottom: gutters.spacing[1],
                },
              ]}
            >
              {strings.createUser}
            </Text>
            <Text style={commonStyles.p}>{strings.createUserText}</Text>
            <TouchableOpacity onPress={() => history.push(`/wiki`)}>
              <Text style={commonStyles.link}>
                {strings.createUserTextSupport}
              </Text>
            </TouchableOpacity>
            <View
              style={{
                backgroundColor: colors.blueGrey,
                marginTop: 24,
                padding: 20,
                borderRadius: 4,
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: fonts.medium,
                  color: colors.blueGreyDark,
                }}
              >
                {strings.invitesLeft}
              </Text>
              <View style={{ flexDirection: 'row', marginTop: 4 }}>
                <Text
                  style={{
                    fontSize: 16,
                    fontFamily: fonts.mono,
                    backgroundColor: colors.white,
                    flexShrink: 1,
                    paddingHorizontal: 8,
                    paddingVertical: 4,
                    borderRadius: 8,
                  }}
                >
                  {userLimit - invited > 0 ? userLimit - invited : 0}
                </Text>
                <Text
                  style={{
                    fontSize: 16,
                    fontFamily: fonts.regular,
                    flexShrink: 1,
                    paddingHorizontal: 4,
                    paddingVertical: 4,
                    color: colors.blueGreyDark,
                  }}
                >
                  {strings.invitesOf}
                </Text>
                <Text
                  style={{
                    fontSize: 16,
                    fontFamily: fonts.mono,
                    backgroundColor: colors.white,
                    flexShrink: 1,
                    paddingHorizontal: 8,
                    paddingVertical: 4,
                    borderRadius: 8,
                  }}
                >
                  {userLimit > 0 ? userLimit : 0}
                </Text>
              </View>
            </View>

            <View style={{ marginTop: 10 }}>
              {newUserLoading ? (
                <ActivityIndicator />
              ) : userLimit > 0 && invited >= 0 && userLimit >= invited + 1 ? (
                <View>
                  <TextInput
                    style={styles.input}
                    placeholder={strings.enterUsername}
                    value={userName}
                    onChangeText={(input) => {
                      setUserName(input);
                    }}
                  />
                  <View style={styles.inputBottom} />
                  <TextInput
                    style={styles.input}
                    placeholder={strings.enterUsersEmail}
                    value={email}
                    onChangeText={(input) => {
                      setEmail(input);
                    }}
                  />
                  <View style={styles.inputBottom} />
                  <View
                    style={[
                      styles.inputRow,
                      { flexDirection: isMobile ? 'column' : 'row' },
                    ]}
                  >
                    <WideButton
                      pill
                      onPress={() => {
                        // create new user
                        newUser({
                          variables: {
                            name: userName,
                            email: email,
                            mode: 'new',
                          },
                        });
                      }}
                      text={strings.createUser}
                      disabled={
                        newUserLoading ||
                        !userName ||
                        !email ||
                        !email.includes('@') ||
                        !email.includes('.') ||
                        !user.isSellerActivated
                      }
                      buttonStyle={{
                        width: isMobile ? '100%' : 250,
                        marginTop: isMobile ? 0 : 0,
                      }}
                    />
                    {user.isSeller && (
                      <WideButton
                        pill
                        onPress={() => {
                          // add new user
                          newUser({
                            variables: {
                              name: userName,
                              email: email,
                              mode: 'newSeller',
                            },
                          });
                        }}
                        text={strings.createSalesman}
                        disabled={
                          newUserLoading ||
                          !userName ||
                          !email ||
                          !email.includes('@') ||
                          !email.includes('.') ||
                          !user.isSellerActivated
                        }
                        buttonStyle={{
                          width: isMobile ? '100%' : 250,
                          marginTop: isMobile ? 20 : 0,
                        }}
                      />
                    )}
                    {user.isSellerAdmin && (
                      <View>
                        <WideButton
                          pill
                          onPress={() => {
                            // add new user
                            newUser({
                              variables: {
                                name: userName,
                                email: '',
                                mode: 'shareApproved',
                              },
                            });
                          }}
                          text={strings.shareApproved}
                          disabled={newUserLoading || !userName}
                          buttonStyle={{
                            width: isMobile ? '100%' : 250,
                            marginTop: isMobile ? 20 : 0,
                            marginBottom: 5,
                          }}
                        />
                        <WideButton
                          pill
                          onPress={() => {
                            // add new user
                            newUser({
                              variables: {
                                name: userName,
                                email: '',
                                mode: 'shareDenied',
                              },
                            });
                          }}
                          text={strings.shareDenied}
                          disabled={newUserLoading || !userName}
                          buttonStyle={{
                            width: isMobile ? '100%' : 250,
                            marginTop: isMobile ? 20 : 0,
                            marginBottom: 5,
                          }}
                        />
                        <WideButton
                          pill
                          onPress={() => {
                            // add new user
                            newUser({
                              variables: {
                                name: userName,
                                email: email,
                                mode: 'activateSeller',
                              },
                            });
                          }}
                          text={strings.activateSalesman}
                          disabled={newUserLoading || !userName}
                          buttonStyle={{
                            width: isMobile ? '100%' : 250,
                            marginTop: isMobile ? 20 : 0,
                            marginBottom: 5,
                          }}
                        />
                        <WideButton
                          pill
                          onPress={() => {
                            // add new user
                            newUser({
                              variables: {
                                name: userName,
                                email: email,
                                mode: 'removeSeller',
                              },
                            });
                          }}
                          text={strings.removeSalesman}
                          disabled={newUserLoading || !userName}
                          buttonStyle={{
                            width: isMobile ? '100%' : 250,
                            marginTop: isMobile ? 20 : 0,
                            marginBottom: 5,
                          }}
                        />
                        <WideButton
                          pill
                          onPress={() => {
                            // add new user
                            newUser({
                              variables: {
                                name: userName,
                                email: '',
                                mode: 'banUser',
                              },
                            });
                          }}
                          text={strings.ban}
                          disabled={newUserLoading || !userName}
                          buttonStyle={{
                            width: isMobile ? '100%' : 250,
                            marginTop: isMobile ? 20 : 0,
                            marginBottom: 5,
                          }}
                        />
                        <WideButton
                          pill
                          onPress={() => {
                            // add new user
                            newUser({
                              variables: {
                                name: userName,
                                email: '',
                                mode: 'unbanUser',
                              },
                            });
                          }}
                          text={strings.unban}
                          disabled={newUserLoading || !userName}
                          buttonStyle={{
                            width: isMobile ? '100%' : 250,
                            marginTop: isMobile ? 20 : 0,
                            marginBottom: 5,
                          }}
                        />
                      </View>
                    )}
                  </View>
                </View>
              ) : (
                <View>
                  <Text
                    style={{
                      fontSize: 18,
                      fontFamily: fonts.medium,
                      color: colors.blueGreyDark,
                    }}
                  >
                    {strings.limitReachedTxt}
                  </Text>
                  <WideButton
                    pill
                    disabled={!user.isSellerActivated}
                    onPress={() => {
                      Linking.openURL('/partner/sponsor/');
                    }}
                    text={strings.sponsorNow}
                    buttonStyle={{
                      width: 250,
                      marginTop: 20,
                    }}
                  />
                </View>
              )}
            </View>
          </View>
        )}
        {(user.isSeller && user.isSellerActivated) || !user.isSeller ? (
          <>
            <Text
              style={[
                commonStyles.h3,
                {
                  marginTop: gutters.spacing[4],
                  marginBottom: gutters.spacing[1],
                },
              ]}
            >
              {strings.title2}
            </Text>
            <Text style={commonStyles.p}>{strings.titleText2}</Text>
            <TouchableOpacity onPress={() => history.push(`/wiki`)}>
              <Text style={commonStyles.link}>
                {strings.createUserTextSupport2}
              </Text>
            </TouchableOpacity>
            {/*user.isSeller ? (
              <View style={{ marginTop: 40 }}>
                <Text style={commonStyles.boldText}>{strings.inviteSalesman}</Text>
                <Text style={commonStyles.infoText}>
                  {strings.inviteSalesmanText}
                </Text>
                <CopyLink
                  text={`${config.baseUrl}/partner/team/${user.shortId}`}
                  link={`${config.baseUrl}/partner/team/${user.shortId}`}
                />
              </View>
            ) : (
              <View />
            )*/}
            <View
              style={{
                marginTop: isMobile ? 10 : 20,
                marginBottom: isMobile ? 10 : 20,
              }}
            >
              <CopyLink
                text={`${config.baseUrl}/partner/sponsor/s/${user.shortId}`}
                link={`${config.baseUrl}/partner/sponsor/s/${user.shortId}`}
              />
            </View>
            {/* <Text style={[commonStyles.h2, { marginTop: gutters.spacing[4] }]}>{strings.foundationTitle}</Text> */}
            <View
              style={{
                marginTop: isMobile ? 10 : 20,
                marginBottom: isMobile ? 10 : 20,
              }}
            >
              <Text style={commonStyles.h3}>{strings.inviteFoundation}</Text>
              <Text style={commonStyles.infoText}>
                {strings.inviteFoundationText}
              </Text>
              <CopyLink
                text={`${config.foundationUrl}?s=${user.shortId}`}
                link={`${config.foundationUrl}?s=${user.shortId}`}
              />
            </View>
            {/* <Text style={[commonStyles.h2, { marginTop: gutters.spacing[4] }]}>{strings.vgmTitle}</Text> */}
            <View
              style={{
                marginTop: isMobile ? 10 : 20,
                marginBottom: isMobile ? 10 : 20,
              }}
            >
              <Text style={commonStyles.h3}>{strings.inviteVgm}</Text>
              <Text style={commonStyles.infoText}>{strings.inviteVgmText}</Text>
              <CopyLink
                text={`${config.vgmUrl}?s=${user.shortId}`}
                link={`${config.vgmUrl}?s=${user.shortId}`}
              />
            </View>
            {/**<View style={{ marginTop: 40 }}>
              <Text style={commonStyles.boldText}>
                {strings.inviteContentOwner}
              </Text>
              <Text style={commonStyles.infoText}>
                {strings.inviteContentOwnerText}
              </Text>
              <CopyLink
                text={`${config.baseUrl}/s/${user.shortId}`}
                link={`${config.baseUrl}/s/${user.shortId}`}
              />
            </View>
            <View style={{ marginBottom: 40 }}>
              <Text style={commonStyles.boldText}>{strings.inviteAdvertiser}</Text>
              <Text style={commonStyles.infoText}>
                {strings.inviteAdvertiserText}
              </Text>
              <CopyLink
                text={`${config.baseUrl}/partner/s/${user.shortId}`}
                link={`${config.baseUrl}/partner/s/${user.shortId}`}
              />
            </View>
            <View style={{ marginBottom: 40 }}>
              <Text style={commonStyles.boldText}>{strings.inviteJoin}</Text>
              <Text style={commonStyles.infoText}>{strings.inviteJoinText}</Text>
              <CopyLink
                text={`${config.foundationUrl}/join/${user.shortId}`}
                link={`${config.foundationUrl}/join/${user.shortId}`}
              />
            </View>
            <View style={{ marginBottom: 120 }}>
              <Text style={commonStyles.boldText}>{strings.inviteLaunch}</Text>
              <Text style={commonStyles.infoText}>{strings.inviteLaunchText}</Text>
              <CopyLink
                text={`${config.foundationUrl}/launch/${user.shortId}`}
                link={`${config.foundationUrl}/launch/${user.shortId}`}
              />
            </View>
            **/}
            {/* <View style={{ marginBottom: 40 }} >
              <Text style={commonStyles.boldText}>
                {strings.learnMore}
              </Text>
              <Text style={commonStyles.infoText}>
                {strings.learnMoreInfo}
              </Text>
              {user.certified ? <WideButton
                middleRightImage={<Image
                  source={verifiedIcon}
                  style={{
                    marginLeft: 10,
                    width: 24,
                    height: 24,
                  }} />}
                unfilled
                text={strings.certified}
              /> : <WideButton text={strings.education} buttonStyle={{ marginBottom: 40 }} />}

            </View> */}
          </>
        ) : (
          <>
            <View
              style={{
                backgroundColor: colors.blueGrey,
                marginTop: 24,
                padding: 20,
                borderRadius: 4,
              }}
            >
              <Text
                style={[commonStyles.h4, { marginBottom: gutters.spacing[0] }]}
              >
                {strings.notApprovedTitle}
              </Text>
              <Text
                style={[commonStyles.p, { marginBottom: gutters.spacing[2] }]}
              >
                {strings.notApprovedText}
              </Text>
              <WideButton
                unfilled
                small
                onPress={() => history.push(`/start`)}
                text={strings.notApprovedButton}
                buttonStyle={{ marginBottom: gutters.spacing[4] }}
              />
            </View>
          </>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  inputRow: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
  inputCurrency: {
    fontFamily: fonts.regular,
    color: colors.black,
    fontSize: fontsize.medium,
    marginTop: 5,
  },
  input: {
    textAlign: 'left',
    fontFamily: fonts.regular,
    color: colors.black,
    fontSize: fontsize.medium,
    minHeight: 56,
    backgroundColor: colors.greySolo,
    marginVertical: 10,
    marginBottom: 10,
    padding: 15,
    borderRadius: 8,
  },
});

const strings = new LocalizedStrings({
  en: {
    title: 'Share your links and get rewarded',
    title2: 'Invite a Sponsor',
    titleText:
      'Share VEO using your unique links below and get rewarded for every new content owner, subscriber, sponsor or salesman that you manage to recruit.',
    titleText2:
      'Invite interested parties to financially contribute to VEO, a project aimed at improving conditions for those who create and own content. As a salesperson, your compensation will be proportional to the size of the sponsored contributions you manage to secure. This means that the more sponsorship you can generate, the greater your own financial reward will be.',
    inviteSalesman: 'Invite a salesman',
    inviteSalesmanText:
      'Invite a salesman that you want to be part of your sales team. You will recieve revenue when ever one of your team members and group makes a sale.',
    inviteSponsor: 'Invite a sponsor',
    inviteSponsorText: 'Get a reward for helping content creators.',
    inviteContentOwner: 'Invite a content/channel owner',
    inviteContentOwnerText:
      'Invite a content or channel owner you think would appreciate and benefit from using VEO. Recruit an artist, association club or anyone else who values themselves and the content they create. For each content owner you succeed in recruiting, you get an income equivalent to 1% of what they earn on their published content.',
    inviteAdvertiser: 'Invite an advertiser',
    inviteAdvertiserText:
      'The way to spread the word about your own content channel. More traffic to your channel means more views. Which will result in increasing your revenue.',
    inviteFoundation: 'Invite to foundation',
    inviteFoundationText: 'Invite a customer to the foundation page.',
    inviteVgm: 'Invite to VEO Global Markets',
    inviteVgmText: 'Invite a customer to the VEO Global Markets page.',
    inviteJoin: 'Invite to join',
    inviteJoinText:
      'Invite a customer to join veo through the join foundation page.',
    inviteLaunch: 'Invite to launch',
    inviteLaunchText:
      'Invite a customer to register himself before the release.',
    learnMore: 'Learn more',
    learnMoreInfo:
      'Learn how to earn more by recruiting friends to Veo partner so you can go through our training.',
    certified: 'You are certified',
    education: 'Education',
    foundationTitle: 'Support foundation and its cause',
    foundationText: 'The purpose of foundation can be seen at the link below.',
    vgmTitle: 'Support VEO Global Markets and its cause',
    vgmText: 'The purpose of VEO Global Markets can be seen at the link below.',
    createUser: 'Create new user',
    createUserText:
      'An email with login details will be sent to the user you add. During our beta you can create maximum 100 new users.',
    createUserTextSupport:
      'See the wiki »§3 Create new user« for more information.',
    createUserTextSupport2:
      'See the wiki »§2.1.1 Invite a sponsor« for more information.',
    enterUsersEmail: 'Enter user email',
    enterUsername: 'Enter channel name',
    createSalesman: 'Create new salesman',
    removeSalesman: 'Remove as salesman',
    activateSalesman: 'Activate salesman',
    newUserSuccess:
      'You have successfully created a new user with channel name:',
    activateSellerSuccess:
      'You have successfully activated a new salesman with channel name:',
    removeSellerSuccess:
      'You have removed the seller and is now a normal user instead.',
    errNewUser: 'Channel name already exists. Please try another name.',
    invitesLeft: 'Invites left:',
    invitesOf: 'of',
    sponsorNow: 'Sponsor now',
    limitReachedTxt:
      'You have reached your limit of users you can create. Make a sponsorship with a 200% return at 1 EUR per user you want to create. Your limit will also increase automatically equal to the sucess of your sales.',
    notApprovedTitle: 'Not yet approved as a sales representative',
    notApprovedText:
      'You first need to complete the knowledge test before you can create new users and invite sponsors by sharing links etc.',
    notApprovedButton: 'Take knowledge test',
    shareApproved: 'Approve external share',
    shareDenied: 'Deny external share',
    shareApprovedSuccess: 'The user can now share externally',
    shareDeniedSuccess: 'The user can no longer share externally',
    ban: 'Ban illegal (ONLY!)',
    unban: 'Restore user ban',
    banSuccess: 'Successfully banned user',
    unbanSuccess: 'Restored user successfully',
  },
  sv: {
    title: 'Dela dina länkar och bli belönad',
    title2: 'Bjud in en sponsor',
    titleText:
      'Dela dina personliga delnings-länkar. Vid rekrytering av ny användare får du en ersättning. Använd rätt länk beroende på typ av användare.',
    titleText2:
      'Bjud in intresserade parter att bidra ekonomiskt till VEO, ett projekt som syftar till att förbättra förhållandena för de som skapar och äger innehåll. Som säljare kommer din ersättning att vara proportionell mot storleken på de sponsrade bidragen du lyckas säkra. Detta innebär att ju mer sponsring du kan generera, desto större blir din egen ekonomiska belöning.',
    inviteSalesman: 'Säljare',
    inviteSalesmanText: 'Blir en del i ditt team.',
    inviteSponsor: 'Sponsor',
    inviteSponsorText: 'Bjud in någon som vill sponsra VEO.',
    inviteContentOwner: 'Bjud in en innehåll/kanalägare',
    inviteContentOwnerText:
      'Bjud in en innehållsägare eller kanalägare som du tror skulle uppskatta och dra nytta av att använda VEO. Rekrytera en artist, föreningsklubb eller någon annan som värdesätter sig själv och det innehåll de skapar. För varje innehållsägare du lyckas rekrytera får du en inkomst motsvarande 1% av vad de tjänar på sitt publicerade innehåll.',
    inviteFriend: 'Bjud in en vän',
    inviteFriendText:
      'Sättet att sprida ordet om din egen innehållskanal. Mer trafik till din kanal, troligen menas fler visningar. Vilket kommer att leda till att dina intäkter ökar.',
    inviteFoundation: 'Bjud in till stiftelsen',
    inviteFoundationText: 'Bjud in en kund till stiftelsesidan.',
    inviteVgm: 'Bjud in till VEO Global Markets',
    inviteVgmText: 'Bjud in en kund till VEO Global Markets.',
    inviteJoin: 'Bjud in att joina',
    inviteJoinText: 'Bjud in en kund att joina veo genom veo foundation sidan.',
    inviteLaunch: 'Bjud in till lansering',
    inviteLaunchText: 'Bjud in kund att registrera sig innan lansering.',
    learnMore: 'Lär dig mer',
    learnMoreInfo:
      'Lär dig att tjäna mer genom att rekrytera vänner till Veo partner så att du kan gå igenom vår utbildning.',
    certified: 'Du är certifierad',
    education: 'Utbildning',
    foundationTitle: 'Stötta stiftelsen',
    foundationText: 'Syftet med stiftelsen kan ses via länken nedan.',
    vgmTitle: 'Stötta VEO Global Markets',
    vgmText: 'Syftet med VEO Global Markets kan ses via länken nedan.',
    createUser: 'Skapa ny användare',
    createUserText:
      'Ett epost-meddelande med inloggningsuppgifter kommer att skickas till användaren du lägger till. Under vår beta-period kan du skapa max 100 nya användare.',
    createUserTextSupport:
      'Se wikin »§3 Skapa ny användare« för mer information.',
    createUserTextSupport2:
      'Se wikin »§2.1.1 Bjud in en sponsor« för mer information.',
    enterUsersEmail: 'Ange användarens email',
    enterUsername: 'Ange ett kanalnamn',
    createSalesman: 'Skapa ny säljare',
    removeSalesman: 'Tabort som säljare',
    activateSalesman: 'Aktivera säljare',
    newUserSuccess: 'Du har nu skapat en ny användare med kanalnamn:',
    activateSellerSuccess: 'Du har aktiverat en ny säljare med kanalnamn:',
    removeSellerSuccess:
      'Du har tagit bort säljaren som nu är en vanlig användare istället.',
    errNewUser: 'Kanalnamnet finnas redan. Vänligen prova ett annat kanalnamn.',
    invitesOf: 'av',
    invitesLeft: 'Inbjudningar kvar:',
    sponsorNow: 'Sponsra nu',
    limitReachedTxt:
      'Du har uppnått din gräns på hur många användare du kan skapa. Sponsra med 200% återbetalning för 1 EUR per användare du vill skapa. Din gräns kommer också automatiskt att öka beroende på hur bra din försäljning går.',
    notApprovedTitle: 'Inte godkänd som säljare än',
    notApprovedText:
      'Du måste först genomföra ett kunskapstest innan du kan skapa nya användare och bjuda in sponsorer via delningslänkar m.m.',
    notApprovedButton: 'Genomför kunskapstest',
    shareApproved: 'Godkänn extern delning',
    shareDenied: 'Neka extern delning',
    shareApprovedSuccess: 'Användaren kan nu dela externt',
    shareDeniedSuccess: 'Användaren kan inte längre dela externt',
    ban: 'Banna olaglig (ENDAST!)',
    unban: 'Återställ ban',
    banSuccess: 'Bannat användaren framgångsrikt',
    unbanSuccess: 'Återställt användaren framgångsrikt',
  },
});
