import React from 'react';
import LocalizedStrings from 'react-localization';
import {
  View,
  ActivityIndicator,
  Text,
} from 'react-native';
import { NAVBAR_HEIGHT } from '../TopBar';

import { fonts, colors, fontsize } from '../../theme'

const PageLoader = ({ loading, error, children, noNavBar }) => {
  if (loading) {
    return <View style={{
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      marginTop: noNavBar ? NAVBAR_HEIGHT : 0
    }}>
     <ActivityIndicator color={colors.black}  />
   </View>
  }
  if (error) {
    return <View style={{
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
      <Text style={{
        fontFamily: fonts.regular,
        fontSize: fontsize.regular,
        color: colors.black
      }}>
        {strings.error}
     </Text>
   </View>
  }
  
  return children;
}

const strings = new LocalizedStrings({
  en: {
    error: 'Something went wrong. Try refreshing the page.',
  },
  sv: {
    error: 'Något gick fel. Försök ladda om sidan.',
  }
});

export default PageLoader