import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
} from 'react-native';
import { colors, fonts, } from '../../theme'
import LocalizedStrings from 'react-localization';

const InputField = ({
  input,
  currency,
  recurrent,
  placeholder,
  keyboardType,
  onChangeText,
  invalid,
  invalidText,
  extraStyles,
  label = null }) => <View style={extraStyles}>
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {label}
      {invalid && <Text style={{
        fontSize: 12,
        marginLeft: 5,
        color: colors.redWarning,
        fontFamily: fonts.regular
      }}>
        {invalidText ? invalidText : strings.invalid}
      </Text>}
    </View>
    <View style={styles.inputRow}>
      
      <TextInput
        style={styles.input}
        placeholder={placeholder}
        keyboardType={keyboardType}
        value={input}
        onChangeText={onChangeText}
      />
      <Text 
        style={styles.inputCurrency}>{recurrent ? currency + ' ' + strings.perMonth : currency} </Text>
    </View>
    
  </View>

export default InputField

const strings = new LocalizedStrings({
  en: {
    invalid: 'Invalid input',
    perMonth: '/month',
  },
  sv: {
    invalid: 'Ogiltig input',
    perMonth: '/ månad',
  },
});

const styles = StyleSheet.create({
  inputRow: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 1,
    width: '100%'
  },
  inputCurrency: {
    position: 'absolute',
    right: 0,
    fontFamily: fonts.regular,
    color: colors.black,
    fontSize: 14,
    paddingRight: 20,
    maxHeight: 64,
  },
  input: {
    flex: 1,
    padding: 20,
    maxHeight: 64,
    fontFamily: fonts.regular,
    color: colors.black,
    fontSize: 16,
    backgroundColor: colors.greySolo,
    borderRadius: 8,
  },
  inputBottom: {
    height: 1,
    backgroundColor: colors.greyLuke,
    marginVertical: 10,
  },
})