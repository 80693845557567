export const font = 'rubikregular';
export const fontItalic = 'rubikitalic';
export const fontLight = 'rubiklight';
export const fontLightItalic = 'rubiklight_italic';

export const fontStyle = {
  fontFamily: font,
  fontWeight: 'normal',
};

export const fontStyleBold = {
  fontFamily: font,
  fontWeight: '500',
};

export const fontStyleItalic = {
  fontFamily: fontItalic,
  fontWeight: 'normal',
};

export const fontStyleLight = {
  fontFamily: font,
  fontWeight: 'normal',
};

export const fontStyleLightItalic = {
  fontFamily: fontLightItalic,
  fontWeight: '400',
};
