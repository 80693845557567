import React, { useState } from 'react';
import LocalizedStrings from 'react-localization';
import { FlatList, View, Text } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {
  MY_SPONSORSHIPS,
  GET_CURRENCY,
  TOGGLE_ACTIVE,
  UPDATE_AD,
} from '../../gql/gqls';
import SponsorInput from '../../components/SponsorInput';
import WideButton from '../../components/misc/WideButton';
import { myAccountVar } from '../../reactiveVariables';
import { sponsorTypes } from '../../helpers/constants';
import Avatar from '../../components/Avatar';
import Stat from '../../components/Stat';
import Fetching from '../../components/Fetching';
import Select from '../../components/Select';

import {
  colors,
  fonts,
  gutters,
  mainTitle,
  mediaQueries,
  screenStyles,
  useMobileSize,
  commonStyles,
} from '../../theme';

export default function MySponsorship() {
  const isMobile = useMobileSize();
  const history = useHistory();
  const { isSmallScreen } = useWindowDimensions();
  const user = useReactiveVar(myAccountVar);
  const [sponsorType, setSponsorType] = useState('all');
  const [sponsorshipToUpdate, setSponsorshipToUpdate] = useState(null);
  const { data: currencyData } = useQuery(GET_CURRENCY);
  const [
    toggleActive,
    { loading: toggleActiveLoading, error: toggleActiveError },
  ] = useMutation(TOGGLE_ACTIVE);
  const [updateAd, { loading: updateAdLoading, error: updateAdError }] =
    useMutation(UPDATE_AD);

  const { maxSponsor } = currencyData?.getOnlyCurrency || {
    maxSponsor: 10000,
  };

  const { data, loading, error } = useQuery(MY_SPONSORSHIPS, {
    fetchPolicy: 'cache-and-network',
  });

  const ads = data?.sponsorships?.filter(
    (item) => item.type === sponsorTypes.AD
  );
  const repayments = data?.sponsorships?.filter(
    (item) => item.type === sponsorTypes.SPECIFIC
  );

  const selectOptions = [
    { value: 'all', text: strings.all },
    { value: sponsorTypes.SPECIFIC, text: strings.withRepayments },
    { value: sponsorTypes.AD, text: strings.withAds },
  ];

  const DATA =
    sponsorType === 'all'
      ? data?.sponsorships
      : sponsorType === sponsorTypes.SPECIFIC
        ? repayments
        : ads;

  return (
    <View style={screenStyles.main}>
      <View style={screenStyles.innerBody}>
        <View
          style={{
            maxWidth: mediaQueries.tablet,
            width: '100%',
            marginBottom: 20,
            zIndex: 1000,
          }}
        >
          <Text style={mainTitle(isMobile)}>{strings.mySponsorships}</Text>
          <Select
            selected={selectOptions.find((o) => o.value === sponsorType).text}
            onChange={(value) => setSponsorType(value)}
            options={selectOptions}
          />
        </View>
        <FlatList
          style={{ paddingHorizontal: isMobile ? gutters.pageGutter : 0 }}
          data={
            DATA
              ? [...DATA].sort(
                (a, b) =>
                  new Date(b.date).getTime() - new Date(a.date).getTime()
              )
              : []
          }
          keyExtractor={(item) => item.id}
          ListEmptyComponent={<Fetching error={error} loading={loading} />}
          renderItem={({ item }) =>
            item.type === sponsorTypes.GENERIC ? (
              <RepaymentItem
                item={item}
                toggleActive={toggleActive}
                toggleActiveLoading={toggleActiveLoading}
                sponsorshipToUpdate={sponsorshipToUpdate}
                setSponsorshipToUpdate={setSponsorshipToUpdate}
                isMobile={isMobile}
                history={history}
              />
            ) : item.type === sponsorTypes.SPECIFIC ? (
              <RepaymentItem
                item={item}
                toggleActive={toggleActive}
                toggleActiveLoading={toggleActiveLoading}
                sponsorshipToUpdate={sponsorshipToUpdate}
                setSponsorshipToUpdate={setSponsorshipToUpdate}
                isMobile={isMobile}
                history={history}
              />
            ) : item.type === sponsorTypes.AD ? (
              <AdItem
                toggleActive={toggleActive}
                maxSponsor={maxSponsor}
                toggleActiveLoading={toggleActiveLoading}
                toggleActiveError={toggleActiveError}
                item={item}
                isMobile={isMobile}
                sponsorshipToUpdate={sponsorshipToUpdate}
                setSponsorshipToUpdate={setSponsorshipToUpdate}
                updateAd={updateAd}
                updateAdLoading={updateAdLoading}
                updateAdError={updateAdError}
                history={history}
              />
            ) : (
              <View />
            )
          }
          ItemSeparatorComponent={() => <View style={{ height: 20 }} />}
          ListFooterComponent={() => <View style={{ height: 40 }} />}
        />
      </View>
    </View>
  );
}

const RepaymentItem = ({
  item,
  toggleActive,
  toggleActiveLoading,
  setSponsorshipToUpdate,
  sponsorshipToUpdate,
  isMobile,
  history,
}) => (
  <View
    style={{
      paddingVertical: 20,
      borderWidth: 1,
      borderColor: colors.greySolo,
      backgroundColor: colors.white,
      borderRadius: 4,
    }}
  >
    <View
      style={{
        marginHorizontal: 20,
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        height: 40,
      }}
    >
      <Avatar
        image={item.smlImage}
        size={40}
        extraStyles={{ marginRight: 10, borderWidth: 1, borderColor: colors.greySolo }}
      />
      <Text
        style={{
          fontSize: fonts.medium,
          fontFamily: fonts.regular,
        }}
      >
        {item?.sponsorship?.name
          ? item.sponsorship.name
          : strings.genericSponsor}
      </Text>
    </View>
    <View
      style={{
        marginHorizontal: 20,
        flexShrink: 1,
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'flex-start' : 'center',
        justifyContent: isMobile ? 'flex-start' : 'space-between',
      }}
    >
      <View
        style={{
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          width: '100%',
          flexShrink: 1,
          marginTop: 20,
          justifyContent: isMobile ? 'space-between' : 'flex-start',
        }}
      >
        <Stat
          label={strings.sponsorAmount}
          value={item.sponsored.toFixed(0) + ' ' + item.currency}
          extraStyles={{ marginRight: isMobile ? 0 : 50, marginTop: isMobile ? 20 : 0 }}
        />

        <Stat
          label={strings.return}
          value={
            (item.paidBack * item.veoRate).toFixed(2).replace(/[.,]00$/, "") + ' ' + item.currency
          }
          extraStyles={{ marginRight: isMobile ? 0 : 50, marginTop: isMobile ? 20 : 0 }}
        />

        <Stat
          label={strings.expectedReturn}
          value={
            (item.maxPaid ? item.maxPaid.toFixed(0) : 0) + ' ' + item.currency
          }
          valueStyle={{ color: colors.black }}
          extraStyles={{ marginRight: isMobile ? 0 : 50, marginTop: isMobile ? 20 : 0 }}
        />

        <Stat
          label={strings.status}
          value={item.isPaid ? strings.isPaid : strings.isNotPaid}
          valueStyle={{ color: item.isPaid ? colors.goGreen : colors.greyLeia, height: 24, fontSize: 12, lineHeight: 24, }}
          extraStyles={{ marginRight: isMobile ? 0 : 50, marginTop: isMobile ? 20 : 0 }}
        />
      </View>
      {item?.sponsorship && (
        <WideButton
          pill
          onPress={() =>
            history.push(`/discover/${item.sponsorship.id}?expand=sponsorAd`)
          }
          text={strings.buyAd}
          buttonStyle={{
            width: isMobile ? '100%' : 250,
            marginTop: isMobile ? 20 : 0,
          }}
        />
      )}
    </View>
    {item.recurring && (
      <View
        style={{
          borderTopColor: colors.greySolo,
          borderTopWidth: 1,
          marginTop: 20,
          paddingTop: 20,
          paddingHorizontal: 20,
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          justifyContent: isMobile ? 'flex-start' : 'space-between',
        }}
      >
        <Stat
          label={strings.monthlyAmount}
          value={item.monthly + ' ' + item.currency}
        />
        <WideButton
          loading={toggleActiveLoading && sponsorshipToUpdate === item.id}
          text={item.active ? strings.stopSponsor : strings.startSponsor}
          textStyle={{
            color: item.active ? colors.redWarning : colors.white,
          }}
          onPress={() => {
            setSponsorshipToUpdate(item.id);
            toggleActive({
              variables: {
                id: item.id,
              },
            });
          }}
          buttonStyle={{
            height: 40,
            backgroundColor: item.active
              ? colors.backgroundRed
              : colors.goGreen,
            width: isMobile ? '100%' : 250,
            marginTop: isMobile ? 20 : 0,
          }}
        />
      </View>
    )}
  </View>
);



const AdItem = ({
  item,
  isMobile,
  maxSponsor,
  toggleActive,
  toggleActiveLoading,
  toggleActiveError,
  sponsorshipToUpdate,
  setSponsorshipToUpdate,
  updateAd,
  updateAdLoading,
  updateAdError,
}) => {
  const [cpc, setCpc] = useState(item.cpc);
  const [maxDaily, setMaxDaily] = useState(item.maxDaily);

  const hasEdited = cpc !== item.cpc || maxDaily !== item.maxDaily;

  return (
    <View
      style={{
        padding: 20,
        borderWidth: 1,
        borderColor: colors.greySolo,
        backgroundColor: colors.greyBoba,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          height: 40,
        }}
      >
        <Avatar
          image={item.smlImage}
          size={40}
          extraStyles={{ marginRight: 20 }}
        />
        <Text
          style={{
            fontSize: 14,
            fontFamily: fonts.medium,
          }}
        >
          {item?.sponsorship?.name}
        </Text>
      </View>
      <View
        style={{
          flexShrink: 1,
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          justifyContent: isMobile ? 'flex-start' : 'space-between',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            flexShrink: 1,
            marginVertical: 20,
            justifyContent: 'space-between',
            backgroundColor: colors.lightBlue,
            padding: 10,
          }}
        >
          <View
            style={{
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'flex-start' : 'center',
              justifyContent: isMobile ? 'flex-start' : 'space-around',
              flexGrow: 1,
            }}
          >
            <Stat
              labelStyle={{ color: colors.deepBlue }}
              label={strings.amountSpent}
              value={0}
            />
            <Stat
              labelStyle={{ color: colors.deepBlue }}
              label={strings.reach}
              value={0}
              extraStyles={{ marginTop: isMobile ? 10 : 0 }}
            />
          </View>
          <View
            style={{
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'flex-start' : 'center',
              justifyContent: isMobile ? 'flex-start' : 'space-around',
              flexGrow: 1,
            }}
          >
            <Stat
              labelStyle={{ color: colors.deepBlue }}
              label={strings.impressions}
              value={0}
            />
            <Stat
              labelStyle={{ color: colors.deepBlue }}
              label={strings.clicks}
              value={0}
              extraStyles={{ marginTop: isMobile ? 10 : 0 }}
            />
            <Stat
              labelStyle={{ color: colors.deepBlue }}
              label={strings.status}
              value={item.isPaid ? strings.isPaid : strings.isNotPaid}
              valueStyle={{
                color: item.isPaid ? colors.goGreen : colors.greyLeia,
              }}
              extraStyles={{ marginTop: isMobile ? 10 : 0 }}
            />
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          justifyContent: isMobile ? 'flex-start' : 'space-between',
        }}
      >
        <SponsorInput
          label={
            <Text style={[commonStyles.infoText, { marginBottom: 0 }]}>
              {strings.costPerClick}
            </Text>
          }
          currency={item.currency}
          input={cpc + ''}
          extraStyles={isMobile ? { width: '100%' } : null}
          onChangeText={(input) => {
            console.log(input, 'fkdklfkld');
            const inputAmount = parseFloat(input);
            const numericRegex = /^([0-9]{1,100})+$/;
            if (numericRegex.test(inputAmount)) {
              if (inputAmount <= maxSponsor) {
                setCpc(inputAmount);
              } else {
              }
            } else {
              setCpc(inputAmount + '');
            }
          }}
        />
        <SponsorInput
          label={
            <Text style={[commonStyles.infoText, { marginBottom: 0 }]}>
              {strings.maxBudget}
            </Text>
          }
          currency={item.currency}
          input={maxDaily + ''}
          extraStyles={isMobile ? { width: '100%' } : null}
          onChangeText={(input) => {
            const inputAmount = parseFloat(input);
            const numericRegex = /^([0-9]{1,100})+$/;
            if (numericRegex.test(inputAmount)) {
              if (inputAmount <= maxSponsor) {
                setMaxDaily(inputAmount);
              } else {
              }
            } else {
              setMaxDaily(inputAmount + '');
            }
          }}
        />
      </View>
      <View
        style={{
          marginTop: 20,
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          justifyContent: isMobile ? 'flex-start' : 'space-between',
        }}
      >
        <WideButton
          loading={toggleActiveLoading && sponsorshipToUpdate === item.id}
          onPress={() => {
            if (hasEdited) {
              setCpc(item.cpc);
              setMaxDaily(item.maxDaily);
            } else {
              setSponsorshipToUpdate(item.id);
              toggleActive({
                variables: {
                  id: item.id,
                },
              });
            }
          }}
          text={
            hasEdited
              ? strings.cancel
              : item.active
                ? strings.stopAd
                : strings.startAd
          }
          textStyle={{
            color: hasEdited || item.active ? colors.redWarning : colors.white,
          }}
          buttonStyle={{
            width: isMobile ? '100%' : 250,
            marginTop: isMobile ? 20 : 0,
            backgroundColor:
              hasEdited || item.active ? colors.backgroundRed : colors.goGreen,
          }}
        />
        <WideButton
          loading={updateAdLoading && sponsorshipToUpdate === item.id}
          onPress={() => {
            if (hasEdited) {
              setSponsorshipToUpdate(item.id);
              updateAd({
                variables: {
                  id: item.id,
                  cpc,
                  maxDaily,
                },
              });
            }
          }}
          text={strings.confirm}
          buttonStyle={{
            width: isMobile ? '100%' : 250,
            marginTop: isMobile ? 20 : 0,
            backgroundColor: hasEdited ? colors.goGreen : colors.greySolo,
          }}
        />
      </View>
    </View>
  );
};

const Bord = ({ extraStyles, isMobile }) =>
  <View
    style={{
      backgroundColor: colors.greySolo,
      width: 1,
      height: '100%'
    }}>
  </View>;



const strings = new LocalizedStrings({
  en: {
    mySponsorships: 'Sponsorships',
    all: 'All',
    withRepayments: 'Sponsored with repayments',
    withAds: 'Sponsored with ads',
    return: 'Current return',
    sponsorAmount: 'Sponsored amount',
    expectedReturn: 'Expected return',
    buyAd: 'Buy Ad',
    monthlyAmount: 'Monthly amount',
    cancel: 'Cancel',
    stopSponsor: 'Stop',
    startSponsor: 'Start',
    maxBudget: 'Max Daily Budget *',
    costPerClick: 'Cost per Click *',
    stopAd: 'Stop ad',
    startAd: 'Start ad',
    confirm: 'Confirm',
    averageCpc: 'Average CPC',
    totalDailyBudget: 'Total Daily Budget',
    amountSpent: 'Amount spent',
    reach: 'Maximum reach',
    impressions: 'Impressions',
    clicks: 'Clicks',
    isNotPaid: 'Awaiting approval',
    isPaid: 'Paid and active',
    status: 'Status',
    genericSponsor: 'VEO Sponsorship',
  },
  sv: {
    mySponsorships: 'Sponsringar',
    all: 'Alla',
    withRepayments: 'Sponsra med återbetalningar',
    withAds: 'Sponsra med annonser',
    return: 'Återbetalt',
    sponsorAmount: 'Sponsrat belopp',
    expectedReturn: 'Förväntad återbetalning',
    buyAd: 'Köp annons',
    monthlyAmount: 'Månads belopp',
    cancel: 'Avbryt',
    stopSponsor: 'Stoppa',
    startSponsor: 'Starta',
    maxBudget: 'Max daglig budget *',
    costPerClick: 'Kostnad per klick *',
    stopAd: 'Avsluta annons',
    startAd: 'Starta annons',
    confirm: 'Bekräfta',
    averageCpc: 'Genomsnittlig CPC-kostnad',
    totalDailyBudget: 'Total daglig budget',
    amountSpent: 'Belopp spenderat',
    reach: 'Maximal räckvidd',
    impressions: 'Impressions',
    clicks: 'Clicks',
    isNotPaid: 'Väntar godkännande',
    isPaid: 'Betald och aktiv',
    status: 'Status',
    genericSponsor: 'VEO Sponsorskap',
  },
});
