import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity, TextInput, ActivityIndicator } from 'react-native';
import LocalizedStrings from 'react-localization';
import { CheckBox } from 'react-native-web';
import { isMobile as isMobileDevice } from 'react-device-detect';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { TRANSACTION_DONE } from '../../gql/gqls';
import { fontStyleLight, fontStyle, fontStyleBold } from '../../styles/fonts';
import {
  black,
  white,
  successGreen,
  darkGrey,
  superLightGrey,
  systemBlueLight,
  systemBlueDark, successButton, grey,
} from '../../styles/colors';
import { sponsorVar, myAccountVar, shareLinkVar } from '../../../src/reactiveVariables';
import ListPaymentMethods from '../../components/payment/ListPaymentMethods';
import InputField from '../../components/InputField';
import {
  fonts,
  colors,
  fontsize,
  useMobileSize
} from '../../theme';

const openVeoWorldLink = (link) => {
  window.open(`https://veoworld.org/org/${link}.html`, '_blank');
};

export default function SponsorPayment() {
  const history = useHistory();
  const sponsorData = useReactiveVar(sponsorVar);
  const account = useReactiveVar(myAccountVar);
  const shareLinkData = useReactiveVar(shareLinkVar);
  const shareId = shareLinkData?.shortId ? shareLinkData.shortId : null;
  const { currency, amount, VAT, sponsorshipVisible, recurrent } = sponsorData;
  const [confirm, setConfirm] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [waitLoading, setWaitLoading] = useState(false);
  const [confirmItem, setConfirmItem] = useState([{}]);
  const [confirmTitle, setConfirmTitle] = useState(null);
  const [confirmMonths, setConfirmMonths] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [email, setEmail] = useState(account.email);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [missingEmail, setMissingEmail] = useState(false);
  const [missingPhone, setMissingPhone] = useState(false);
  const [missingName, setMissingName] = useState(false);
  const [makePayment, setMakePayment] = useState(null);
  const [isCompany, setIsCompany] = useState(false);
  const [orgNr, setOrgNr] = useState(null);
  const shortId = shareLinkData?.shortId ? shareLinkData.shortId : null;
  const isCreatorWeek =
    process.env.REACT_APP_ENV === 'stage'
      ? shortId === 'dF4B7FUZNaI'
      : process.env.REACT_APP_ENV === 'prod'
      ? shortId === 'yb5zlXNnhNH'
      : shortId === 'key123';

  const isMobile = useMobileSize();

  useEffect(() => {
    // 👇️ Scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, [history]);

  useQuery(TRANSACTION_DONE, {
    skip: !transactionId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    pollInterval: 3000,
    variables: {
      id: transactionId ? transactionId : '',
    },
    onCompleted: (data) => {
      if (data.transactionDone) {
        window.open(`/receipt/${transactionId}`, '_self');
      }
    },
  });

  return (
    !confirm ? (
      <View style={{
        backgroundColor: colors.white,
        paddingHorizontal: isMobile ? 20 : 40,
        paddingVertical: isMobile ? 20 : 40,
        borderRadius: 8,
        marginBottom: 100,
        boxShadow: '3px 2px 20px -3px rgba(0, 0, 0, 0.09)',
      }}>
        <Text style={styles.supportHeader}>{strings.supportHeader}</Text>
        <View style={styles.paymentContainer}>
          <Text
            style={[styles.paymentInfo, { fontSize: 16, fontFamily: fonts.medium }]}>{strings.toPay}: {amount} {currency}{recurrent ? strings.perMonth : ''}</Text>
          {VAT && <Text
            style={[styles.paymentInfo, {fontSize: 14, color: colors.greyLeia, marginTop: 4}]}>{isCreatorWeek ? strings.sponsorCreator : strings.genericSponsorship}, {strings.includingVAT} {VAT}%</Text>}
          <View style={styles.checkBoxRow}>
            <CheckBox
              style={styles.checkBox}
              value={isCompany}
              onValueChange={(value) => setIsCompany(value)}
              color={successButton}
            />
            <Text style={{fontFamily: fonts.regular}}>{strings.isCompany}</Text>
          </View>
          {isCompany ?
            <TextInput
              style={styles.input}
              placeholder={strings.enterOrgNr}
              value={orgNr}
              onChangeText={(input) => setOrgNr(input.toUpperCase())}
            />
            : <View />
          }
        </View>
        <View style={[styles.paymentInfoContainer, { marginTop: 32, flexDirection: 'column' }]}>
        <Text style={styles.supportHeader}>{strings.paymentMethod}</Text>
          <Text style={styles.paymentInfo}>{strings.paymentInfo}</Text>
        </View>
        <ListPaymentMethods
          type='sponsorGeneric'
          amount={amount}
          currency={currency}
          name={isCreatorWeek ? strings.sponsorCreator : recurrent ? strings.sponsorGenericMonthly : strings.sponsorGeneric}
          recurring={false}
          verificationOnly={false}
          visibleOthers={sponsorshipVisible}
          orgNr={isCompany && orgNr?.trim() !== '' ? orgNr : null}
          setMakePayment={setMakePayment}
          setConfirmItem={setConfirmItem}
          setConfirmTitle={setConfirmTitle}
          setConfirm={setConfirm}
          setConfirmLoading={setConfirmLoading}
          setWaitLoading={setWaitLoading}
          setConfirmMonths={setConfirmMonths}
          setTransactionId={setTransactionId}
          isMonthly={false}
          disabled={false}
          shareId={shortId}
        />
        <View style={styles.agreementContainer}>
          <Text style={styles.agreement}>
            {strings.agreement}{' '}
            <Text
              onPress={() => openVeoWorldLink('termsofuse')}
              style={styles.agreementLink}
            >
              {strings.termsOfUse}
            </Text>
            {', '}
            <Text
              onPress={() => openVeoWorldLink('privacypolicy')}
              style={styles.agreementLink}
            >
              {strings.privacyPolicy}
            </Text>
            {` ${strings.and} `}
            <Text
              onPress={() => openVeoWorldLink('cof')}
              style={styles.agreementLink}
            >
              {strings.cof}
            </Text>
            {'.'}
          </Text>
        </View>

        <View style={styles.navRow}>
          <TouchableOpacity
            style={styles.backContainer}
            onPress={() => history.push('/sponsor' + (shareLinkData?.shortId ? '/s/' + shareLinkData.shortId : ''))}
          >
            <Image
              style={styles.chevronIcon}
              source={{ uri: 'icons/nav/chevron.jpg' }}
            />
            <Text style={styles.back}>{strings.back}</Text>
          </TouchableOpacity>
          <View />
        </View>
      </View>)
      :
      !confirmLoading ? (
        <View
          style={
            {
              backgroundColor: colors.white,
              paddingHorizontal: isMobile ? 20 : 40,
              paddingVertical: isMobile ? 20 : 40,
              borderRadius: 8,
              marginBottom: 100,
              boxShadow: '3px 2px 20px -3px rgba(0, 0, 0, 0.09)',
            }
          }
        >
          <Text style={styles.supportHeader}>{strings.continueToPayment} - {confirmTitle}</Text>
          <Text style={styles.confirmInfoText}>
            {strings.requiredFieldsText + ' ' + confirmItem?.providerName + '. ' + strings.requiredFieldsInfo}
          </Text>
          {!account.email ? (
            <InputField
              extraStyles={
                missingEmail && {
                  borderColor: colors.redWarning,
                  borderWidth: 2,
                }
              }
              placeholder={strings.email.toUpperCase()}
              keyboardType={'email-address'}
              placeholderTextColor={colors.greyDarth}
              onChangeText={(input) => {
                setEmail(input);
                setMissingEmail(false);
              }}
              value={email}
            />
          ) : (
            <View />
          )}
          {confirmItem?.provider === 'trustly' &&
            <View>
              <InputField
                extraStyles={
                  missingName && {
                    borderColor: colors.redWarning,
                    borderWidth: 2,
                  }
                }
                placeholder={strings.firstName}
                placeholderTextColor={colors.greyDarth}
                onChangeText={(input) => setFirstName(input)}
                value={firstName}
              />
              <InputField
                extraStyles={[
                  missingName && {
                    borderColor: colors.redWarning,
                    borderWidth: 2,
                  },
                  {marginTop: 10}
                ]
                }
                placeholder={strings.lastName}
                placeholderTextColor={colors.greyDarth}
                onChangeText={(input) => setLastName(input)}
                value={lastName}
              />
            </View>
          }
          {confirmItem?.provider === 'swish' && !isMobileDevice &&
            <View>
              <InputField
                extraStyles={
                  missingPhone && {
                    borderColor: colors.redWarning,
                    borderWidth: 2,
                  }
                }
                placeholder={strings.phone}
                placeholderTextColor={colors.greyDarth}
                onChangeText={(input) => {
                  setPhone(input);
                  setMissingPhone(false);
                }}
                value={phone}
              />
            </View>
          }
          <View style={styles.closeConfirmRow}>
            <TouchableOpacity
              style={styles.cancelButton}
              onPress={() => {
                setConfirm(false);
                window.scrollTo(0, 0);
              }}
            >
              <Text style={styles.cancelText}>{strings.cancel}</Text>
            </TouchableOpacity>

            <TouchableOpacity
            style={{
              paddingHorizontal: 24,
              height: 40,
              backgroundColor: colors.deepBlue,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 4,
            }}
              onPress={() => {
                let errEmail =
                  !email ||
                  email.length < 3 ||
                  !email.includes('@') ||
                  !email.includes('.');
                let errName = false;
                let errPhone = false;
                setMissingEmail(errEmail);
                if (confirmItem.provider === 'trustly') {
                  errName = !firstName || !lastName;
                  setMissingName(errName);
                }
                if (confirmItem.provider === 'swish' && !phone) {
                  errPhone = true;
                  setMissingPhone(true);
                }
                if (!errEmail && !errName && !errPhone) {
                  makePayment(
                    confirmItem,
                    'sponsorGeneric',
                    amount,
                    currency,
                    null,
                    null,
                    isCreatorWeek ? strings.sponsorCreator : strings.genericSponsorship,
                    null,
                    orgNr,
                    {
                      email: email ? email : '',
                      firstName: firstName ? firstName : '',
                      lastName: lastName ? lastName : '',
                      phone: phone ? phone : ''
                    },
                    null,
                    shareId
                  );
                  setConfirmLoading(true);
                }
              }}
            >

              
              <Text style={styles.actionText}>
                {strings.continueToPayment}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      )
        : (
          <View style={styles.loadingContainer}>
            <ActivityIndicator style={{ marginRight: 10 }} />
            {waitLoading ?
              <Text style={styles.loadingText}>{strings.openWaiting + ' ' + confirmItem.providerName + ' ' + strings.openWaitingConfirm} ...</Text>
              :
              <Text style={styles.loadingText}>{strings.waiting + ' ' + confirmItem.providerName} ...</Text>
            }
          </View>
        )
  );
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
    paddingBottom: 15,
    top: '45%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 30,
  },
  loadingText: {
    textAlign: 'center',
    width: '100%',
    marginTop: 10
  },
  supportHeader: {
    marginBottom: 10,
    fontSize: 24,
    lineHeight: 32,
    color: black,
    fontFamily: fonts.medium,
  },
  greenHeader: {
    marginTop: 15,
    ...fontStyleBold,
    color: successGreen,
    fontSize: 20,
    lineHeight: 25,
    fontFamily: fonts.regular,
  },
  paymentHeader: {
    marginTop: 15,
    ...fontStyleBold,
    fontSize: 20,
    lineHeight: 25,
    color: black,
    fontFamily: fonts.regular,
  },
  paymentContainer: {
    marginTop: 10,
    padding: 20,
    borderRadius: 8,
    backgroundColor: colors.white,
  },
  paymentInfoContainer: {
    flexDirection: 'column',
  },
  paymentInfo: {
    color: colors.black,
    ...fontStyle,
    fontSize: 14,
    fontFamily: fonts.regular,
  },
  paymentRow: {
    marginTop: 20,
    marginBottom: 10,
    backgroundColor: superLightGrey,
    height: 64,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 4,
  },
  cardsRow: {
    paddingLeft: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardIcon: {
    width: 34,
    height: 24,
  },
  payRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 26,
  },
  payText: {
    color: darkGrey,
    ...fontStyle,
    fontSize: 16,
    marginRight: 17,
    fontFamily: fonts.regular,
  },
  rightChevron: {
    width: 6,
    height: 12,
    transform: [{ rotate: '180deg' }],
  },
  trustlyIcon: {
    width: 36,
    height: 10.8,
    marginLeft: 20,
  },
  agreementContainer: {
    marginTop: 20,
    marginBottom: 40,
  },
  agreement: {
    ...fontStyleLight,
    fontSize: 12,
    color: darkGrey,
    fontFamily: fonts.regular,
  },
  agreementLink: {
    ...fontStyle,
    fontSize: 12,
    color: darkGrey,
    textDecorationLine: 'underline',
    fontFamily: fonts.regular,
  },
  navRow: {
    marginTop: 20,
    marginBottom: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  backContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  chevronIcon: {
    width: 6,
    height: 12,
  },
  back: {
    marginLeft: 17,
    ...fontStyle,
    color: darkGrey,
    fontSize: 14,
    fontFamily: fonts.regular,
  },
  checkBoxRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 16,
  },
  checkBox: {
    height: 16,
    width: 16,
    marginRight: 10,
  },
  confirmHeader: {
    marginTop: 24,
    fontFamily: fonts.regular,
    fontSize: fontsize.medium,
    lineHeight: 20,
    color: colors.black,
    marginBottom: 10,
  },
  confirmInfoText: {
    marginBottom: 24,
    fontFamily: fonts.regular,
    fontSize: fontsize.medium,
    lineHeight: 20,
    color: colors.black,
  },
  closeConfirmRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
  },
  cancelButton: {
    marginRight: 30,
  },
  cancelText: {
    fontFamily: fonts.regular,
    fontSize: fontsize.medium,
    lineHeight: 40,

    color: colors.greyLeia,
  },
  actionText: {
    color: colors.white,
    fontFamily: fonts.medium,
    fontSize: fontsize.medium,
  },
  input: {
    marginTop: 16,
    borderRadius: 4,
    minHeight: 48,
    paddingHorizontal: 10,
    ...fontStyleLight,
    fontSize: 14,
    lineHeight: 14,
    color: black,
    fontFamily: fonts.regular,
    backgroundColor: colors.white
  },
});

const strings = new LocalizedStrings({
  en: {
    supportHeader: 'Support and sponsor VEO',
    paymentInfo: 'Once you pick a payment option, you will be redirected from this page.',
    paymentMethod: 'Choose payment method',
    includingVAT: 'price including VAT tax',
    payWithCard: 'Pay with Card',
    bankPayment: 'Bank Payment',
    agreement: `By continuing with this purchase and selecting a payment method you agree to VEO's`,
    termsOfUse: 'Terms of Use (EULA)',
    privacyPolicy: 'Privacy Policy',
    and: 'and',
    cof: 'COF',
    back: 'Back',
    genericSponsorship: 'VEO sponsorship',
    toPay: 'Amount to pay',
    isCompany: 'Check this box if you are making this payment as a company',
    enterOrgNr: 'Enter Your Company VAT number',
    sponsorGeneric: 'VEO sponsorship',
    sponsorGenericMonthly: 'VEO monthly sponsorship',
    perMonth: '/month',
    email: 'Email (For access to subscription)',
    continueToPayment: 'Continue to payment',
    cancel: 'Cancel',
    donationVAT: 'Including donation VAT tax of',
    requiredFieldsText: 'Please fill in below. Required by',
    requiredFieldsInfo: 'Information entered here will be deleted from our system as soon as the transaction is complete.',
    firstName: 'First name',
    lastName: 'Last name',
    phone: 'Mobile phone number',
    waiting: 'Waiting for response from',
    openWaiting: 'Please open',
    openWaitingConfirm: 'to confirm the payment',
    sponsorCreator: 'Sponsor Kreatörsveckan',
  },
  sv: {
    supportHeader: 'Stöd och sponsra VEO',
    paymentInfo: 'När du väljer betalmetod kommer du skickas vidare ifrån denna sida.',
    paymentMethod: 'Välj betalmetod:',
    includingVAT: 'pris inklusive moms',
    payWithCard: 'Betala Med Kort',
    bankPayment: 'Bank Betalning',
    agreement: `Genom att fortsätta med detta köp och välja betalmetod godkänner du VEO's`,
    termsOfUse: 'Terms of Use (EULA)',
    privacyPolicy: 'Privacy Policy',
    and: 'och',
    cof: 'COF',
    back: 'Tillbaka',
    genericSponsorship: 'Generellt sponsorskap',
    toPay: 'Belopp att betala',
    isCompany: 'Markera denna checkbox om du gör denna betalning som företag',
    enterOrgNr: 'Fyll i ditt Företags VAT/momsnummer',
    sponsorGeneric: 'Generellt sponsorskap',
    sponsorGenericMonthly: 'VEO sponsorskap varje månad',
    perMonth: '/månad',
    email: 'Epost (För åtkomst till prenumeration)',
    continueToPayment: 'Fortsätt till betalning',
    cancel: 'Avbryt',
    donationVAT: 'Inklusive donationsmoms på',
    requiredFieldsText: 'Vänligen fyll i nedan information, som behövs av',
    requiredFieldsInfo: 'Information kommer raderas ifrån våra system så snart som transaktionen är genomförd.',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    phone: 'Mobilnummer',
    waiting: 'Väntar på respons ifrån',
    openWaiting: 'Vänligen öppna',
    openWaitingConfirm: 'för att bekräfta betalning',
    sponsorCreator: 'Sponsor Kreatörsveckan',
  },
});
