import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  Image,
  FlatList,
  TouchableOpacity,
  Linking,
} from 'react-native';
import { useReactiveVar } from '@apollo/client';
import LocalizedStrings from 'react-localization';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import CopyLink from '../../components/CopyLink';
import WideButton from '../../components/misc/WideButton';
import ExpectedReturn from '../../components/ExpectedReturn';
import SponsorInput from '../../components/SponsorInput';
import { myAccountVar, sponsorVar, shareLinkVar } from '../../../src/reactiveVariables';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import TermsCheckbox from '../../components/misc/TermsCheckbox';
import RecurrentCheckbox from '../../components/misc/RecurrentCheckbox';
import { GET_CURRENCY } from '../../gql/gqls';
import { commonStyles, useMobileSize } from '../../theme';

import { fontStyle, fontStyleBold } from '../../styles/fonts';
import {
  black,
  white,
  veoOrange,
  successGreen,
  lightGreen,
  grey,
  darkGrey,
  systemBlueDark,
} from '../../styles/colors';

import { fonts, colors } from '../../theme';
import arrowIcon from '../../assets/icons/arrow.svg';

import logoWhite from '../../assets/icons/logo-veo-white.png';
import config from '../../config';

export default function StartSponsor() {
  const history = useHistory();
  const { isSmallScreen } = useWindowDimensions();
  const sponsorData = useReactiveVar(sponsorVar);
  const {
    currency,
    sponsorshipVisible,
    recurrent,
    amount,
    minSponsor,
    maxSponsor,
  } = sponsorData;
  const user = useReactiveVar(myAccountVar);
  const isSignedIn = user && !user?.hasNotSignedUp;
  const isMobile = useMobileSize();
  const { shortId, team } = useParams();
  if (!shareLinkVar) shareLinkVar(shortId);

  const isCreatorWeek =
    process.env.REACT_APP_ENV === 'stage'
      ? shortId === 'dF4B7FUZNaI'
      : process.env.REACT_APP_ENV === 'prod'
      ? shortId === 'yb5zlXNnhNH'
      : shortId === 'key123';

  useQuery(GET_CURRENCY, {
    onCompleted: (data) => {
      sponsorVar({
        ...sponsorData,
        currency: data.getOnlyCurrency.currency,
        VAT: data.getOnlyCurrency.VAT,
        minSponsor: data.getOnlyCurrency.minSponsor.toFixed(0),
        maxSponsor: data.getOnlyCurrency.maxSponsor.toFixed(0),
        shortId: shortId,
        isSeller: team === 'team',
      });
    },
    onError: () => {
      sponsorVar({
        ...sponsorData,
        currency: 'SEK',
        VAT: 25,
        minSponsor: 100,
        maxSponsor: 10000,
      });
    },
  });

  const DATA1 = [
    { id: '1', title: strings.uspOne },
    { id: '4', title: strings.uspFour },
    { id: '2', title: strings.uspTwo },
    { id: '3', title: strings.uspThree, link: true },
  ];

  const DATA2 = [
    { id: '1', title: strings.uspCOne },
    { id: '2', title: strings.uspCTwo },
    { id: '3', title: strings.uspCThree },
  ];

  const Item = ({ title, link }) => (
    <View>
      <View style={styles.item}>
        <Image
          source={arrowIcon}
          style={{
            height: 20,
            width: 20,
          }}
        />
        <Text style={styles.title}>{title}</Text>
      </View>
      {link && (
        <TouchableOpacity
          onPress={() => handlePress('https://www.veofestivalen.se/')}
        >
          <Text style={styles.linkText}>{strings.urlFestival}</Text>
        </TouchableOpacity>
      )}
    </View>
  );

  const renderItem = ({ item }) => <Item title={item.title} link={item.link} />;

  const handlePress = async (url) => {
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    } else {
      console.log(`${url}`);
    }
  };

  return (
    <View
      style={{
        backgroundColor: colors.white,
        paddingHorizontal: isMobile ? 20 : 40,
        paddingVertical: isMobile ? 20 : 40,
        borderRadius: 8,
        marginBottom: 100,
        boxShadow: '3px 2px 20px -3px rgba(0, 0, 0, 0.09)',
      }}
    >
      {isCreatorWeek ? (
        <View>
          <Text style={styles.sponsorHeader}>{strings.creatorHeader}</Text>
          <View style={styles.veoContainer}>
            <Text
              style={[
                styles.infoTextSmall,
                { marginTop: 20, marginBottom: 10 },
              ]}
            >
              {strings.creatorInfoMain}
            </Text>

            <Text style={styles.smallHeader}>{strings.uspTitleOne}</Text>
            <FlatList
              data={DATA1}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
            />

            <Text style={styles.smallHeader}>{strings.uspTitleTwo}</Text>
            <FlatList
              data={DATA2}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
            />
          </View>
          <View style={styles.navPayRow}>
            <Text style={styles.smallHeaderNoMargin}>
              {strings.creatorPayTitle}
            </Text>
            <Text style={styles.price}>{strings.creatorPayText}</Text>
            <WideButton
              buttonStyle={[styles.nextButton, isSmallScreen && { width: 150 }]}
              text={strings.next}
              onPress={() => {
                sponsorVar({
                  ...sponsorData,
                  amount: 3000,
                  currency: 'SEK',
                  shortId: shortId,
                  sponsorshipVisible: true,
                });
                if (isSignedIn) {
                  history.push('/sponsor/payment');
                } else {
                  history.push('/sponsor/signup');
                }
              }}
            />
          </View>
        </View>
      ) : (
        <View>
          <Text style={styles.sponsorHeader}>{strings.sponsorHeader}</Text>
          <View style={styles.veoContainer}>
            <Text style={[styles.infoTextBold, { marginTop: 20 }]}>
              {strings.supportInfoOne}
            </Text>
            <View style={styles.inlineText}>
              <Text style={[styles.infoText]}>
                {strings.supportInfoTwo}
                <Text style={[styles.infoTextTwo]}>{strings.twohun}</Text>
                {strings.supportInfoTwoo}
              </Text>
            </View>

            <Text style={[styles.infoTextSmall, { marginTop: 20 }]}>
              {strings.supportInfoThree}
            </Text>
            <View style={styles.sponsorInfoRow}>
              <Text style={styles.sponsorAmount}>{strings.sponsorAmount}</Text>
            </View>
            <SponsorInput
              keyboardType={'numeric'}
              input={amount}
              currency={currency}
              recurrent={recurrent}
              placeholder={strings.enterSponsorAmount}
              onChangeText={(input) => {
                const inputAmount = parseFloat(input);
                const numericRegex = /^([0-9]{1,100})+$/;
                if (numericRegex.test(inputAmount)) {
                  sponsorVar({
                    ...sponsorData,
                    amount: inputAmount,
                  });
                } else {
                  sponsorVar({
                    ...sponsorData,
                    amount: '',
                  });
                }
              }}
            />
            <Text style={styles.maxAmount}>
              {strings.maxAmount}{' '}
              <Text style={styles.maxAmountBold}>
                {maxSponsor} {currency}
              </Text>
            </Text>
            <ExpectedReturn
              currency={currency}
              amount={amount}
              isMobile={isMobile}
              recurrent={recurrent}
            />
            {/*<RecurrentCheckbox
          recurrent={recurrent}
          onChange={(value) => {
            sponsorVar({
              ...sponsorData,
              recurrent: value
            });
          }}
          fontSize={isSmallScreen ? 10 : 14}
          marginTop={10}
          marginBottom={20}
        />
        */}
            <TermsCheckbox
              termsAccepted={sponsorshipVisible}
              onChange={(value) => {
                sponsorVar({
                  ...sponsorData,
                  sponsorshipVisible: value,
                });
              }}
              customText={strings.sponsorshipVisible}
              fontSize={isSmallScreen ? 14 : 14}
              marginTop={10}
              marginBottom={20}
            />
          </View>
          <View style={styles.navRow}>
            <WideButton
              buttonStyle={[styles.nextButton, isSmallScreen && { width: 150 }]}
              disabled={!amount}
              text={strings.next}
              onPress={() => {
                if (amount <= minSponsor) {
                  alert(strings.amountToSmall);
                } else if (amount > maxSponsor) {
                  alert(strings.sponsorToLarge);
                } else if (isSignedIn) {
                  history.push('/sponsor/payment');
                } else {
                  history.push('/sponsor/signup');
                }
              }}
            />
            <View />
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  supportHeader: {
    marginTop: 30,
    fontSize: 20,
    lineHeight: 25,
    color: black,
    fontFamily: fonts.regular,
  },
  sponsorHeader: {
    marginBottom: 10,
    fontSize: 24,
    lineHeight: 32,
    color: black,
    fontFamily: fonts.medium,
  },
  veoContainer: {
    fontSize: 24,
  },
  createAccountRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 33,
  },
  createAccountheader: {
    color: black,
    fontSize: 20,
    lineHeight: 25,
    fontFamily: fonts.regular,
  },
  accountCreatedContainer: {
    height: 38,
    width: 273,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: lightGreen,
  },
  accountCreated: {
    fontSize: 14,
    lineHeight: 17,
    color: successGreen,
    fontFamily: fonts.regular,
  },
  veoLogoContainer: {
    backgroundColor: veoOrange,
    width: 42,
    height: 42,
    borderRadius: '50%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nextButton: {
    width: 296.5,
    height: 40,
    backgroundColor: colors.deepBlue,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  veoLogo: {
    width: 22,
    height: 8,
  },
  infoText: {
    color: black,
    fontSize: 20,
    lineHeight: 30,
    fontFamily: fonts.regular,
  },
  infoTextTwo: {
    color: '#0B46AA',
    fontSize: 20,
    lineHeight: 30,
    fontFamily: fonts.medium,
    backgroundColor: '#F0EEF8',
    paddingHorizontal: 10,
    paddingVertical: 4,
    borderRadius: 4,
  },
  infoTextSmall: {
    color: black,
    fontSize: 16,
    lineHeight: 20,
    fontFamily: fonts.regular,
  },
  infoTextBold: {
    color: black,
    fontSize: 20,
    lineHeight: 30,
    fontFamily: fonts.regular,
  },
  veoInfoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  veoInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  valueEachOther: {
    marginLeft: 20,
    fontSize: 14,
    lineHeight: 17.5,
    color: black,
    fontFamily: fonts.regular,
  },
  precentageBackBackground: {
    height: 17,
    width: 73,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: successGreen,
    borderRadius: 2,
  },
  precentageBack: {
    color: white,
    fontSize: 12,
    lineHeight: 12.5,
    fontFamily: fonts.regular,
  },
  sponsorInfoRow: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  sponsorAmount: {
    color: black,
    fontSize: 20,
    lineHeight: 25,
    fontFamily: fonts.medium,
  },
  maxAmount: {
    color: colors.greyLeia,
    fontSize: 14,
    lineHeight: 15,
    fontFamily: fonts.regular,
    marginTop: 10,
  },
  maxAmountBold: {
    color: colors.black,
    fontSize: 14,
    lineHeight: 15,
    fontFamily: fonts.medium,
    marginTop: 10,
  },
  inputRow: {
    marginTop: 11,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputCurrency: {
    color: black,
    fontSize: 16,
    marginTop: 5,
    fontFamily: fonts.regular,
  },
  input: {
    textAlign: 'right',
    color: black,
    fontSize: 20,
    fontFamily: fonts.regular,
  },

  returnRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  navPayRow: {
    gap: 16,
    padding: 16,
    borderRadius: 8,
    borderWidth: 1,
    backgroundColor: '#FAFAFA',
    borderColor: '#D9D9D9',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginTop: 32,
  },
  navRow: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  backContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  chevronIcon: {
    width: 6,
    height: 12,
  },
  back: {
    marginLeft: 17,
    color: darkGrey,
    fontSize: 14,
    fontFamily: fonts.regular,
  },
  nextButtonText: {
    color: white,
    fontSize: 16,
    fontFamily: fonts.regular,
  },
  inlineText: {
    fontSize: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 20,
  },
  smallHeader: {
    fontSize: 18,
    lineHeight: '140%',
    fontFamily: fonts.familjen,
    fontWeight: 600,
    marginTop: 16,
    marginBottom: 8,
  },
  item: {
    flexDirection: 'row',
    marginVertical: 8,
  },
  title: {
    fontSize: 16,
    fontFamily: fonts.familjen,
    marginLeft: 4,
  },
  smallHeaderNoMargin: {
    fontSize: 18,
    lineHeight: '140%',
    fontFamily: fonts.familjen,
    fontWeight: 600,
  },
  price: {
    fontSize: 14,
    lineHeight: '140%',
    fontFamily: fonts.mono,
    fontWeight: 200,
  },
  linkText: {
    fontSize: 16,
    lineHeight: '140%',
    fontFamily: fonts.familjen,
    fontWeight: 500,
    color: colors.deepBlue,
    marginLeft: 25,
    marginBottom: 10,
  },
});

const strings = new LocalizedStrings({
  en: {
    supportInfoOne: `Help us in our work to create better conditions for current and future generations of content creators.`,
    supportInfoTwo: `Get up to `,
    twohun: `200%`,
    supportInfoTwoo: ` in repayment of sponsored amount.`,
    supportInfoThree: `Your contribution helps us grow so we can help more creators and content owners.`,
    valueEachOther: 'Value Each Other',
    supportWholePlatform: 'Support us, the whole VEO Platform.',
    sponsorHeader: 'Support and sponsor VEO',
    sponsorAmount: 'Sponsor Amount - ',
    enterSponsorAmount: 'Enter Sponsor Amount ',
    maxAmount: 'Maximum amount:',
    charity: '1% of sponsored amount always goes to charity.',
    lineBrokenCharity: '1% of sponsored amount\nalways goes to charity',
    expectedReturn: 'Expected Return Per Payment:',
    lineBrokenExpectedReturn: 'Expected\nReturn',
    sponsorshipVisible: 'I want my sponsorship to be visible to other users',
    back: 'ROI',
    next: 'Next',
    stepOne: 'Step 1 of 3',
    amountToSmall: 'Sponsor amount is to small. Minimum is 100 SEK or 10 EUR.',
    sponsorToLarge:
      'Sponsor amount is to large. Please contact us directly at finance@veoworld.org if you want to sponsor a larger amount.',
    creatorHeader:
      'Ta chansen till synlighet för ditt företag! Stöd Kreatörsveckan i Dalarna och sponsra kreativa talanger.',
    creatorInfoMain:
      'Under en veckas tid kommer utvalda kreativa själar att utbyta idéer och skapa fantastiska verk. Vi bjuder in er att få synas som en del av denna spännande resa genom att bli sponsor. Vi kontaktar dig via email för företagslogga samt föreslagen kreatör.',
    uspTitleOne: 'Du som sponsrar får:',
    uspOne:
      'En unik möjlighet att föreslå en kreatör i din närhet till Kreatörsveckan (valfritt)',
    uspTwo:
      'Din företagslogga på webbplats och i VEO-appen, synlig för våra användare',
    uspThree:
      'Din företagslogga synlig i samband med VEO-festivalen och våra festivalbesökare',
    uspFour: 'Tillgång till VEO-appen. VEO är en digital plattform för musik, blogg, konst, film, idrott med mera.',
    uspFive:
      'Exklusiva inbjudningar till evenemang där du får tillgång till nätverkande för ditt företag',
    uspTitleTwo: 'Kreatören får:',
    uspCOne: 'En betald hotelvecka på Tällbergsgården',
    uspCTwo: 'Ett års medlemskap i VEO-stiftelsen',
    uspCThree: 'Möjlighet att delta på VEO-festivalen i Tällberg',
    creatorPayTitle:
      'Köp sponsorskap kreatörsveckan - I samarbete med Marknadscheferna ',
    creatorPayText: 'Totalt: 3000 SEK',
    urlFestival: 'www.veofestivalen.se',
  },
  sv: {
    supportInfoOne: `Hjälp oss i vårt arbete att skapa bättre förutsättningar för nuvarande och framtida generationer av innehållsskapare.`,
    supportInfoTwo: `Få upp till `,
    twohun: `200%`,
    supportInfoTwoo: ` i återbetalning på sponsrat belopp.`,
    supportInfoThree: `Ditt bidrag hjälper oss att växa så att vi kan hjälpa fler kreatörer och innehållsägare.`,
    valueEachOther: 'Value Each Other',
    supportWholePlatform: 'Stöd oss, hela VEO platformen.',
    sponsorHeader:
      'Tycker du att digitalt innehåll har ett värde? Hjälp oss visa det!',
    sponsorAmount: 'Hur mycket vill du sponsra med?',
    enterSponsorAmount: 'Ange belopp',
    maxAmount: 'Maximalt belopp: ',
    charity: '1% av sponsrat belopp går till välgörenhet',
    lineBrokenCharity: '1% av sponsrat belopp\ngår alltid till välgörenhet',
    expectedReturn: 'Förväntad Återbetalning Per Betalning:',
    lineBrokenExpectedReturn: 'Förväntad\nÅterbetalning',
    sponsorshipVisible:
      'Jag vill att min sponsring blir synlig för andra användare',
    back: 'ROI',
    next: 'Nästa',
    stepOne: 'Steg 1 av 3',
    amountToSmall:
      'Sponsrat belopp är för litet. Minimum är 100 SEK eller 10 EUR.',
    sponsorToLarge:
      'Sponsrat belopp är för stort. Vänligen kontakta oss direkt via finance@veoworld.org om du vill sponsra ett större belopp.',
  },
});
