export const black = '#000000';
export const white = '#FFFFFF';
export const green = '#40B975';
export const lightGreen = 'rgba(58, 195, 118, 0.1)';
export const orange = '#EE4115';
export const blue = '#4A90E2';
export const grey = '#DADADA';
export const darkGrey = '#818182';
export const lightGrey = '#EFEFF0';
export const superLightGrey = '#FAFAFA';

export const veoOrange = '#FF5226';

export const successButton = '#3AC376';
export const visaBackground = '#15195A';

export const successGreen = '#3AC376';

export const systemBlueLight = '#EAF6FB';
export const systemBlueDark = '#0C7CFF';
