import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
} from 'react-native';
import { colors, fonts, fontsize } from '../../theme';

const Info = ({ label, value, isMobile, onPress }) => {
  const ValueButton = onPress ? TouchableOpacity : View

  return <View style={{
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: isMobile ? 'flex-start' : 'center',
    justifyContent: isMobile ? 'flex-start' : 'space-between',
    marginTop: isMobile ? 20 : 5
  }}>
  <Text style={{
    fontFamily: fonts.regular,
    color: colors.greyLeia,
    fontSize: fontsize.medium,
  }}>
    {label}
  </Text>
  <ValueButton>
    <Text style={{
      fontFamily: fonts.regular,
      color: colors.black,
      fontSize: fontsize.medium
    }}>
      {value ? value : 0}
    </Text>
  </ValueButton>  
</View>;
}

export default Info
