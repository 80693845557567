import React from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import LocalizedStrings from 'react-localization';
import { fonts, colors } from '../../theme';

import heart from '../../assets/icons/heart.png';

const ExpectedReturn = ({ currency, amount, isMobile, recurrent }) => (
  <View>
    <View style={styles.returnRow}>
    <Text style={[styles.expectedReturn, isMobile && { fontSize: 12 }]}>
        {recurrent ? strings.expectedReturnRecurring : strings.expectedReturn}
      </Text>
    <Text style={styles.expectedReturnAmount}>
      {currency} {amount * 2}
    </Text>
      <View style={styles.charityRow}>
        
        <Image
          style={isMobile ? styles.smallHeartLogo : styles.heartLogo}
          source={heart}
        />
        <Text style={[styles.charity, isMobile && { fontSize: 12 }]}>
          {strings.charity}
        </Text>
      </View>
      
    </View>
    
  </View>
);

export default ExpectedReturn;

const styles = StyleSheet.create({
  returnRow: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  charityRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 20,
    marginBottom: 20,
  },
  heartLogo: {
    width: 14,
    height: 14,
  },
  smallHeartLogo: {
    width: 10,
    height: 10,
  },
  charity: {
    marginLeft: 5,
    fontFamily: fonts.regular,
    color: colors.black,
    fontSize: 14,
    width: '100%'
  },
  expectedReturn: {
    fontFamily: fonts.medium,
    color: colors.black,
    fontSize: 12,
  },
  expectedReturnAmount: {
    marginTop: 5,
    color: colors.deepBlue,
    fontFamily: fonts.medium,
    fontSize: 20,
  },
});

const strings = new LocalizedStrings({
  en: {
    charity: '1% of sponsored amount always goes to charity.',
    lineBrokenCharity: '1% of sponsored amount\nalways goes to charity.',
    expectedReturn: 'Expected Return:',
    expectedReturnRecurring: 'Expected Return Per Month:',
    lineBrokenExpectedReturn: 'Expected\nReturn',
  },
  sv: {
    charity: '1% av sponsrat belopp går alltid till välgörenhet.',
    lineBrokenCharity: '1% av sponsrat belopp\ngår alltid till välgörenhet.',
    expectedReturn: 'Förväntad återbetalning:',
    expectedReturnRecurring: 'Förväntad återbetalning per månad:',
    lineBrokenExpectedReturn: 'Förväntad\nÅterbetalning',
  },
});
