import React, { useState } from 'react';
import {
  StyleSheet,
  View,
  Text,
  Image,
  FlatList,
  TouchableOpacity,
  Linking,
} from 'react-native';
import LocalizedStrings from 'react-localization';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import RadioButton from '../../components/RadioButton';
import CopyLink from '../../components/CopyLink';
import WideButton from '../../components/misc/WideButton';

import ExpectedReturn from '../../components/ExpectedReturn';
import SponsorInput from '../../components/SponsorInput';
import { myAccountVar, festivalAmountVar } from '../../reactiveVariables';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import TermsCheckbox from '../../components/misc/TermsCheckbox';
import RecurrentCheckbox from '../../components/misc/RecurrentCheckbox';
import { GET_CURRENCY } from '../../gql/gqls';
import { commonStyles, useMobileSize } from '../../theme';

import {
  black,
  white,
  veoOrange,
  successGreen,
  lightGreen,
  grey,
  darkGrey,
  systemBlueDark,
} from '../../styles/colors';

import { fonts, colors } from '../../theme';

import logoWhite from '../../assets/icons/logo-veo-white.png';
import config from '../../config';
import arrowIcon from '../../assets/icons/arrow.svg';

export default function StartFestival() {
  const history = useHistory();
  const { isSmallScreen } = useWindowDimensions();
  const user = useReactiveVar(myAccountVar);
  const festivalAmount = useReactiveVar(festivalAmountVar);
  const isSignedIn = user && !user?.hasNotSignedUp;
  const isMobile = useMobileSize();
  const { shortId, team } = useParams();

  const DATA = [
    { id: '1', title: strings.uspOne },
    { id: '2', title: strings.uspTwo },
    { id: '3', title: strings.uspThree },
    { id: '4', title: strings.uspFour },
  ];

  /*
  useQuery(GET_CURRENCY, {
    onCompleted: (data) => {
      sponsorVar({
        ...sponsorData,
        currency: data.getOnlyCurrency.currency,
        VAT: data.getOnlyCurrency.VAT,
        minSponsor: data.getOnlyCurrency.minSponsor.toFixed(0),
        maxSponsor: data.getOnlyCurrency.maxSponsor.toFixed(0),
        shortId: shortId,
        isSeller: team === 'team'
      });
    },
    onError: () => {
      sponsorVar({
        ...sponsorData,
        currency: 'SEK',
        VAT: 25,
        minSponsor: 100,
        maxSponsor: 10000
      });
    }
  });
  */

  const Item = ({ title }) => (
    <View style={styles.item}>
      <Image
        source={arrowIcon}
        style={{
          height: 20,
          width: 20,
        }}
      />
      <Text style={styles.title}>{title}</Text>
    </View>
  );

  const renderItem = ({ item }) => <Item title={item.title} />;

  const handlePress = async (url) => {
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    } else {
      console.log(`${url}`);
    }
  };

  return (
    <View
      style={{
        backgroundColor: colors.white,
        paddingHorizontal: isMobile ? 20 : 40,
        paddingVertical: isMobile ? 20 : 40,
        borderRadius: 8,
        marginBottom: 100,
        boxShadow: '3px 2px 20px -3px rgba(0, 0, 0, 0.09)',
      }}
    >
      <Text style={styles.smallFoundationHeader}>{strings.veoFoundation}</Text>
      <Text style={styles.festivalHeader}>{strings.festivalHeader}</Text>

      <Text style={styles.paragraphText}>{strings.intro}</Text>
      <Text style={styles.smallHeader}>{strings.whyMember}</Text>

      <FlatList
        data={DATA}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
      />

      <Text style={styles.smallHeader}>{strings.yourTicket}</Text>
      <Text style={styles.paragraphText}>{strings.ticketInfo}</Text>

      <View style={styles.linkRow}>
        <TouchableOpacity
          onPress={() => handlePress('https://www.veofestivalen.se/')}
        >
          <Text style={styles.linkText}>{strings.urlFestival}</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.navRow}>
        <RadioButton
          buttonStyle={{ marginTop: 10 }}
          txtStyle={styles.smallHeaderNoMargin}
          selected={festivalAmount < 1500}
          label={strings.membershipPrivate}
          onPress={() => {
            festivalAmountVar(500);
          }}
        />
        <RadioButton
          buttonStyle={{
            marginTop: 10,
            marginBottom: 20,
          }}
          txtStyle={styles.smallHeaderNoMargin}
          selected={festivalAmount > 500}
          label={strings.membershipCompany}
          onPress={() => {
            festivalAmountVar(1500);
          }}
        />
        <WideButton
          buttonStyle={[styles.nextButton, isSmallScreen && { width: 150 }]}
          text={strings.next}
          onPress={() => {
            if (isSignedIn) {
              history.push('/festival/payment');
            } else {
              history.push('/festival/signup');
            }
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  supportHeader: {
    marginTop: 30,
    fontSize: 20,
    lineHeight: 25,
    color: black,
    fontFamily: fonts.regular,
  },
  smallFoundationHeader: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: fonts.familjen,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  festivalHeader: {
    marginBottom: 10,
    fontSize: 36,
    lineHeight: '125%',
    color: black,
    fontFamily: fonts.familjen,
    fontWeight: 800,
    textAlign: 'center',
    marginBottom: 24,
    marginTop: 8,
  },
  paragraphText: {
    fontSize: 16,
    lineHeight: '140%',
    fontFamily: fonts.familjen,
  },
  preambleText: {
    fontSize: 20,
    lineHeight: '140%',
    fontFamily: fonts.familjen,
  },
  linkRow: {
    flexDirection: 'column',
    marginVertical: 8,
  },
  linkText: {
    fontSize: 16,
    lineHeight: '140%',
    fontFamily: fonts.familjen,
    fontWeight: 500,
    color: colors.deepBlue,
  },
  smallHeader: {
    fontSize: 18,
    lineHeight: '140%',
    fontFamily: fonts.familjen,
    fontWeight: 600,
    marginTop: 16,
    marginBottom: 8,
  },
  smallHeaderNoMargin: {
    fontSize: 18,
    lineHeight: '140%',
    fontFamily: fonts.familjen,
    fontWeight: 600,
  },
  price: {
    fontSize: 14,
    lineHeight: '140%',
    fontFamily: fonts.mono,
    fontWeight: 200,
  },
  veoContainer: {
    fontSize: 24,
  },
  createAccountRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 33,
  },
  createAccountheader: {
    color: black,
    fontSize: 20,
    lineHeight: 25,
    fontFamily: fonts.familjen,
  },
  accountCreatedContainer: {
    height: 38,
    width: 273,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: lightGreen,
  },
  accountCreated: {
    fontSize: 14,
    lineHeight: 17,
    color: successGreen,
    fontFamily: fonts.regular,
  },
  veoLogoContainer: {
    backgroundColor: veoOrange,
    width: 42,
    height: 42,
    borderRadius: '50%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nextButton: {
    width: 296.5,
    height: 40,
    backgroundColor: colors.deepBlue,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  veoLogo: {
    width: 22,
    height: 8,
  },
  infoText: {
    color: black,
    fontSize: 20,
    lineHeight: 30,
    fontFamily: fonts.familjen,
  },
  infoTextTwo: {
    color: '#0B46AA',
    fontSize: 20,
    lineHeight: 30,
    fontFamily: fonts.familjen,
    fontWeight: 500,
    backgroundColor: '#F0EEF8',
    paddingHorizontal: 10,
    paddingVertical: 4,
    borderRadius: 4,
  },
  infoTextSmall: {
    color: black,
    fontSize: 16,
    lineHeight: 20,
    fontFamily: fonts.familjen,
  },
  infoTextBold: {
    color: black,
    fontSize: 20,
    lineHeight: 30,
    fontFamily: fonts.familjen,
  },
  veoInfoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  veoInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  valueEachOther: {
    marginLeft: 20,
    fontSize: 14,
    lineHeight: 17.5,
    color: black,
    fontFamily: fonts.familjen,
  },
  precentageBackBackground: {
    height: 17,
    width: 73,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: successGreen,
    borderRadius: 2,
  },
  precentageBack: {
    color: white,
    fontSize: 12,
    lineHeight: 12.5,
    fontFamily: fonts.familjen,
  },
  sponsorInfoRow: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  sponsorAmount: {
    color: black,
    fontSize: 20,
    lineHeight: 25,
    fontFamily: fonts.medium,
  },
  maxAmount: {
    color: colors.greyLeia,
    fontSize: 14,
    lineHeight: 15,
    fontFamily: fonts.familjen,
    marginTop: 10,
  },
  maxAmountBold: {
    color: colors.black,
    fontSize: 14,
    lineHeight: 15,
    fontFamily: fonts.familjen,
    fontWeight: 500,
    marginTop: 10,
  },
  inputRow: {
    marginTop: 11,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputCurrency: {
    color: black,
    fontSize: 16,
    marginTop: 5,
    fontFamily: fonts.familjen,
  },
  input: {
    textAlign: 'right',
    color: black,
    fontSize: 20,
    fontFamily: fonts.familjen,
  },

  returnRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  navRow: {
    gap: 16,
    padding: 16,
    borderRadius: 8,
    borderWidth: 1,
    backgroundColor: '#FAFAFA',
    borderColor: '#D9D9D9',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginTop: 32,
  },
  backContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  chevronIcon: {
    width: 6,
    height: 12,
  },
  back: {
    marginLeft: 17,
    color: darkGrey,
    fontSize: 14,
    fontFamily: fonts.familjen,
  },
  nextButtonText: {
    color: white,
    fontSize: 16,
    fontFamily: fonts.familjen,
  },
  inlineText: {
    fontSize: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 20,
  },
  item: {
    flexDirection: 'row',
    marginVertical: 8,
  },
  title: {
    fontSize: 16,
    fontFamily: fonts.familjen,
    marginLeft: 4,
  },
});

const strings = new LocalizedStrings({
  en: {
    festivalHeader: 'VEO-festivalen i Dalarna',
    veoFoundation: 'VEO Foundation',
    intro:
      'Välkomna till årets VEO festival där kreatörer och företag möts. Kom och lyssna på vår branschmässa med föreläsare, lunch och artister. Få ökad synlighet till ditt företag och stöd kreatörer i partnerskap med en avkastning.',
    whyMember:
      'Köp biljett till festivalen och få',
    uspOne:
      'Entré till VEO-festivalen den 30-31 Augusti. Begränsat antal platser.',
    uspTwo: 'Två måltider ingår och två dagars entré.',
    uspThree: 'Du stöttar digitala rättigheter, lokala företag samt dina lokala favoritartister.',
    uspFour: 'Ett års medlemskap i VEO stiftelsen på köpet samt exklusiva förmåner och unik access till VEO-appen.',
    readMore: 'Läs mer om VEO Foundation: ',
    readMoreFestival: 'Läs mer om VEO Festivalen: ',
    yourTicket: 'Läs mer om VEO-festivalen',
    ticketInfo:
      'Upplev livekonserter, föreläsningar, interaktiva installationer och mycket mer.',
    yourSpot: 'Säkra din plats - Bli medlem!',
    spotInfo:
      'Gå med i VEO Foundation och bli en del av en rörelse som inspirerar och förändrar. Platserna är begränsade, så agera nu! Vi ser fram emot att välkomna dig!',
    membershipPrivate:
      'Privatperson - VEO-festivalbiljett entré - 500 SEK',
    membershipCompany:
      'Företag - VEO-festivalbiljett entré - 1500 SEK',
    membershipPrice: 'Totalt: 500 SEK',
    next: 'Nästa',
    urlFestival: 'www.veofestivalen.se',
    urlFoundation: 'www.veofoundation.org',
  },
  sv: {
    festivalHeader: 'VEO-festivalen i Dalarna',
    veoFoundation: 'VEO Foundation',
    intro:
    'Välkomna till årets VEO festival där kreatörer och företag möts. Kom och lyssna på vår branschmässa med föreläsare, lunch och artister. Få ökad synlighet till ditt företag och stöd kreatörer i partnerskap med en avkastning.',
    whyMember:
      'Köp biljett till festivalen och få',
    uspOne:
      'Entré till VEO-festivalen den 30-31 Augusti. Begränsat antal platser.',
    uspTwo: 'Två måltider ingår och två dagars entré.',
    uspThree: 'Du stöttar digitala rättigheter, lokala företag samt dina lokala favoritartister.',
    uspFour: 'Ett års medlemskap i VEO stiftelsen på köpet samt exklusiva förmåner och unik access till VEO-appen.',
    readMore: 'Läs mer om VEO Foundation: ',
    readMoreFestival: 'Läs mer om VEO Festivalen: ',
    yourTicket: 'Läs mer om VEO-festivalen',
    ticketInfo:
      'Upplev livekonsert, föreläsningar, interaktiva installationer och mycket mer.',
    yourSpot: 'Säkra din plats - Bli medlem!',
    spotInfo:
      'Gå med i VEO Foundation och bli en del av en rörelse som inspirerar och förändrar. Platserna är begränsade, så agera nu! Vi ser fram emot att välkomna dig!',
    membershipPrivate:
      'Privatperson - VEO-festivalbiljett entré - 500 SEK',
    membershipCompany:
      'Företag - VEO-festivalbiljett entré - 1500 SEK (avdragsgillt)',
    membershipPrice: 'Totalt: 500 SEK',
    next: 'Nästa',
    urlFestival: 'www.veofestivalen.se',
    urlFoundation: 'www.veofoundation.org',
  },
});
