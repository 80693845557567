import React from 'react';
import {
  View,
  TextInput,
  Image,
  TouchableOpacity
} from 'react-native';
import LocalizedStrings from 'react-localization';

import { colors, fonts } from '../../theme';
import maginfyingGlass from '../../assets/icons/searchGlass.png';
import closeIcon from '../../assets/icons/close-black.png';

const SearchBar = ({ input, setInput }) => {

  return <View style={{
    backgroundColor: colors.white,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: '3px 0px 20px 0px rgba(0, 0, 0, 0.05)',
  }}>
    <Image source={maginfyingGlass} style={{
      height: 20,
      width: 20,
      marginHorizontal: 10
    }} />
    <TextInput
      underlineColorAndroid='rgba(0,0,0,0)'
      placeholderTextColor={colors.greyDarth}
      placeholder={strings.searchPlaceholder}
      value={input}
      onChangeText={(input) => setInput(input)}
      style={{
        paddingRight: 20,
        fontSize: 14,
        fontFamily: fonts.regular,
        width: '100%',
        color: colors.black,
        borderRadius: 4,
        paddingTop: 15,
        paddingBottom: 15,
        minHeight: 64,
      }}
    />
    {input.length > 0 && <TouchableOpacity
      onPress={() => setInput('')}
      hitSlop={{ top: 10, bottom: 10, left: 10, right: 20 }}
      style={{
        marginHorizontal: 10,
      }}>
      <Image source={closeIcon} style={{ width: 15, height: 15 }} />
    </TouchableOpacity>}
  </View>
}

export default SearchBar;

const strings = new LocalizedStrings({
  en: {
    searchPlaceholder: 'Search'
  },
  sv: {
    searchPlaceholder: 'Sök'
  }
});
