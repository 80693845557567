import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  ImageBackground,
  Linking,
} from 'react-native';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { uses24HourClock } from 'react-native-localize';

import { fontStyleLight, fontStyle } from '../../styles/fonts';
import { colors } from '../../theme';
import {
  black,
  white,
  successGreen,
  systemBlueDark,
} from '../../styles/colors';

import advocadoBd from '../../assets/images/advocado_bg.jpeg';
import advocadoBdRed from '../../assets/images/advocado_bg_red.jpeg';
import checkIcon from '../../assets/icons/check-circle-white.png';
import errIcon from '../../assets/icons/close-white.png';
import { useReactiveVar } from '@apollo/client';
import { myAccountVar, sponsorVar } from '../../reactiveVariables';

const paymentProviderIcon = {
  visa: require('../../assets/icons/visacard.jpg'),
  mastercard: require('../../assets/icons/mastercard.png'),
  veo: require('../../assets/icons/veoLogo-black.png'),
};

const TRANS = gql`
  query ($id: ID!) {
    getTransaction(id: $id) {
      id
      name
      type
      status
      payId
      refId
      itemId
      fromId
      from
      fromImage
      toId
      to
      toImage
      country
      comment
      accNumber
      cardType
      amount
      currency
      VAT
      amountVAT
      currencyVAT
      countryVAT
      veoAmount
      convertedAmount
      convertedCurrency
      verificationOnly
      isCompleted
      date
    }
  }
`;

export const fonts = {
  regular: 'Rubik-Regular',
  medium: 'Rubik-Medium',
  light: 'Rubik-Light',
  italic: 'Rubik-LightItalic',
};

export const convertToLongDate = (int) => {
  if (!int) return '';
  let timeString,
    mon = '';
  const date = new Date(Number(int));
  const m = date.getMonth();
  const hour = date.getHours();
  const minutes = date.getMinutes();
  if (uses24HourClock()) {
    timeString = `${hour}:${minutes}`;
  } else {
    timeString = `${hour > 12 ? hour - 12 : hour}:${minutes} ${hour > 12 ? 'PM' : 'AM'
      }`;
  }
  mon = strings.monthNames[m];
  return `${mon.toUpperCase()} ${date.getDate()}, ${date.getFullYear()} ${timeString}`;
};

export default function ReceiptScreen() {
  const history = useHistory();
  const user = useReactiveVar(myAccountVar);
  const [item, setItem] = useState(false);
  const { id } = useParams();

  useQuery(TRANS, {
    variables: {
      id: id,
    },
    onCompleted: (data) => {
      setItem(data.getTransaction);
    },
    onError: () => {
      alert('Err');
    },
  });

  return (
    <View
      style={{
        backgroundColor: white,
        marginBottom: 100,
      }}
    >
      <View style={styles.mainContainer}>
        <View style={styles.itemInformationContainer}>
          {item ? (
            <View>
              <ImageBackground
                style={{
                  width: '100%',
                  height: 128,
                  borderRadius: 5,
                  overflow: 'hidden',
                  marginTop: 15,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                source={item?.status === 'success' ? advocadoBd : advocadoBdRed}
              >
                <Image
                  style={{
                    height: 32,
                    width: 32,
                  }}
                  source={
                    item?.status === 'success' || item?.status === 'pending'
                      ? checkIcon
                      : errIcon
                  }
                />
                <Text
                  style={{
                    marginTop: 20,
                    textAlign: 'center',
                    fontSize: 16,
                    fontFamily: fonts.regular,
                    color: colors.white,
                  }}
                >
                  {item?.status === 'success'
                    ? strings.success
                    : item?.status === 'nofunds'
                      ? strings.nofunds
                      : item?.status === 'pending'
                        ? strings.pending
                        : strings.failed}
                </Text>
              </ImageBackground>
              <Text style={styles.message}>
                {strings.message}{' '}
                <Image
                  style={styles.heartLogo}
                  source={{ uri: `/icons/misc/heart.jpg` }}
                />
              </Text>
              <TouchableOpacity
                onPress={() => window.open('https://veoworld.org/', '_self')}
                style={styles.discoverButton}
              >
                <Text style={styles.discoverText}>{strings.discover}</Text>
              </TouchableOpacity>
              <Text style={styles.textTitle}>{strings.date}</Text>
              <Text style={styles.informationText}>
                {convertToLongDate(item?.date)}
              </Text>
              {item?.from ? (
                <View>
                  <Text style={styles.textTitle}>{strings.nameOfSender}</Text>
                  <Text style={styles.informationText}>{item?.from}</Text>
                </View>
              ) : (
                <View />
              )}
              <Text style={styles.textTitle}>{strings.nameOfRecipient}</Text>
              <Text style={styles.informationText}>{item?.to}</Text>
              <Text style={styles.textTitle}>{strings.amount}</Text>
              <Text style={styles.informationText}>
                {item?.amount
                  ? item?.amount.toFixed(2).replace(/[.,]00$/, "") + ' ' + item?.currency
                  : (item?.veoAmount * user.veoRate).toFixed(2).replace(/[.,]00$/, "") +
                  ' ' +
                  user.currency}
              </Text>
              {item?.VAT ? (
                <View>
                  <Text style={styles.textTitle}>
                    {strings.VAT} {item.VAT}%
                  </Text>
                  <Text style={styles.informationText}>
                    {item?.amountVAT.toFixed(2).replace(/[.,]00$/, "") + ' ' + item?.currencyVAT}
                  </Text>
                </View>
              ) : (
                <View />
              )}
              {item?.description ? (
                <View>
                  <Text style={styles.textTitle}>{strings.description}</Text>
                  <Text style={styles.informationText}>
                    {item?.description}
                  </Text>
                </View>
              ) : item?.name || strings[item.type] ? (
                <View>
                  <Text style={styles.textTitle}>{strings.description}</Text>
                  <Text style={styles.informationText}>
                    {item.type === 'sponsorChannel'
                      ? item.name + strings[item.type]
                      : strings[item.type]
                        ? strings[item.type]
                        : strings[item.name]
                          ? strings[item.name]
                          : item.name}
                  </Text>
                </View>
              ) : (
                <View />
              )}
              <Text style={styles.textTitle}>{strings.transactionType}</Text>
              <Text style={styles.informationText}>
                {item?.type === 'subfee' || item?.type === 'membership'
                  ? strings.purchase
                  : item?.type === 'donate'
                    ? strings.donate
                    : item?.type === 'payout'
                      ? strings.payout
                      : item?.type === 'refund'
                        ? strings.refund
                        : strings.income}
              </Text>
              <Text style={styles.textTitle}>{strings.id}</Text>
              <Text style={styles.informationText}>{item?.id}</Text>
              {item?.type && item?.provider ? (
                <View>
                  <Text style={styles.textTitle}>{strings.paymentMethod}</Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 10,
                    }}
                  >
                    {item?.cardType === 'visa' ||
                      (item?.cardType === 'mastercard' && item?.accNumber) ? (
                      <View style={{ flexDirection: 'row' }}>
                        <Text
                          style={[
                            styles.informationText,
                            {
                              marginTop: 3,
                              marginBottom: 0,
                            },
                          ]}
                        >
                          {item?.cardType.toUpperCase()} {item?.accNumber}
                        </Text>
                      </View>
                    ) : (
                      <View style={{ flexDirection: 'row' }}>
                        <Text
                          style={[
                            styles.informationText,
                            {
                              marginTop: 3,
                              marginBottom: 0,
                            },
                          ]}
                        >
                          {item?.providerName.toUpperCase()} {item?.accNumber}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              ) : (
                <View />
              )}
              {item?.comment ? (
                <View>
                  <Text style={styles.textTitle}>{strings.comment}</Text>
                  <Text style={styles.informationText}>{item?.comment}</Text>
                </View>
              ) : (
                <View />
              )}
              <Text style={[styles.companyDetails, { marginTop: 25 }]}>
                {strings.address}
              </Text>
              <Text style={styles.companyDetails}>{strings.contact}</Text>
              <TouchableOpacity
                onPress={() => Linking.openURL('mailto:finance@veoworld.org')}
              >
                <Text
                  style={[
                    styles.companyDetails,
                    {
                      color: '#7daccb',
                      marginTop: 3,
                    },
                  ]}
                >
                  receipt@veoworld.org
                </Text>
              </TouchableOpacity>
              <Text style={styles.companyDetails}>
                {strings.termsCondition}
                <Text
                  onPress={() =>
                    window.open(
                      'https://veoworld.org/org/termsofuse.html',
                      '_blank'
                    )
                  }
                  style={[styles.companyDetails, { color: '#7daccb' }]}
                >
                  {strings.here}
                </Text>
                .
              </Text>
              <Text style={styles.siteText}>veoworld.org</Text>
            </View>
          ) : (
            <View />
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  heartLogo: {
    width: 8.71,
    height: 8.54,
  },
  thankYouHeader: {
    color: successGreen,
    marginTop: 40,
    ...fontStyleLight,
    fontSize: 48,
    alignSelf: 'center',
  },
  message: {
    color: black,
    marginTop: 17,
    ...fontStyle,
    fontSize: 16,
    alignSelf: 'center',
    paddingHorizontal: 40,
    textAlign: 'center',
  },
  discoverButton: {
    width: 296.5,
    height: 40,
    borderRadius: 4,
    backgroundColor: systemBlueDark,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 40,
    alignSelf: 'center',
  },
  discoverText: {
    ...fontStyle,
    fontSize: 16,
    color: white,
  },
  mainContainer: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#ffffff',
    paddingLeft: 15,
    paddingRight: 15,
  },
  itemInformationContainer: {
    flex: 1,
    width: '100%',
  },
  textTitle: {
    fontSize: 14,
    color: '#858585',
    fontFamily: 'Rubik-Light',
    marginTop: 15,
    marginBottom: 5,
  },
  informationText: {
    fontSize: 16,
    color: '#000000',
    fontFamily: 'Rubik-Light',
    marginBottom: 10,
  },
  companyDetails: {
    fontSize: 11,
    color: '#888888',
    fontFamily: 'Rubik-Light',
    marginTop: 10,
    textAlign: 'center',
  },
  mastercard: {
    width: 35,
    height: 30,
    marginRight: 20,
  },
  veo: {
    width: 40,
    height: 15,
    marginRight: 20,
  },
  visa: {
    width: 35,
    height: 25,
    marginRight: 20,
  },
  siteText: {
    fontSize: 11,
    color: '#e0e0e0',
    marginTop: 40,
    marginBottom: 40,
    textAlign: 'center',
    fontFamily: 'Rubik-Light',
    justifyContent: 'center',
  },
  loadingContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
    paddingBottom: 15,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
  },
});

const strings = new LocalizedStrings({
  en: {
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    message:
      'Thank you for your support. You will soon be able to track your sponsorship directly here on veopartner.com and in the veo app. When you get access to the veo app you can login with the same details you created here on veopartner.com. Again, thank you',
    discover: 'Go to Veoworld.org', //TODO Discover more projects
    status: 'Status',
    yourReceipt: 'Your receipt',
    success: 'Transaction is complete',
    nofunds: 'Transaction rejected due to no funds',
    pending: 'Pending reply from your provider',
    failed: 'Transaction was not completed',
    date: 'Date',
    recurringPayments: 'Receipt',
    nameOfSender: 'From',
    nameOfRecipient: 'Recipient',
    amount: 'Amount',
    itemQuantity: 'Item / Quantity',
    id: 'ID',
    paymentMethod: 'Payment method',
    subscription30Fee: 'Subscription 30 days',
    transaction: 'Transaction',
    description: 'Description',
    comment: 'Comment',
    address:
      'Merchant: VEO WORLD EUROPE LTD (address: Fiskebäcks Hamn 34, 426 58 Västra Frölunda, Sweden).',
    contact:
      'If you need help regarding this receipt, please contact our finance department via e-mail:',
    termsCondition:
      'You can find our Terms & Conditions, including return and cancellation policy',
    here: ' here',
    VAT: 'VAT',
    subfee: 'Subscription favorite channel',
    transactionType: 'Transaction type',
    purchase: 'Purchase',
    donate: 'Donation',
    payout: 'Withdrawal',
    refund: 'Refund',
    income: 'Income',
    sponsorChannel: ' - Repayment sponsorship',
    sponsorGeneric: 'Return VEO sponsorship',
    acquisition: 'Acquisition',
    recruiter: 'Recruiting',
    distribution: 'Favorite Channel',
    channelReward: 'Channel reward',
    sellerReward: 'Seller reward',
    leaderTeamReward: 'Team leader reward',
    leaderGroupReward: 'Group leader reward',
  },
  sv: {
    monthNames: [
      'Januari',
      'Februari',
      'Mars',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'Augusti',
      'September',
      'Oktober',
      'November',
      'December',
    ],
    message:
      'thank you for your support. You will be able to track your refund in the app. Download it from the appstore or google play and use the same login as you registered here. Again, thank you',
    discover: 'Gå till Veoworld.org', //TODO Upptäck fler projekt
    status: 'Status',
    yourReceipt: 'Ditt kvitto',
    success: 'Transaktion är genomförd',
    nofunds: 'Transaktion nekad på grund av otillräckligt belopp',
    pending: 'Väntar svar ifrån din inlösare',
    failed: 'Transaktionen gick inte igenom',
    date: 'Datum',
    recurringPayments: 'Kvitto',
    nameOfSender: 'Från',
    nameOfRecipient: 'Mottagare',
    amount: 'Belopp',
    itemQuantity: 'Vara / Antal',
    id: 'ID',
    paymentMethod: 'Betalningsmetod',
    subscription30Fee: 'Prenumeration 30 dagar',
    transaction: 'Transaktion',
    description: 'Beskrivning',
    comment: 'Kommentar',
    address:
      'Merchant: VEO WORLD EUROPE LTD (adress: Fiskebäcks Hamn 34, 426 58 Västra Frölunda, Sweden).',
    contact:
      'Om du behöver hjälp med anledning av detta kvittot, kontakta vår finansavdelning via e-post:',
    termsCondition: 'Du hittar våra Användarvillkor (EULA), retur och avbrotts policy',
    here: ' här',
    VAT: 'Moms',
    subfee: 'Prenumeration favoritkanal',
    transactionType: 'Transaktionstyp',
    purchase: 'Köp',
    donate: 'Donation',
    payout: 'Utbetalning',
    refund: 'Återbetalning',
    income: 'Inkomst',
    sponsorChannel: ' - Återbetalning Sponsorskap',
    sponsorGeneric: 'Återbetalning Generellt Sponsorskap',
    acquisition: 'Förvärv',
    recruiter: ' Rekrytering',
    distribution: 'Favoritkanal',
    channelReward: 'Kanalersättning',
    sellerReward: 'Säljersättning',
    leaderTeamReward: 'Teamledarersättning',
    leaderGroupReward: 'Gruppledarersättning',
  },
});
