import React from 'react';
import LocalizedStrings from 'react-localization';
import {
  View,
  Text,
  TouchableOpacity,
} from 'react-native';
import WideButton from '../misc/WideButton';
import VeoIcon from '../VeoIcon';
import Roi from '../Roi';

import { colors, fonts } from '../../theme';

const SupportVeo = ({ isMobile, history }) => 

<TouchableOpacity onPress={() => history.push(`/sponsor`)}>

<View style={{
  paddingHorizontal: 20,
  paddingVertical: 10,
  borderRadius: 16,
  boxShadow: '3px 2px 20px -3px rgba(0, 0, 0, 0.15)',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'fixed',
  bottom: 20,
  right: 20,
  width: 'auto',
  backgroundColor: '#F76E36',
  
}}>
  <Text style={{
          fontSize: 14,
          fontFamily: fonts.medium,
          color: colors.white
        }}>
          {strings.veo}
        </Text>
  {isMobile && <VeoIcon />}
  <View style={{
    width: '100%',
    flexShrink: 1
  }}>
    <View style={{
      flexDirection: 'row',
      width: '100%',
      alignItems:'flex-start',
      flexShrink: 1,
    }}>
      <View style={{
        marginLeft: 5,
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        {!isMobile && <VeoIcon />}
        {!isMobile && <Roi extraStyles={{ marginLeft: 10  }} />}
      </View>
      {isMobile && <Roi extraStyles={{ marginVertical: 10 }} />}
    </View>
  </View>
</View>
</TouchableOpacity>

export default SupportVeo

const strings = new LocalizedStrings({
  en: {
    veo: 'Support',
    supportVeo: 'Support VEO Platform',
    supportUs: 'Support',
  },
  sv: {
    veo: 'Stötta',
    supportVeo: 'Stöd VEO Plattformen',
    supportUs: 'Stötta',
  },
});