import React, { useRef, useEffect, useState } from 'react';
import LocalizedStrings from 'react-localization';
import {
  StyleSheet,
  FlatList,
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import { useReactiveVar } from '@apollo/client';

import { myAccountVar } from '../../reactiveVariables';

import { commonStyles, mediaQueries, gutters, mainTitle, screenStyles, useMobileSize } from '../../theme';

export default function DiscoverScreen() {
  const isMobile = useMobileSize();

  const user = useReactiveVar(myAccountVar);

  return (
    <View style={screenStyles.main}>
      <ScrollView
        scrollIndicatorInsets={{ right: 1 }}
        style={{
          maxWidth: mediaQueries.tablet,
          width: '100%',
          paddingHorizontal: isMobile ? gutters.pageGutter : 0,
        }}>
        <Text style={mainTitle(isMobile)}>
          {strings.title}
        </Text>
        <Paragraph
          heading={strings.heading1}
          text={strings.paragraphs1}
        />
        <Paragraph
          heading={strings.heading2}
          text={strings.paragraphs2}
        />
        <Paragraph
          heading={strings.heading3}
          text={strings.paragraphs3}
        />
        <Paragraph
          heading={strings.heading4}
          text={strings.paragraphs4}
        />
        <Paragraph
          heading={strings.heading5}
          text={strings.paragraphs5}
        />
        <Paragraph
          heading={strings.heading6}
          text={strings.paragraphs6}
        />
        <Paragraph
          heading={strings.heading7}
          text={strings.paragraphs7}
        />
        <Paragraph
          heading={strings.heading8}
          text={strings.paragraphs8}
        />
        <Paragraph
          heading={strings.heading9}
          text={strings.paragraphs9}
        />
      </ScrollView>
    </View>
  );
};

const Paragraph = ({ heading, text }) => <View style={{ marginBottom: 15 }} >
  <Text style={commonStyles.boldText}>
    {heading}
  </Text>
  <Text style={commonStyles.infoText}>
    {text}
  </Text>
</View>

const strings = new LocalizedStrings({
  en: {
    title: 'FAQ',
    heading1: 'What is VEO Partner?',
    heading2: 'What do I invest in, how is the money used?',
    heading3: 'Regular Content',
    heading4: 'Projects',
    heading5: 'How is my investing request processed?',
    heading6: 'Investment Risks?',
    heading7: 'How is my investment repaid?',
    heading8: 'Sequrity',
    heading9: 'How do I monitor development for my investment?',
    paragraphs1: `A platform where VEO's sales force continuously adds new VEO-contracted distributors (associations, clubs etc). Each distributor has committed itself to connect a certain number of members/followers, whose monthly fees are the basis for VEO's business. A platform where investment capital can be sought for distributors (associations, clubs etc).`,
    paragraphs2: `You invest in the business of the distributor/association. The investment is used to create the distributor's App and start up the business within VEO, which includes dedicated investments that VEO does.`,
    paragraphs3: `Distributor's own digital material, eg in the form of videos, pictures, etc. Users’ own material uploaded, such as own videos. External content, such as music and movies`,
    paragraphs4: `As part of a distributor's offer to its users, funding may be sought for various purposes, such as a new clubhouse. This is done as a supplement, which the user can choose as an addition to the regular VEO service.`,
    paragraphs5: `It is processed by our financial department and you receive a written proposal for an investment agreement within one working day. What is the return on my investment? Returns vary depending on the type of investment. All conditions are stated in the investment agreement.`,
    paragraphs6: `You choose a particular distributor as an investment item, but if for some reason it would not succeed, your repayment and return are covered by other distributors replacing the one you chose primarily. This means that VEO's entire business secures your investment.`,
    paragraphs7: `Repayment including return on your investment is made monthly to an e-wallet that you receive from VEO free of charge.`,
    paragraphs8: `Refunds, including return on investment, are made to the e-wallet, which works under a license issued by FCA (UK Financial Conduct Authority), ie with the highest security.`,
    paragraphs9: `You can monitor your investment on veopartner.com under "My investments". All monthly payments are shown here.`,
  },
});