import LocalizedStrings from 'react-localization';
import { uses24HourClock } from 'react-native-localize';

const strings = new LocalizedStrings({
  en: {
    jan: 'JAN',
    feb: 'FEB',
    mar: 'MAR',
    apr: 'APR',
    may: 'MAY',
    jun: 'JUN',
    jul: 'JUL',
    aug: 'AUG',
    sep: 'SEP',
    oct: 'OCT',
    nov: 'NOV',
    dec: 'DEC',

    monthShortName: [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC'
    ],

    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

    weekDay: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    weekDayShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

    art: 'Art',
    comedy: 'Comedy',
    gamesAndHobby: 'Games and hobby',
    health: 'Health',
    technology: 'Technology',
    newsAndPolitics: 'News and politics',
    scienceAndMedicine: 'Science and medicine',
    sportsAndLeisure: 'Sports and leisure',
    business: 'Business',
    education: 'Education',
    authorityAndOrganisation: 'Authority and organisation',
    childAndFamily: 'Child and family',
    music: 'Music',
    religionAndSpirituality: 'Religion and spirituality',
    societyAndCulture: 'Society and culture',
    tvAndMovies: 'Tv and movies',
    other: 'Other',
    pop: 'Pop',
    hipHop: 'Hiphop',
    electronicDance: 'Electronic/Dance',
    rnb: 'R&B',
    rock: 'Rock',
    indie: 'Indie',
    jazz: 'Jazz',
    metal: 'Metal',
    dance: 'Band',
    country: 'Country',
    acoustic: 'Acoustic',
    soul: 'Soul',
    classic: 'Classic',
    blues: 'Blues',
    latino: 'Latino',
    punk: 'Punk',
    reggae: 'Reggae',
    funk: 'Funk',
    kpop: 'K-pop',

    day: 'Day',
    days: 'Days',
    week: 'Week',
    weeks: 'Weeks',
    month: 'Month',
    months: 'Months',
    year: 'This year',
    yearSingular: 'Year',
    yearPlural: 'Years',
    ago: 'ago',

    male: 'Male',
    female: 'Female',

    passport: 'Passport',
    creditOrDebitCard: 'Credit Or Debit Card',
    idCard: 'ID Card',
    drivingLicense: 'Driving license',

    utilityBill: 'Utility Bill',
    bankStatement: 'Bank Statement',
    rentAgreement: 'Rent Agreement',
    employerLetter: 'Employer Letter',
    insuranceAgreement: 'Insurance Agreement',
    taxBill: 'Tax Bill',

    today: 'Today',
    yesterday: 'Yesterday'
  },

  sv: {
    jan: 'JAN',
    feb: 'FEB',
    mar: 'MAR',
    apr: 'APR',
    may: 'MAJ',
    jun: 'JUN',
    jul: 'JUL',
    aug: 'AUG',
    sep: 'SEP',
    oct: 'OKT',
    nov: 'NOV',
    dec: 'DEC',

    monthShortName: [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAJ',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OKT',
      'NOV',
      'DEC'
    ],

    monthNames: ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december'],

    weekDay: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
    weekDayShort: ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],

    day: 'Dag',
    days: 'Dagar',
    week: 'Vecka',
    weeks: 'Veckor',
    month: 'Månad',
    months: 'Månader',
    year: 'År',
    yearSingular: 'År',
    yearPlural: 'År',
    ago: 'sedan',

    male: 'Man',
    female: 'Kvinna',

    passport: 'Pass',
    creditOrDebitCard: 'Kreditkort eller Bankkort',
    idCard: 'Id-kort',
    drivingLicense: 'Körkort',

    utilityBill: 'Elräkning',
    bankStatement: 'Kontoutdrag',
    rentAgreement: 'Hyresavtal',
    employerLetter: 'Arbetsgivarbrev',
    insuranceAgreement: 'Försäkringsavtal',
    taxBill: 'Skattsedel',

    today: 'Idag',
    yesterday: 'Igår'
  }
});

export const inprodiconIMG = (item, size) => {
  const distId = '2';
  const prUrl = 'https://ip.inprodicon.ch';
  let headers = {
    'Cookie': global.account.prAuth,
    'X-ODS-Distributor-ID': distId
  };
  // 'Cache-Control': 'public, max-age=31536000',
  // Set image and extra
  let image;
  if (item.type === 'playlist' || item.type === 'videoplaylist') {
    image = prUrl + '/p/' + item.ipId + '/cover?size=' + size;
  }
  if (item.type === 'album') image = prUrl + '/albums/' + item.ipId + '/cover?size=' + size + '&distId=' + distId;
  if (item.type === 'artist') image = '';
  if (item.type === 'video') {
    image = prUrl + '/albums/' + item.ipId + '/cover?size=' + size + '&distId=' + distId;
  }
  if (item.type === 'track') {
  }
  if (item.type === 'playlist') {
    image = prUrl + '/p/' + item.ipId + '/cover?size=' + size + '&distId=' + distId;
  }
  if (item.type === 'videoplaylist') {
    image = prUrl + '/p/' + item.ipId + '/cover?size=' + size + '&distId=' + distId;
  }
  if (item.type === 'image') image = '';
  if (item.type === 'podcast') image = '';
  if (item.type === 'profile') image = '';
  if (item.listId) image = prUrl + '/albums/' + item.listId + '/cover?size=250&distId=' + distId;

  return {
    image,
    headers
  };
};

export const numericDate = (int) => {
  const date = new Date(Number(int));
  const month = date.getMonth() + 1;
  const paddedMonth = month < 10 ? '0' + month : month;
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  return `${date.getFullYear()}-${paddedMonth}-${day}`;
};

const timeOfDay = date => {
  const hour = date.getHours();
  const minutes = date.getMinutes() === 0 && !uses24HourClock() ? '' : date.getMinutes();
  const paddedMinutes = minutes !== '' && minutes < 10 ? '0' + minutes : minutes;

  if (uses24HourClock()) {
    const paddedHour = hour < 10 ? '0' + hour : hour;
    return `${paddedHour}:${paddedMinutes}`;
  } else {
    return `${hour > 12 ? hour - 12 : hour}${paddedMinutes > 0 ? ':' : ''}${paddedMinutes} ${hour > 12 ? 'PM' : 'AM'}`;
  }
};

const withinHour = (timestamp, nowTimestamp = new Date().getTime()) => {
  const HOUR = 1000 * 60 * 60;
  return nowTimestamp - timestamp < HOUR;
};

const isToday = (date, nowDate) => date.getDate() == nowDate.getDate() &&
  date.getMonth() == nowDate.getMonth() &&
  date.getFullYear() == nowDate.getFullYear();

const isYesterday = (date, nowTimestamp = new Date().getTime()) => {
  const yesterdayTimestamp = nowTimestamp - 1000 * 60 * 60 * 24;
  const yesterdayDate = new Date(yesterdayTimestamp);

  return date.getDate() == yesterdayDate.getDate() &&
    date.getMonth() == yesterdayDate.getMonth() &&
    date.getFullYear() == yesterdayDate.getFullYear();
};

const withinWeek = (timestamp, nowTimestamp = new Date().getTime(), days = 7) => {
  const withinWeek = nowTimestamp - 1000 * 60 * 60 * 24 * days;
  return withinWeek < timestamp;
};

const withinMonth = (timestamp, nowTimestamp = new Date().getTime()) => {
  const withinMonth = nowTimestamp - 1000 * 60 * 60 * 24 * 30;
  return withinMonth < timestamp;
};

const withinYear = (timestamp, nowTimestamp = new Date().getTime()) => {
  const withinMonth = nowTimestamp - 1000 * 60 * 60 * 24 * 365;
  return withinMonth < timestamp;
};

export const distanceInTimeInWords = ({ timestamp, nowTimestamp = new Date().getTime() }) => {
  if (timestamp > nowTimestamp) timestamp = nowTimestamp;
  const date = new Date(timestamp);
  const nowDate = new Date(nowTimestamp);

  if (isToday(date, nowDate)) {
    return strings.today;
  }

  if (isYesterday(date, nowTimestamp)) {
    return strings.yesterday;
  }

  if (withinWeek(timestamp, nowTimestamp)) {
    const days = Math.floor((nowTimestamp - timestamp) / (1000 * 60 * 60 * 24));
    return `${days} ${(days > 1 ? strings.days : strings.day).toLowerCase()} ${strings.ago}`;
  }

  if (withinMonth(timestamp, nowTimestamp)) {
    const weeks = Math.floor((nowTimestamp - timestamp) / (1000 * 60 * 60 * 24 * 7));
    return `${weeks} ${(weeks > 1 ? strings.weeks : strings.week).toLowerCase()} ${strings.ago}`;
  }

  if (withinYear(timestamp, nowTimestamp)) {
    const months = Math.floor((nowTimestamp - timestamp) / (1000 * 60 * 60 * 24 * 30));
    return `${months} ${(months > 1 ? strings.months : strings.month).toLowerCase()} ${strings.ago}`;
  }
  const years = Math.floor((nowTimestamp - timestamp) / (1000 * 60 * 60 * 24 * 365));
  return `${years} ${(years > 1 ? strings.yearPlural : strings.yearSingular).toLowerCase()} ${strings.ago}`;
};

export const distanceInTime = ({ timestamp, nowTimestamp = new Date().getTime(), short }) => {

  if (timestamp > nowTimestamp) timestamp = nowTimestamp;

  const date = new Date(timestamp);
  const nowDate = new Date(nowTimestamp);

  if (withinHour(timestamp, nowTimestamp)) {
    const minutes = new Date(nowTimestamp - timestamp).getUTCMinutes();
    return minutes > 0 ? minutes + ' min ' + strings.ago : 'Now';
  }
  ;

  if (isToday(date, nowDate)) {
    return short ? timeOfDay(date) : `${strings.today}\n${timeOfDay(date)}`;
  }

  if (isYesterday(date, nowTimestamp)) {
    return short ? strings.yesterday : `${strings.yesterday}\n${timeOfDay(date)}`;
  }

  if (withinWeek(timestamp, nowTimestamp, 6)) {
    return short ? strings.weekDayShort[date.getDay()] : `${strings.weekDay[date.getDay()]}\n${timeOfDay(date)}`;
  }

  const thisYear = date.getFullYear() === nowDate.getFullYear();

  let mon = strings.monthShortName[date.getMonth()];
  mon = mon.charAt(0) + mon.substring(1)
    .toLowerCase();

  if (thisYear) {
    return `${date.getDate()} ${mon}`;
  } else {
    return `${date.getDate()} ${mon}\n${date.getFullYear()}`;
  }
};

export const convertToDate = (int, format) => {
  const date = new Date(Number(int));
  const m = date.getMonth();
  const mon = strings.monthShortName[m];
  return format === 'month-day-year' ? `${mon.charAt(0) + mon.substring(1)
    .toLowerCase()} ${date.getDate()}, ${date.getFullYear()}` :
    date.getDate() + ' ' + mon + ' ' + date.getFullYear();
};

export const convertToLongDate = (int) => {
  if (!int) return '';
  let timeString,
    mon = '';
  const date = new Date(Number(int));
  const m = date.getMonth();
  const hour = date.getHours();
  const minutes = date.getMinutes();
  if (uses24HourClock()) {
    timeString = `${hour}:${minutes}`;
  } else {
    timeString = `${hour > 12 ? hour - 12 : hour}:${minutes} ${hour > 12 ? 'PM' : 'AM'}`;
  }
  mon = strings.monthNames[m];
  return `${mon.toUpperCase()} ${date.getDate()}, ${date.getFullYear()} ${timeString}`;
};

export const getIndex = (value, arr, prop) => {
  if (!arr || !value || !prop) return -1;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][prop] === value) {
      return i;
    }
  }
  return -1; //to handle the case where the value doesn't exist
};

export const formatToArray = (obj, prop) => {
  let arr = [];
  if (!obj || !prop) return [];
  for (let i = 0; i < obj.length; i++) {
    arr.push(obj[i][prop]);
  }
  return arr;
};

export const calculateProfit = (price, priceCurrency, currency, countries) => {
  if (price && priceCurrency && currency && countries) {
    // Find currencyRate for priceCurrency and currency
    const priceCurrencyRate = countries[getIndex(priceCurrency, countries, 'currency')].currencyRate;
    const currencyRate = countries[getIndex(currency, countries, 'currency')].currencyRate;
    return ((price / priceCurrencyRate) * currencyRate).toFixed(2).replace(/[.,]00$/, "") + ' ' + currency;
  }
  return 0;
};

export const countries = [
  {
    'name': 'Afghanistan',
    'code': 'AF'
  },
  {
    'name': 'Åland Islands',
    'code': 'AX'
  },
  {
    'name': 'Albania',
    'code': 'AL'
  },
  {
    'name': 'Algeria',
    'code': 'DZ'
  },
  {
    'name': 'American Samoa',
    'code': 'AS'
  },
  {
    'name': 'AndorrA',
    'code': 'AD'
  },
  {
    'name': 'Angola',
    'code': 'AO'
  },
  {
    'name': 'Anguilla',
    'code': 'AI'
  },
  {
    'name': 'Antarctica',
    'code': 'AQ'
  },
  {
    'name': 'Antigua and Barbuda',
    'code': 'AG'
  },
  {
    'name': 'Argentina',
    'code': 'AR'
  },
  {
    'name': 'Armenia',
    'code': 'AM'
  },
  {
    'name': 'Aruba',
    'code': 'AW'
  },
  {
    'name': 'Australia',
    'code': 'AU'
  },
  {
    'name': 'Austria',
    'code': 'AT'
  },
  {
    'name': 'Azerbaijan',
    'code': 'AZ'
  },
  {
    'name': 'Bahamas',
    'code': 'BS'
  },
  {
    'name': 'Bahrain',
    'code': 'BH'
  },
  {
    'name': 'Bangladesh',
    'code': 'BD'
  },
  {
    'name': 'Barbados',
    'code': 'BB'
  },
  {
    'name': 'Belarus',
    'code': 'BY'
  },
  {
    'name': 'Belgium',
    'code': 'BE'
  },
  {
    'name': 'Belize',
    'code': 'BZ'
  },
  {
    'name': 'Benin',
    'code': 'BJ'
  },
  {
    'name': 'Bermuda',
    'code': 'BM'
  },
  {
    'name': 'Bhutan',
    'code': 'BT'
  },
  {
    'name': 'Bolivia',
    'code': 'BO'
  },
  {
    'name': 'Bosnia and Herzegovina',
    'code': 'BA'
  },
  {
    'name': 'Botswana',
    'code': 'BW'
  },
  {
    'name': 'Bouvet Island',
    'code': 'BV'
  },
  {
    'name': 'Brazil',
    'code': 'BR'
  },
  {
    'name': 'British Indian Ocean Territory',
    'code': 'IO'
  },
  {
    'name': 'Brunei Darussalam',
    'code': 'BN'
  },
  {
    'name': 'Bulgaria',
    'code': 'BG'
  },
  {
    'name': 'Burkina Faso',
    'code': 'BF'
  },
  {
    'name': 'Burundi',
    'code': 'BI'
  },
  {
    'name': 'Cambodia',
    'code': 'KH'
  },
  {
    'name': 'Cameroon',
    'code': 'CM'
  },
  {
    'name': 'Canada',
    'code': 'CA'
  },
  {
    'name': 'Cape Verde',
    'code': 'CV'
  },
  {
    'name': 'Cayman Islands',
    'code': 'KY'
  },
  {
    'name': 'Central African Republic',
    'code': 'CF'
  },
  {
    'name': 'Chad',
    'code': 'TD'
  },
  {
    'name': 'Chile',
    'code': 'CL'
  },
  {
    'name': 'China',
    'code': 'CN'
  },
  {
    'name': 'Christmas Island',
    'code': 'CX'
  },
  {
    'name': 'Cocos (Keeling) Islands',
    'code': 'CC'
  },
  {
    'name': 'Colombia',
    'code': 'CO'
  },
  {
    'name': 'Comoros',
    'code': 'KM'
  },
  {
    'name': 'Congo',
    'code': 'CG'
  },
  {
    'name': 'Congo, The Democratic Republic of the',
    'code': 'CD'
  },
  {
    'name': 'Cook Islands',
    'code': 'CK'
  },
  {
    'name': 'Costa Rica',
    'code': 'CR'
  },
  {
    'name': 'Cote DIvoire',
    'code': 'CI'
  },
  {
    'name': 'Croatia',
    'code': 'HR'
  },
  {
    'name': 'Cuba',
    'code': 'CU'
  },
  {
    'name': 'Cyprus',
    'code': 'CY'
  },
  {
    'name': 'Czech Republic',
    'code': 'CZ'
  },
  {
    'name': 'Denmark',
    'code': 'DK'
  },
  {
    'name': 'Djibouti',
    'code': 'DJ'
  },
  {
    'name': 'Dominica',
    'code': 'DM'
  },
  {
    'name': 'Dominican Republic',
    'code': 'DO'
  },
  {
    'name': 'Ecuador',
    'code': 'EC'
  },
  {
    'name': 'Egypt',
    'code': 'EG'
  },
  {
    'name': 'El Salvador',
    'code': 'SV'
  },
  {
    'name': 'Equatorial Guinea',
    'code': 'GQ'
  },
  {
    'name': 'Eritrea',
    'code': 'ER'
  },
  {
    'name': 'Estonia',
    'code': 'EE'
  },
  {
    'name': 'Ethiopia',
    'code': 'ET'
  },
  {
    'name': 'Falkland Islands (Malvinas)',
    'code': 'FK'
  },
  {
    'name': 'Faroe Islands',
    'code': 'FO'
  },
  {
    'name': 'Fiji',
    'code': 'FJ'
  },
  {
    'name': 'Finland',
    'code': 'FI'
  },
  {
    'name': 'France',
    'code': 'FR'
  },
  {
    'name': 'French Guiana',
    'code': 'GF'
  },
  {
    'name': 'French Polynesia',
    'code': 'PF'
  },
  {
    'name': 'French Southern Territories',
    'code': 'TF'
  },
  {
    'name': 'Gabon',
    'code': 'GA'
  },
  {
    'name': 'Gambia',
    'code': 'GM'
  },
  {
    'name': 'Georgia',
    'code': 'GE'
  },
  {
    'name': 'Germany',
    'code': 'DE'
  },
  {
    'name': 'Ghana',
    'code': 'GH'
  },
  {
    'name': 'Gibraltar',
    'code': 'GI'
  },
  {
    'name': 'Greece',
    'code': 'GR'
  },
  {
    'name': 'Greenland',
    'code': 'GL'
  },
  {
    'name': 'Grenada',
    'code': 'GD'
  },
  {
    'name': 'Guadeloupe',
    'code': 'GP'
  },
  {
    'name': 'Guam',
    'code': 'GU'
  },
  {
    'name': 'Guatemala',
    'code': 'GT'
  },
  {
    'name': 'Guernsey',
    'code': 'GG'
  },
  {
    'name': 'Guinea',
    'code': 'GN'
  },
  {
    'name': 'Guinea-Bissau',
    'code': 'GW'
  },
  {
    'name': 'Guyana',
    'code': 'GY'
  },
  {
    'name': 'Haiti',
    'code': 'HT'
  },
  {
    'name': 'Heard Island and Mcdonald Islands',
    'code': 'HM'
  },
  {
    'name': 'Holy See (Vatican City State)',
    'code': 'VA'
  },
  {
    'name': 'Honduras',
    'code': 'HN'
  },
  {
    'name': 'Hong Kong',
    'code': 'HK'
  },
  {
    'name': 'Hungary',
    'code': 'HU'
  },
  {
    'name': 'Iceland',
    'code': 'IS'
  },
  {
    'name': 'India',
    'code': 'IN'
  },
  {
    'name': 'Indonesia',
    'code': 'ID'
  },
  {
    'name': 'Iran, Islamic Republic Of',
    'code': 'IR'
  },
  {
    'name': 'Iraq',
    'code': 'IQ'
  },
  {
    'name': 'Ireland',
    'code': 'IE'
  },
  {
    'name': 'Isle of Man',
    'code': 'IM'
  },
  {
    'name': 'Israel',
    'code': 'IL'
  },
  {
    'name': 'Italy',
    'code': 'IT'
  },
  {
    'name': 'Jamaica',
    'code': 'JM'
  },
  {
    'name': 'Japan',
    'code': 'JP'
  },
  {
    'name': 'Jersey',
    'code': 'JE'
  },
  {
    'name': 'Jordan',
    'code': 'JO'
  },
  {
    'name': 'Kazakhstan',
    'code': 'KZ'
  },
  {
    'name': 'Kenya',
    'code': 'KE'
  },
  {
    'name': 'Kiribati',
    'code': 'KI'
  },
  {
    'name': 'Korea, Democratic Peoples Republic of',
    'code': 'KP'
  },
  {
    'name': 'Korea, Republic of',
    'code': 'KR'
  },
  {
    'name': 'Kuwait',
    'code': 'KW'
  },
  {
    'name': 'Kyrgyzstan',
    'code': 'KG'
  },
  {
    'name': 'Lao Peoples Democratic Republic',
    'code': 'LA'
  },
  {
    'name': 'Latvia',
    'code': 'LV'
  },
  {
    'name': 'Lebanon',
    'code': 'LB'
  },
  {
    'name': 'Lesotho',
    'code': 'LS'
  },
  {
    'name': 'Liberia',
    'code': 'LR'
  },
  {
    'name': 'Libyan Arab Jamahiriya',
    'code': 'LY'
  },
  {
    'name': 'Liechtenstein',
    'code': 'LI'
  },
  {
    'name': 'Lithuania',
    'code': 'LT'
  },
  {
    'name': 'Luxembourg',
    'code': 'LU'
  },
  {
    'name': 'Macao',
    'code': 'MO'
  },
  {
    'name': 'Macedonia, The Former Yugoslav Republic of',
    'code': 'MK'
  },
  {
    'name': 'Madagascar',
    'code': 'MG'
  },
  {
    'name': 'Malawi',
    'code': 'MW'
  },
  {
    'name': 'Malaysia',
    'code': 'MY'
  },
  {
    'name': 'Maldives',
    'code': 'MV'
  },
  {
    'name': 'Mali',
    'code': 'ML'
  },
  {
    'name': 'Malta',
    'code': 'MT'
  },
  {
    'name': 'Marshall Islands',
    'code': 'MH'
  },
  {
    'name': 'Martinique',
    'code': 'MQ'
  },
  {
    'name': 'Mauritania',
    'code': 'MR'
  },
  {
    'name': 'Mauritius',
    'code': 'MU'
  },
  {
    'name': 'Mayotte',
    'code': 'YT'
  },
  {
    'name': 'Mexico',
    'code': 'MX'
  },
  {
    'name': 'Micronesia, Federated States of',
    'code': 'FM'
  },
  {
    'name': 'Moldova, Republic of',
    'code': 'MD'
  },
  {
    'name': 'Monaco',
    'code': 'MC'
  },
  {
    'name': 'Mongolia',
    'code': 'MN'
  },
  {
    'name': 'Montserrat',
    'code': 'MS'
  },
  {
    'name': 'Morocco',
    'code': 'MA'
  },
  {
    'name': 'Mozambique',
    'code': 'MZ'
  },
  {
    'name': 'Myanmar',
    'code': 'MM'
  },
  {
    'name': 'Namibia',
    'code': 'NA'
  },
  {
    'name': 'Nauru',
    'code': 'NR'
  },
  {
    'name': 'Nepal',
    'code': 'NP'
  },
  {
    'name': 'Netherlands',
    'code': 'NL'
  },
  {
    'name': 'Netherlands Antilles',
    'code': 'AN'
  },
  {
    'name': 'New Caledonia',
    'code': 'NC'
  },
  {
    'name': 'New Zealand',
    'code': 'NZ'
  },
  {
    'name': 'Nicaragua',
    'code': 'NI'
  },
  {
    'name': 'Niger',
    'code': 'NE'
  },
  {
    'name': 'Nigeria',
    'code': 'NG'
  },
  {
    'name': 'Niue',
    'code': 'NU'
  },
  {
    'name': 'Norfolk Island',
    'code': 'NF'
  },
  {
    'name': 'Northern Mariana Islands',
    'code': 'MP'
  },
  {
    'name': 'Norway',
    'code': 'NO'
  },
  {
    'name': 'Oman',
    'code': 'OM'
  },
  {
    'name': 'Pakistan',
    'code': 'PK'
  },
  {
    'name': 'Palau',
    'code': 'PW'
  },
  {
    'name': 'Palestinian Territory, Occupied',
    'code': 'PS'
  },
  {
    'name': 'Panama',
    'code': 'PA'
  },
  {
    'name': 'Papua New Guinea',
    'code': 'PG'
  },
  {
    'name': 'Paraguay',
    'code': 'PY'
  },
  {
    'name': 'Peru',
    'code': 'PE'
  },
  {
    'name': 'Philippines',
    'code': 'PH'
  },
  {
    'name': 'Pitcairn',
    'code': 'PN'
  },
  {
    'name': 'Poland',
    'code': 'PL'
  },
  {
    'name': 'Portugal',
    'code': 'PT'
  },
  {
    'name': 'Puerto Rico',
    'code': 'PR'
  },
  {
    'name': 'Qatar',
    'code': 'QA'
  },
  {
    'name': 'Reunion',
    'code': 'RE'
  },
  {
    'name': 'Romania',
    'code': 'RO'
  },
  {
    'name': 'Russian Federation',
    'code': 'RU'
  },
  {
    'name': 'Rwanda',
    'code': 'RW'
  },
  {
    'name': 'Saint Helena',
    'code': 'SH'
  },
  {
    'name': 'Saint Kitts and Nevis',
    'code': 'KN'
  },
  {
    'name': 'Saint Lucia',
    'code': 'LC'
  },
  {
    'name': 'Saint Pierre and Miquelon',
    'code': 'PM'
  },
  {
    'name': 'Saint Vincent and the Grenadines',
    'code': 'VC'
  },
  {
    'name': 'Samoa',
    'code': 'WS'
  },
  {
    'name': 'San Marino',
    'code': 'SM'
  },
  {
    'name': 'Sao Tome and Principe',
    'code': 'ST'
  },
  {
    'name': 'Saudi Arabia',
    'code': 'SA'
  },
  {
    'name': 'Senegal',
    'code': 'SN'
  },
  {
    'name': 'Serbia and Montenegro',
    'code': 'CS'
  },
  {
    'name': 'Seychelles',
    'code': 'SC'
  },
  {
    'name': 'Sierra Leone',
    'code': 'SL'
  },
  {
    'name': 'Singapore',
    'code': 'SG'
  },
  {
    'name': 'Slovakia',
    'code': 'SK'
  },
  {
    'name': 'Slovenia',
    'code': 'SI'
  },
  {
    'name': 'Solomon Islands',
    'code': 'SB'
  },
  {
    'name': 'Somalia',
    'code': 'SO'
  },
  {
    'name': 'South Africa',
    'code': 'ZA'
  },
  {
    'name': 'South Georgia and the South Sandwich Islands',
    'code': 'GS'
  },
  {
    'name': 'Spain',
    'code': 'ES'
  },
  {
    'name': 'Sri Lanka',
    'code': 'LK'
  },
  {
    'name': 'Sudan',
    'code': 'SD'
  },
  {
    'name': 'Suriname',
    'code': 'SR'
  },
  {
    'name': 'Svalbard and Jan Mayen',
    'code': 'SJ'
  },
  {
    'name': 'Swaziland',
    'code': 'SZ'
  },
  {
    'name': 'Sweden',
    'code': 'SE'
  },
  {
    'name': 'Switzerland',
    'code': 'CH'
  },
  {
    'name': 'Syrian Arab Republic',
    'code': 'SY'
  },
  {
    'name': 'Taiwan, Province of China',
    'code': 'TW'
  },
  {
    'name': 'Tajikistan',
    'code': 'TJ'
  },
  {
    'name': 'Tanzania, United Republic of',
    'code': 'TZ'
  },
  {
    'name': 'Thailand',
    'code': 'TH'
  },
  {
    'name': 'Timor-Leste',
    'code': 'TL'
  },
  {
    'name': 'Togo',
    'code': 'TG'
  },
  {
    'name': 'Tokelau',
    'code': 'TK'
  },
  {
    'name': 'Tonga',
    'code': 'TO'
  },
  {
    'name': 'Trinidad and Tobago',
    'code': 'TT'
  },
  {
    'name': 'Tunisia',
    'code': 'TN'
  },
  {
    'name': 'Turkey',
    'code': 'TR'
  },
  {
    'name': 'Turkmenistan',
    'code': 'TM'
  },
  {
    'name': 'Turks and Caicos Islands',
    'code': 'TC'
  },
  {
    'name': 'Tuvalu',
    'code': 'TV'
  },
  {
    'name': 'Uganda',
    'code': 'UG'
  },
  {
    'name': 'Ukraine',
    'code': 'UA'
  },
  {
    'name': 'United Arab Emirates',
    'code': 'AE'
  },
  {
    'name': 'United Kingdom',
    'code': 'GB'
  },
  {
    'name': 'United States',
    'code': 'US'
  },
  {
    'name': 'United States Minor Outlying Islands',
    'code': 'UM'
  },
  {
    'name': 'Uruguay',
    'code': 'UY'
  },
  {
    'name': 'Uzbekistan',
    'code': 'UZ'
  },
  {
    'name': 'Vanuatu',
    'code': 'VU'
  },
  {
    'name': 'Venezuela',
    'code': 'VE'
  },
  {
    'name': 'Viet Nam',
    'code': 'VN'
  },
  {
    'name': 'Virgin Islands, British',
    'code': 'VG'
  },
  {
    'name': 'Virgin Islands, U.S.',
    'code': 'VI'
  },
  {
    'name': 'Wallis and Futuna',
    'code': 'WF'
  },
  {
    'name': 'Western Sahara',
    'code': 'EH'
  },
  {
    'name': 'Yemen',
    'code': 'YE'
  },
  {
    'name': 'Zambia',
    'code': 'ZM'
  },
  {
    'name': 'Zimbabwe',
    'code': 'ZW'
  }
];

export const videoGenre = [
  strings.art,
  strings.comedy,
  strings.gamesAndHobby,
  strings.health,
  strings.technology,
  strings.newsAndPolitics,
  strings.scienceAndMedicine,
  strings.sportsAndLeisure,
  strings.business,
  strings.education,
  strings.authorityAndOrganisation,
  strings.childAndFamily,
  strings.music,
  strings.religionAndSpirituality,
  strings.societyAndCulture,
  strings.tvAndMovies,
  strings.other,
];

export const bookGenre = [
  strings.art,
  strings.comedy,
  strings.gamesAndHobby,
  strings.health,
  strings.technology,
  strings.newsAndPolitics,
  strings.scienceAndMedicine,
  strings.sportsAndLeisure,
  strings.business,
  strings.education,
  strings.authorityAndOrganisation,
  strings.childAndFamily,
  strings.music,
  strings.religionAndSpirituality,
  strings.societyAndCulture,
  strings.tvAndMovies,
  strings.other,
];

export const musicGenre = [
  strings.pop,
  strings.hipHop,
  strings.electronicDance,
  strings.rnb,
  strings.rock,
  strings.indie,
  strings.jazz,
  strings.metal,
  strings.dance,
  strings.country,
  strings.acoustic,
  strings.soul,
  strings.classic,
  strings.blues,
  strings.latino,
  strings.punk,
  strings.reggae,
  strings.funk,
  strings.kpop,
  strings.other,
];

export const datePeriod = [
  strings.week,
  strings.month,
  strings.year,
];

export const genders = [
  {
    name: strings.male,
    key: 'male'
  },
  {
    name: strings.female,
    key: 'female'
  }
];

export const identityTypes = [
  {
    name: strings.passport,
    key: 'passport'
  },
  {
    name: strings.idCard,
    key: 'id_card'
  },
  {
    name: strings.creditOrDebitCard,
    key: 'credit_or_debit_card'
  },
  {
    name: strings.drivingLicense,
    key: 'driving_license'
  }
];

export const addressTypes = [
  {
    name: strings.passport,
    key: 'passport'
  },
  {
    name: strings.idCard,
    key: 'id_card'
  },
  {
    name: strings.drivingLicense,
    key: 'driving_license'
  },
  {
    name: strings.utilityBill,
    key: 'utility_bill'
  },
  {
    name: strings.bankStatement,
    key: 'bank_statement'
  },
  {
    name: strings.rentAgreement,
    key: 'rent_agreement'
  },
  {
    name: strings.employerLetter,
    key: 'employer_letter'
  },
  {
    name: strings.insuranceAgreement,
    key: 'insurance_agreement'
  },
  {
    name: strings.taxBill,
    key: 'tax_bill'
  },
];
