import React from 'react';
import {
  View,
  Image,
} from 'react-native';
import veoLogo from '../../assets/icons/veoLogo-white.png';

import { colors } from '../../theme';

const VeoIcon = () => <View style={{
  backgroundColor: '#F76E36',

}}>
  <Image
    style={{
      height: 12,
      width: 32,
    }}
    source={veoLogo}
  />
</View>

export default VeoIcon
