import React, { useEffect, useRef, useState } from 'react';
import {
  View,
  PanResponder,
  Text,
  Animated,
  Dimensions
} from 'react-native';
import { fonts, colors, gutters, mediaQueries, useMobileSize } from '../../theme';

const START_Y = -90;
const FINISH_Y = 50;
const DURATION = 150;
const TIMEOUT = 3000;

const Win = Dimensions.get('window');
const screenWidth = Win.width > mediaQueries.tablet ? mediaQueries.tablet : Win.width;

const SnackBar = () => {

  const [messageTimestamp, setMessageTimestamp] = useState(null);
  const [message, setMessage] = useState('');

  const isMobile = useMobileSize();

  const animatedY = useRef(new Animated.ValueXY({
    x: 0,
    y: START_Y
  })).current;

  useEffect(() => {
    global.modalNotice = (text) => {
      setMessage(text);
      animatedY.setValue({ y: START_Y, x: 0 });
      Animated.timing(
        animatedY,
        {
          toValue: {
            x: 0,
            y: FINISH_Y
          },
          duration: DURATION,
          useNativeDriver: false
        }
      )
        .start(() => setMessageTimestamp(new Date().getTime()));
    };
  }, [animatedY]);

  useEffect(() => {
    const timer = setTimeout(() => Animated.timing(
      animatedY,
      {
        toValue: {
          x: 0,
          y: START_Y
        },
        duration: DURATION,
        useNativeDriver: false
      }
    ).start(), TIMEOUT);
    return () => clearTimeout(timer);
  }, [messageTimestamp, animatedY]);

  return <Animated.View
    style={{
      marginHorizontal: gutters.pageGutter,
      zIndex: 10000,
      position: 'fixed',
      alignItems: isMobile ? 'flex-start' : 'center',
      width: '100%',
      top: 0,
      flexShrink: 1,
      transform: [{
        translateY: animatedY.y
      }],
    }}>
    <View style={{
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      borderRadius: 4,
      maxWidth: 500,
      flexShrink: 1,
      padding: 10,
      width: screenWidth - 2 * gutters.pageGutter
    }}>
      <Text
        style={{
          fontFamily: fonts.regular,
          fontSize: 14,
          color: colors.white,
          flexShrink: 1
        }}>
        {message}
      </Text>
    </View>
  </Animated.View>
}

export default SnackBar