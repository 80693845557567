import React from 'react';
import LocalizedStrings from 'react-localization';
import {
  View,
  ActivityIndicator,
  Text,
} from 'react-native';

import { fonts, colors } from '../../theme'

const Fetching = ({ loading, error, EmptyComponent = null }) => (loading || error) ? <View style={{
  marginTop: 150
}}>
  {loading ? <ActivityIndicator color={colors.black} /> :
    <Text>
      {strings.error}
    </Text>}
</View> : EmptyComponent

const strings = new LocalizedStrings({
  en: {
    error: 'Something went wrong. Try refreshing the page.'
  },
  sv: {
    error: 'Nånting blev fel, försök ladda om sidan'
  }
});

export default Fetching
