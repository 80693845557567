import gql from 'graphql-tag';
import { profileFragment } from './gqlFragments';

export const PROFILE_SEARCH = gql`
    query ($search: String, $notSelf: Boolean, $skip: Int, $type: String, $id: String) {
        profileSearch(search: $search, notSelf: $notSelf, skip: $skip, type: $type, id: $id) {
            ${profileFragment}
        }
    }
`;

export const PROFILE = gql`
    query ($id: String!) {
        profile(id: $id) {
          ${profileFragment}
        }
    }
`;

export const TRANSACTION_DONE = gql`
  query ($id: ID!) {
    transactionDone(id: $id)
  }
`;

export const GET_CURRENCY = gql`
  query {
    getOnlyCurrency {
      currency
      VAT
      minSponsor
      maxSponsor
    }
  }
`;

export const MY_SPONSORSHIPS = gql`
  query {
    sponsorships {
      id
      sponsorship {
        ${profileFragment}
      }
      active
      isPaid
      isComplete
      block
      type
      sponsored
      currency
      maxPaid
      paidBack
      veoRate
      subscribers
      views
      shares
      comments
      date
      followers
      uploads
      cpc
      maxDaily
      recurring
      monthly
    }
  }
`;

export const ANALYTICS = gql`
  query ($type: String!) {
    partnerAnalytics(type: $type) {
      analytics {
        shareClicks
        recruitedUsers
        recruitedChannels
        recruitedSubscribers
        recruitedSalesmen
        recruitedSponsors
        subscriberReward
        sponsorsChannelReward
        sponsorsGenericReward
        sponsorsAdsReward
        recruiterReward
        registeredJoin
        registeredLaunch
        teamShareClicks
        teamRecruitedUsers
        teamRecruitedChannels
        teamRecruitedSubscribers
        teamRecruitedSalesmen
        teamRecruitedSponsors
        teamSubscriberReward
        teamSponsorsChannelReward
        teamSponsorsGenericReward
        teamSponsorsAdsReward
        teamRecruiterReward
        teamRegisteredJoin
        teamRegisteredLaunch
      }
      myTeam {
        ${profileFragment}
      }
      events {
        id
        type
        recruiterId
        recruiterName
        leaderTeamId
        leaderGroupId
        newUserId
        newUserName
        sponsorshipId
        sponsoredId
        sponsoredName
        amountRec
        amountTeam
        amountGroup
        city
        country
        linkType
        name
        email
        followers
        reason
        estimatedPercent
        whyMove
        whyMain
        whyFew
        existAdvertise
        budgetAds
        wantAds
        wantNoAds
        date
      }
    }
  }
`;

export const TOGGLE_ACTIVE = gql`
  mutation ($id: ID!) {
    toggleActive(id: $id) {
      id
      active
    }
  }
`;

export const SELLER_ACTIVATED = gql`
  mutation {
    sellerActivated
  }
`;

export const UPDATE_AD = gql`
  mutation ($id: ID!, $cpc: Float!, $maxDaily: Float!) {
    updateAd(id: $id, cpc: $cpc, maxDaily: $maxDaily) {
      id
      maxDaily
      cpc
    }
  }
`;

export const ADD_SPONSORSHIP = gql`
  mutation (
    $sponsoredId: ID
    $sponsored: Float
    $type: String!
    $recurring: Boolean
    $externalUrl: String
    $currency: String
    $adTitle: String
    $adDescription: String
    $adUrl: String
    $adCta: String
    $adPrivateMsg: String
    $adImage: String
    $adTarget: String
    $cpc: Float
    $maxDaily: Float
  ) {
    addSponsorship(
      sponsoredId: $sponsoredId
      sponsored: $sponsored
      recurring: $recurring
      type: $type
      externalUrl: $externalUrl
      currency: $currency
      adTitle: $adTitle
      adDescription: $adDescription
      adUrl: $adUrl
      adCta: $adCta
      adPrivateMsg: $adPrivateMsg
      adImage: $adImage
      adTarget: $adTarget
      cpc: $cpc
      maxDaily: $maxDaily
    )
  }
`;

export const NEW_USER = gql`
  mutation ($name: String!, $email: String, $mode: String!) {
    newUser(name: $name, email: $email, mode: $mode)
  }
`;
