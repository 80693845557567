import React from "react";
import {
  View,
} from 'react-native';
import HCaptcha from "@hcaptcha/react-hcaptcha";

const siteKey = process.env.REACT_APP_ENV !== 'development' ? '963ea101-bf30-4843-a5c0-5c2908a447b9' : '10000000-ffff-ffff-ffff-000000000001';
console.log('SITEKEY: ' + siteKey);

const Hcaptcha = ({ captchaFormRef, onMessage, onError, onExpire, extraStyle }) => <View style={extraStyle}>
  <HCaptcha
    sitekey={siteKey}
    onVerify={onMessage}
    onError={onError}
    onExpire={onExpire}
    ref={captchaFormRef}
  />
</View>

export default Hcaptcha;