import React, { useState } from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
} from 'react-native';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import gql from 'graphql-tag';
import WideButton from '../../components/misc/WideButton';
import {
  fonts,
  colors,
  commonStyles
} from '../../theme';

const FORGOT_PASSWORD = gql`
  mutation($email: String!) {
    forgotPassword(email: $email)
  }
`;

export default function SignIn() {
  const history = useHistory();
  const location = useLocation();
  const isSponsor = location.pathname.includes('sponsor');
  const [email, setEmail] = useState('');

  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      global.modalNotice(strings.emailSent)
      setPasswordSent(true);
      setEmail('');
    },
    onError: () => global.modalNotice(strings.error)
  });

  const [passwordSent, setPasswordSent] = useState(false);

  return (
    <View
      style={{
        backgroundColor: colors.white,
        padding: 40,
        borderRadius: 8,
        boxShadow: '3px 2px 20px -3px rgba(0, 0, 0, 0.09)',
      }}
    >
       <Text style={styles.header}>
        {strings.title}
      </Text>
      <Text style={commonStyles.infoText}>
        {passwordSent ? strings.textSent : strings.textEnter}
      </Text>
      <TextInput
        textContentType="emailAddress"
        style={styles.input}
        placeholder={strings.email}
        value={email}
        onChangeText={(input) => setEmail(input)}
      />
      <WideButton
        buttonStyle={styles.buttonStyle}
        loading={loading}
        disabled={email.length < 1}
        onPress={() => passwordSent ? isSponsor ? history.push('/sponsor/signin') : history.push('/signin') : forgotPassword({
          variables: {
            email
          }
        })}
        text={passwordSent ? strings.signin : strings.reset}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    marginBottom: 13,
    fontFamily: fonts.regular,
    fontSize: 32,
    lineHeight: 25,
    color: colors.black,
  },
  label: {
    fontFamily: fonts.regular,
    fontSize: 12,
    lineHeight: 12,
    color: colors.black,
    marginTop: 21,
  },
  input: {
    marginTop: 10,
    borderRadius: 8,
    backgroundColor: colors.greySolo,
    minHeight: 56,
    paddingHorizontal: 10,
    fontFamily: fonts.regular,
    fontSize: 16,
    color: colors.black,
  },
  buttonStyle: {
    marginTop: 40,
    marginBottom: 20,
  },
  createAccount: {
    marginTop: 20,
    marginBottom: 45,
  },
  createAccountText: {
    fontFamily: fonts.regular,
    color: colors.black,
    fontSize: 14,
    lineHeight: 17.5,
    textAlign: 'center',
  },
  createAccountBold: {
    fontFamily: fonts.regular,
    color: colors.black,
  },
});

const strings = new LocalizedStrings({
  en: {
    title: 'Reset Password',
    reset: 'Reset',
    email: 'Email',
    textEnter: 'Enter the email address you used when you registered. We will send you a link to reset your password.',
    textSent: 'A link for resetting your password has now been sent to your email.',
    signin: 'Sign in',
    error: 'Failed resetting password.',
    emailSent: 'Email for resetting password has been sent.'
  },
  sv: {
    title: 'Återställ lösenord',
    reset: 'Återställ',
    email: 'E-post',
    textEnter: 'Ange e-postadressen du använde när du registrerade dig. Vi skickar ett mejl med en länk där du kan återställa ditt lösenord.',
    textSent: 'En länk för att återställa ditt lösenord har skickats till din e-post.',
    signin: 'Logga in',
    error: 'Misslyckades återställa lösenord.',
    emailSent: 'Epost för återställning av lösenord skickad.'
  }
});
