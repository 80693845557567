import React from 'react';
import LocalizedStrings from 'react-localization';
import {
  View,
  Text,
  Image,
  StyleSheet
} from 'react-native';
import Avatar from '../Avatar';
import WideButton from '../misc/WideButton';

import { colors, fonts, fontsize, gutters } from '../../theme';

const IMAGE_WIDTH = 295;
const IMAGE_HEIGHT = 158;

const Ad = ({
  sponsorImage,
  sponsorName,
  adImage,
  adTitle,
  adDescription,
  adUrl,
  adCta,
  adPrivateMsg }) => <View style={{
    padding: gutters.pageGutter,
    borderWidth: 1,
    borderColor: colors.greySolo,
    maxWidth: IMAGE_WIDTH + gutters.pageGutter * 2
  }}>
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
      flexShrink: 1,
      marginBottom: 10
    }}>
      <Avatar image={sponsorImage} size={40} extraStyles={{ marginRight: 20 }} />
      <Text style={styles.text}>
        {sponsorName}
      </Text>
    </View>
    {adImage ? <Image source={adImage} resizeMode="cover" style={{
      height: IMAGE_HEIGHT,
      width: IMAGE_WIDTH,
    }} /> : <PlaceholderBox
      height={IMAGE_HEIGHT}
      width={IMAGE_WIDTH}
      text={strings.coverPhoto}
    />}
    <Text style={{
      fontFamily: fonts.regular,
      fontSize: fontsize.regular,
      color: colors.black,
      marginVertical: 10
    }}>
      {adTitle ? adTitle : strings.titlePlaceholder}
    </Text>
    <PlaceholderBox
      height={120}
      width={'100%'}
      text={strings.infoVeo}
    />
    <Text style={[styles.text, { fontSize: fontsize.small, marginBottom: 5, marginTop: 10 }]}>
      {strings.description}
    </Text>
    <Text style={styles.text}>
      {adDescription ? adDescription : strings.descPlaceHolder}
    </Text>
    <Text style={[{
      marginBottom: 5,
      marginTop: 10
    }, styles.text]}>
      <Text style={{ fontSize: fontsize.small }}>
        {strings.privateMesg}
      </Text>
      <Text style={{
        color: colors.greyLeia,
        fontSize: fontsize.xsmall
      }}>
        {strings.pmInfo}
      </Text>
    </Text>
    <Text style={styles.text}>
      {adPrivateMsg ? adPrivateMsg : strings.pmPlaceholder}
    </Text>
    <WideButton
      buttonStyle={{ marginTop: 40 }}
      pill text={adCta ? adCta : strings.ctaPlaceholder}
      onPress={() => adUrl ? window.open(adUrl, '_newtab') : null}
    />
  </View>

const PlaceholderBox = ({ text, height, width }) => <View style={{
  height: height,
  width: width,
  backgroundColor: colors.greySolo,
  justifyContent: 'center',
  alignItems: 'center'
}}>
  <Text style={{
    fontSize: fontsize.regular,
    fontFamily: fonts.regular,
    color: colors.greyLeia
  }}>
    {text}
  </Text>
</View>

const strings = new LocalizedStrings({
  en: {
    coverPhoto: 'Cover photo',
    description: 'Description',
    privateMesg: 'Private message',
    pmInfo: ' (Only visible for channel owner)',
    titlePlaceholder: 'Title goes here',
    descPlaceHolder: 'Description goes here',
    pmPlaceholder: 'Private message goes here',
    ctaPlaceholder: 'CTA',
    infoVeo: 'Info to recipient from VEO'
  },
  sv: {
    coverPhoto: 'Bild',
    description: 'Beskrivning',
    privateMesg: 'Privat meddelande',
    pmInfo: ' (Endast synlig för kanalägare)',
    titlePlaceholder: 'Titel hamnar här',
    descPlaceHolder: 'Beskrivning hamnar här',
    pmPlaceholder: 'Privat meddelande hamnar här',
    ctaPlaceholder: 'CTA',
    infoVeo: 'Info till mottagaren från VEO'
  },
});

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    fontFamily: fonts.regular,
    flexShrink: 1
  }
})

export default Ad;