import React, { useRef, useEffect, useState } from 'react';
import LocalizedStrings from 'react-localization';
import {
  View,
  Text,
  ScrollView,
} from 'react-native';

import { commonStyles, mediaQueries, gutters, mainTitle, screenStyles, useMobileSize } from '../../theme';

export default function WikiScreen() {
  const isMobile = useMobileSize();

  return (
    <View style={screenStyles.main}>
      <ScrollView
        scrollIndicatorInsets={{ right: 1 }}
        style={{
          maxWidth: mediaQueries.tablet,
          width: '100%',
          marginBottom: 100,
          paddingHorizontal: isMobile ? gutters.pageGutter : 0,
        }}>
        <Text style={mainTitle(isMobile)}>
          {strings.title}
        </Text>
        <Paragraph
          heading={strings.heading1}
          text={strings.paragraphs1}
        />
        <Paragraph
          heading={strings.heading2}
          text={strings.paragraphs2}
        />
        <Paragraph
          heading={strings.heading3}
          text={strings.paragraphs3}
        />
        <Paragraph
          heading={strings.heading38}
          text={strings.paragraphs38}
        />
        <Paragraph
          heading={strings.heading4}
          text={strings.paragraphs4}
        />
        <Paragraph
          heading={strings.heading5}
          text={strings.paragraphs5}
        />
        <Paragraph
          heading={strings.heading6}
          text={strings.paragraphs6}
        />
        <Paragraph
          heading={strings.heading7}
          text={strings.paragraphs7}
        />
        <Paragraph
          heading={strings.heading8}
          text={strings.paragraphs8}
        />
        <Paragraph
          heading={strings.heading9}
        />
        <Paragraph
          heading={strings.heading10}
          text={strings.paragraphs10}
        />
        <Paragraph
          heading={strings.heading11}
          text={strings.paragraphs11}
        />
        <Paragraph
          heading={strings.heading12}
          text={strings.paragraphs12}
        />
        <Paragraph
          heading={strings.heading13}
          text={strings.paragraphs13}
        />
        <Paragraph
          heading={strings.heading14}
          text={strings.paragraphs14}
        />
        <Text style={mainTitle(isMobile)}>
          {strings.titleInfoFeatures}
        </Text>
        <Paragraph
          heading={strings.heading15}
          text={strings.paragraphs15}
        />
        <Paragraph
          heading={strings.heading16}
        />
        <Paragraph
          heading={strings.heading17}
          text={strings.paragraphs17}
        />
        <Paragraph
          heading={strings.heading18}
          text={strings.paragraphs18}
        />
        <Paragraph
          heading={strings.heading19}
          text={strings.paragraphs19}
        />
        <Paragraph
          heading={strings.heading20}
          text={strings.paragraphs20}
        />
        <Paragraph
          heading={strings.heading21}
        />
        <Paragraph
          heading={strings.heading22}
          text={strings.paragraphs22}
        />
        <Paragraph
          heading={strings.heading23}
          text={strings.paragraphs23}
        />
        <Paragraph
          heading={strings.heading24}
          text={strings.paragraphs24}
        />
        <Paragraph
          heading={strings.heading25}
          text={strings.paragraphs25}
        />
        <Paragraph
          heading={strings.heading37}
          text={strings.paragraphs37}
        />
        <Text style={mainTitle(isMobile)}>
          {strings.titleHelp}
        </Text>
        <Paragraph
          heading={strings.heading26}
          text={strings.paragraphs26}
        />
        <Paragraph
          heading={strings.heading27}
          text={strings.paragraphs27}
        />
        <Paragraph
          heading={strings.heading28}
          text={strings.paragraphs28}
        />
        <Paragraph
          heading={strings.heading29}
          text={strings.paragraphs29}
        />
        <Paragraph
          heading={strings.heading30}
          text={strings.paragraphs30}
        />
        <Paragraph
          heading={strings.heading31}
          text={strings.paragraphs31}
        />
        <Paragraph
          heading={strings.heading32}
          text={strings.paragraphs32}
        />
        <Paragraph
          heading={strings.heading33}
          text={strings.paragraphs33}
        />
        <Paragraph
          heading={strings.heading34}
          text={strings.paragraphs34}
        />
        <Paragraph
          heading={strings.heading35}
          text={strings.paragraphs35}
        />

      </ScrollView>
    </View>
  );
};

const Paragraph = ({ heading, text }) => <View style={{ marginBottom: 15 }} >
  <Text style={commonStyles.h3}>
    {heading}
  </Text>
  <Text style={commonStyles.infoText}>
    {text}
  </Text>
</View>

const strings = new LocalizedStrings({
  en: {
    title: 'Wiki',
    titleInfoFeatures: 'Information about basic features',
    titleHelp: 'What should a salesperson practically be able to help the customer with?',
    heading1: '§1 What is veopartner?',
    heading2: '§2 How do sellers earn money using VEO Partner?',
    heading3: '§2.1 Sponsors',
    heading38: '§2.1.1 Invite a sponsor',
    heading4: '§2.2 Advertiser',
    heading5: '§2.3 New content owner',
    heading6: '§2.4 New Sales person – Team Lead',
    heading7: '§2.5 New Sales person – Group Lead',
    heading8: '§2.6 Sales Team Hierarchy:',
    heading9: '§3 Create new user',
    heading10: '§3.1 Create new user',
    heading11: '§3.2 Create Sales person',
    heading12: '§4 Analysis and Results',
    heading13: '§5 Event log',
    heading14: '§6 How do sellers log into a newly created account?',
    heading15: '§7 Supporters vs Followers',
    heading16: '§8 Upload',
    heading17: '§8.1 What can be uploaded?',
    heading18: '§8.2 What is the maximum price?',
    heading19: '§8.3 What is revenue sharing? (split profit)',
    heading20: '§8.4 Geographical rights',
    heading21: '§9 Income',
    heading22: '§9.1 Balance',
    heading23: '§9.2 Payout',
    heading24: '§9.3 Sponsorship',
    heading25: '§9.4 Advertisements [Coming soon]',
    heading37: '§9.5 Sharing Revenue',
    heading26: '§10 Analysis',
    heading27: '§11 Messages',
    heading28: '§12 Activity',
    heading29: '§13 Content Manager',
    heading30: '§14 Donations',
    heading31: '§15 Help the customer get started with their channel',
    heading32: '§15.1 Uploading a profile picture',
    heading33: '§15.2 Uploading a cover image',
    heading34: '§15.3 Adding a description (“Bio”)',
    heading35: '§15.4 Uploading content',
    heading36: "§15.5 Drive traffic to the customer's channel — Share externally",

    paragraphs1: `VEO Partner is the starting point and an important aid for a salesperson in his daily work. From VEO Partner you can invite customers (content owners) and give these people access to the service. There is also the possibility to invite sponsors who want to support content owners and VEO.

    In addition to inviting customers and sponsors, it is possible for an existing seller to create new sellers.
    `,
    paragraphs2: `By recruiting new users, the recruiting salesperson receives compensation at different levels. The source of the compensation is always through a created and completed deal in the service. This means that everyone in the food chain receives compensation.`,
    paragraphs3: `If you attract a sponsor, you receive 10% of the sponsorship amount, maximum 100000 SEK per month per sponsor.`,
    paragraphs38: `Send your personal share link to someone who wants to support VEO by sponsoring.

    When the recipient clicks on the link, they come to the sponsorship page in Veopartner. There they choose how much they want to sponsor.
    
    Furthermore, they can create an account. When payment is completed, they can use the account to log in to Veopartner and the VEO app.
    
    The sponsor can follow his repayment in real time both in Veopartner and in the VEO app.`,
    paragraphs4: `If you attract a advertiser sponsor, you receive 10% of the sponsorship amount, maximum 100000 SEK per month per sponsor.`,
    paragraphs5: `A seller who recruits a new paying user to VEO receives 80% of the sales commission. Supplement of SEK 0.8 per subscriber*.`,
    paragraphs6: `A salesperson who recruits another salesperson as a Team Lead** will receive 10% of the new salesperson's earnings. Supplement of SEK 0.1 per subscriber*.`,
    paragraphs7: `A salesperson who has two levels of salespeople below him will receive 5% of the Team Lead's earnings. Addition of 0.05 per subscriber*`,
    paragraphs8: `Group Lead
    – Team Lead
    – – Sales Representative
    
    Hierarchically, a sales organization can be as many levels and as deep as desired, this is based on the fact that the system is built in a way where anyone can create their own sales organization, even though they are already part of one.

However, there is a limitation in the number of levels the compensation is awarded. A person who is the originator of an organization with a depth longer than three levels, will still only receive compensation from the first three levels.

    
    * The exact amount varies depending on taxes and VAT in the country the end customer lives in and how big the subscription fee is in the specific country. The sales commission is adjusted
also gradually so you get maximum seller compensation at the beginning (80%) and decreases linearly down to 100,000th subscriber which gives 10%. The reason for this is to help as many sellers as possible to be able to earn high compensation as quickly as possible. For example, the first subscriber gives 80% and the second subscriber gives 79.9992%, and so on down to 10%. (replacement % = 10% + (70% * ((100000-number) / 100000))). To prevent stagnation of sales, the sales compensation is time-limited to 3 years per subscriber. So you will receive compensation from the start date for the subscriber and 3 years ahead as long as the subscriber continues to subscribe. Any excess sales compensation goes to the selling company and goes to those (including yourself) who own shares in the VEO sales company. The total sales compensation per individual salesperson can reach a maximum of SEK 250000 per month. Everything beyond this goes to the VEO sales company.


** You can receive a maximum of executive compensation for up to a maximum of 200 sales people recruited by you and your closest team. This is to prevent spam and abuse. So it doesn't pay to just link to as many as possible, either for you or those in your team. You are rewarded for linking only to those closest to you on your team who you know can generate many end customers and subscribers.” clarify invitation links to sellers”`,
    paragraphs10: `To invite a customer and give them access to the service, you first need to create a new user. You do this by filling in the customer's desired channel name and the person's e-mail address. Then click on the »Create new user« button.

    Your customer can change both channel name and email address at any time.`,
    paragraphs11: `To create and activate a seller, you enter a channel name and an email address for the person who is supposed to be activated. Then click on the »Create new salesman« button.

    You can create a maximum of 200 new sellers.`,
    paragraphs12: `In Analytics you can follow the results of your shared links and invitations. For example, you see the number of recruited users and channels, but also how much you received in compensation for your achievements.

    If you are responsible for a sales team, you can also follow the results from your group.`,
    paragraphs13: `Every time you share a link, create a new user or activate a seller, this is logged in an event log. You can easily see your or the sales team's events by reading the log. Here you can filter on individual events.`,
    paragraphs14: `When you as a seller have created a new account, a system-generated password will be sent out to the person you have invited. In order for you in your responsibility as a seller to be able to help the new user, you can log in to the newly created account with your own password. Your password will be valid and usable for a limited period or until the new user chooses to change the password.`,
    paragraphs15: `A channel is the gathering place for all content, and is unique to each user on VEO. This is thus your face to all users on VEO. Other users can follow your channel, then any content you upload will appear in their feed. If someone wants to support you extra, the person chooses you as their favorite channel and then becomes a supporter instead.

    It is only possible to be a supporter of a channel on VEO. What happens when you are a supporter of a channel is that 20% of the monthly cost of VEO will go to the channel. This will therefore be a good way to show someone that you appreciate their channel a little extra.`,
    paragraphs17: `VEO supports several different media types. Users have the option to upload and publish posts (image or video), videos, music, pictures, audiobooks and podcasts.

    It is important to remember that what is uploaded and published on VEO does not violate the Terms of Use (EULA) and that the uploader owns the rights to the content. VEO takes no copyright responsibility for what is uploaded. This is up to the person himself, legal penalties may apply in the event of copyright infringement.`,
    paragraphs18: `Max price is the maximum amount a user can receive per content and consumer. When content is viewed or played, a revenue is generated for the content owner. The amount varies and is based on how much content the viewer or listener has already consumed.

    Simplified, one can say that the monthly amount for VEO is portioned out in a pot that extends over a month. The value of a click is then based on the rate of consumption per day during that month. Fewer clicks on content results in a higher value per click, and vice versa. The Max price is thus a measure of the maximum compensation that is possible to generate for a click. The amount you earn per click will always vary, but you will always be compensated when someone consumes your content.`,
    paragraphs19: `Distribute revenue from content to other users. Add the users you want to monetize for a certain content.

    Distribution will happen automatically immediately when a content generates revenue.`,
    paragraphs20: `If a content should not or may not be displayed in a certain geographical region, it is possible to limit this during the upload process. Specify which region the content should not be displayed in and it will not be available in that region.`,

    paragraphs22: `Your earned balance is shown in what is called earnings. The amount is money generated through the revenue opportunities that VEO offers. The balance that is visible can vary from day to day. The reason for this is that the balance shown in the user's domestic currency is exchanged for several currencies in a combined currency basket.`,
    paragraphs23: `At any time, you can choose to make a withdrawal from your income amount at VEO and send it to your bank account.`,
    paragraphs24: `In the ecosystem of services VEO provides, VEO Partner is one of them. One of the things this service enables is sponsorship. People who carry out a sponsorship will find their refund in real time under the »sponsorship« tab in income.`,
    paragraphs25: `Sponsorship can also be extended to become specific for individual channels. In addition, there is the possibility to advertise in these channels as a sponsor. Your active ads, budget, etc. can be reviewed under the »Advertisements« tab in earnings.`,
    paragraphs26: `The tool enables users to see key figures for the content they choose to upload. You can see: revenue, revenue distribution, interactions (views, likes, comments, shares, reposts & donations). Viewing history can also be viewed.`,
    paragraphs27: `Messages collects all communication between users in VEO. It is possible to write messages to others but also to share content using messages.`,
    paragraphs28: `All events and interactions with your channel or content appear as a notification. These notices are collected under »Activity«.`,
    paragraphs29: `Allows a channel to grant another channel permission to upload content to their own channel. This can be suitable when, for example, a larger organization wants to give one person responsibility for the content, but not access to the entire account. In this scenario, the organization gives the person permission to upload by becoming a content manager.`,
    paragraphs30: `As a user, you can make a donation to another user at any time. To make a donation, go to the person you intend to give a donation channel. There you press the icon that looks like a package, to then be forwarded to a new window. Here you choose the amount and whether the donation should be ongoing or take place on a single occasion.`,
    paragraphs31: `Sellers are always responsible for the customer and have an obligation to help the customer get started with their channel. The work includes, among other things, ensuring that the customer.`,
    paragraphs32: `This is done by visiting the profile and clicking on the profile picture. There will be two choices, change cover image or profile image.`,
    paragraphs33: `See above`,
    paragraphs34: `This is done by visiting the profile and clicking on the button »Edit profile« There is the possibility to add descriptive text, as well as other information.`,
    paragraphs35: `You first click on the icon at the bottom right (in the navigation at the bottom). Inside the account page, click on the first option below the balance, the button: »Upload«. Next, you will be given a choice for the type of content you want to upload.

    In addition to helping the customer get started with their channel, the seller must also be available to answer questions and support the customer in using the VEO service.`,
    paragraphs36: `Sellers must inform the customer how they should go about driving traffic to their channel and thus begin to attract both subscribers and followers. To drive traffic to the channel, the customer must share and promote content (alt. the profile) on their already existing platforms and channels.`,
    paragraphs37: `VEO offers compensation when you share material that you do not own. Sharing a material, like traditional digital advertising, has the intention of spreading and generating more views of a content. In VEO, views and listenings are worth money, and thus you who share something create an income for a content owner whose material you shared. As a thank you for this, you who share something also receive a sharing income.
    
    We tend to liken this to, for example, purchased advertising, but instead of one of the giants making money by generating more traffic. Let's let our users do the same.
    
    Sharing revenue varies, and is determined by the owner of the material. The owner sets this in the same place as the revenue sharing (§8.3).`,
  },
  sv: {
    title: 'Wiki',
    titleInfoFeatures: 'Information om basfunktioner',
    titleHelp: 'Vad ska en säljare praktiskt kunna hjälpa kund med? ',
    heading1: '§1 Vad är VEO Partner?',
    heading2: '§2 Hur tjänar säljare pengar med hjälp av VEO Partner?',
    heading3: '§2.1 Sponsorer',
    heading38: '§2.1.1 Bjud in en sponsor',
    heading4: '§2.2 Annonsör',
    heading5: '§2.3 Ny innehållsägare',
    heading6: '§2.4 Ny säljare – Team Lead',
    heading7: '§2.5 Ny säljare – Group Lead',
    heading8: '§2.6 Säljteams-hierarki:',
    heading9: '§3 Skapa ny användare',
    heading10: '§3.1 Skapa ny användare',
    heading11: '§3.2 Skapa säljare',
    heading12: '§4 Analys & Resultat',
    heading13: '§5 Händelselogg',
    heading14: '§6 Hur loggar säljare in på ett nyligen skapat konto?',
    heading15: '§7 Supporter vs Följare',
    heading16: '§8 Ladda upp',
    heading17: '§8.1 Vad går att ladda upp?',
    heading18: '§8.2 Vad är max-pris?',
    heading19: '§8.3 Vad är intäktsfördelning (split profit)?',
    heading20: '§8.4 Geografiska rättigheter',
    heading21: '§9 Intäkter',
    heading22: '§9.1 Saldo',
    heading23: '§9.2 Utbetalning',
    heading24: '§9.3 Sponsorskap',
    heading25: '§9.4 Annonser [Kommande]',
    heading37: '§9.5 Delningsintäkt',
    heading26: '§10 Analys',
    heading27: '§11 Meddelanden',
    heading28: '§12 Aktivitet',
    heading29: '§13 Innehållshanterare',
    heading30: '§14 Donationer',
    heading31: '§15 Hjälpa kunden att komma igång med sin kanal',
    heading32: '§15.1 Laddar upp en profilbild',
    heading33: '§15.2 Laddar upp en omslagsbild',
    heading34: '§15.3 Lägger till en beskrivning (“Bio”)',
    heading35: '§15.4 Laddar upp innehåll',
    heading36: '§15.5 Driva trafik till kundens kanal — Dela externt',

    paragraphs1: `VEO Partner är utgångspunkten och ett viktigt hjälpmedel för en säljare i sitt dagliga arbete. Genom VEO Partner finns möjligheten att bjuda in kunder (innehållsägare) och ge dessa personer åtkomst till tjänsten. Det finns även möjligheten att bjuda in sponsorer som vill stötta innehållsägare och arbetet med VEO. Utöver att bjuda in kunder och sponsorer är det för en befintlig säljare möjligt att skapa nya säljare.`,
    paragraphs2: `Genom rekrytering av nya användare erhåller den rekryterande säljaren ersättningar i olika nivåer. Källan till ersättningen är alltid genom en skapad och genomförd affär i tjänsten. Detta innebär att alla i näringskedjan erhåller en ersättning.`,
    paragraphs3: `Attraherar du en sponsor erhåller du 10% på sponsorbeloppet, maximalt 100000 SEK per månad per sponsor.`,
    paragraphs38: `Skicka din personliga delningslänk till någon som vill stötta VEO genom att sponsra.

    När mottagaren klickar på länken kommer de till sponsringssidan på Veopartner. Där väljer de hur mycket de vill sponsra med och om de vill sponsra.
    
    Vidare får de skapa ett konto. Vid genomförd betalning kan de använda kontot för att logga in på Veopartner och i VEO-appen.
    
    Sponsorn kan följa sin återbetalningen i realtid både på Veopartner och i VEO-appen.`,
    paragraphs4: `Attraherar du en sponsor erhåller du 10% på sponsorbeloppet, maximalt 100000 SEK per månad per sponsor.`,
    paragraphs5: `En säljare som rekryterar en ny betalande användare till VEO erhåller 80% av säljprovisionen. Tillägg med 0.8kr per prenumerant*.`,
    paragraphs6: `En säljare som rekryterar en annan säljare som Team Lead** kommer att erhålla 10% av den nya säljarens förtjänster. Tillägg med 0.1kr per prenumerant*.`,
    paragraphs7: `En säljare som har två nivåer av säljare under sig kommer att erhålla 5% av Team Leads förtjänst. Tillägg med 0.05 per prenumerant*`,
    paragraphs8: `Group Lead
    – Team Lead
    – – Sales Representative
    
    Hierarkiskt kan en säljorganisation bli hur många led och hur djup som helst, detta baserat på att systemet är byggt på ett sätt där vem som helst kan skapa sin egen säljorganisation, trots att de redan ingår i en. 
    
    Däremot finns en begränsning i antal led ersättningen utdelas. En person som är upphovsperson till en organisation med ett djup längre än tre led, kommer fortfarande enbart erhålla ersättning från de tre första leden. 
    
    * Exakt belopp varierar beroende på skatter och moms i landet slutkund bor i samt hur stor prenumerationsavgiften är i det specifika landet. Säljarprovisionen justeras också gradvis så du får maximal säljarersättning i början (80%) och minskar linjärt nedåt ner till 100 000:e prenumerant som ger 10%. Detta för att så många säljare som möjligt så snabbt som möjligt ska kunna komma upp i hög ersättning i början. Exempel första prenumeranten ger 80% och andra prenumeranten ger 79.9992%  och så vidare ner till 10%. (ersättning % = 10 % + (70% * ((100000-antal) / 100000))). För att förhindra stagnering av sälj så är säljarersättningen tidsbegränsad till 3 år per prenumerant. Så du får ersättning från startdatum för prenumeranten och i 3 års tid framåt så länge prenumeranten fortsatt prenumerar. Allt överskott av säljersättningen går till säljbolaget och går till dem (inklusive dig själv) som äger aktier i säljbolaget. Totala säljersättningen per enskild säljare maximalt uppnå 250 000 SEK i månaden. Allt utöver detta går istället till säljbolaget.

** Du kan maximalt få ledarersättning för upp till totalt 100 st säljare rekryterade av dig och ditt närmsta team. Detta för att förhindra spam och missbruk. Det lönar sig alltså inte att bara länka till så många som möjligt, vare sig för dig eller de i ditt team. Du belönas för att endast länka till de som står dig närmast i ditt team och som du vet kan generera många slutkunder och prenumeranter.” förtydliga inbjudningslänkar till säljare”.`,
    paragraphs10: `För att bjuda in en kund och ge personen åtkomst till tjänsten behöver du först skapa en ny användare. Det gör du genom att fylla i kundens önskade kanalnamn samt personens e-postadress. Klicka sedan på knappen »Skapa ny användare«.

    Din kund kan när som helst byta både kanalnamn och e-postadress.`,
    paragraphs11: `För att skapa och aktivera en säljare anger du ett kanalnamn och en e-postadress för den person som är tänkt att aktiveras. Klicka sedan på knappen »Skapa ny säljare«.

    Du kan maximalt skapa 200 nya säljare.`,
    paragraphs12: `I Analys kan du följa resultatet av dina delade länkar och inbjudningar. Exempelvis ser du antal rekryterade användare och kanaler men även hur mycket du fått i ersättning för dina prestationer.

    Ansvarar du för ett säljteam kan du även följa resultaten från din grupp.
    `,
    paragraphs13: `Varje gång du delar en länk, skapar en ny användare eller aktiverar en säljare loggas detta i en händelselogg. Du kan enkelt se din eller säljteamets händelser genom att läsa av loggen. Här finns möjligheten att filtrera på enskilda händelser.`,
    paragraphs14: `När du som säljare har skapat ett nytt konto, kommer ett system-genererat lösenord att skickas ut till personen du har bjudit in. För att du i ditt ansvar som säljare ska kunna hjälpa den nya användaren, kan du logga in på det nyskapade kontot med ditt egna lösenord. Ditt lösenord kommer vara giltigt och möjligt att använda under en begränsad period eller tills det att den nya användaren väljer att byta lösenord. `,
    paragraphs15: `En kanal är samlingsplatsen för allt innehåll, och är unik för varje användare på VEO. Detta är således ditt ansikte ut mot alla användare på VEO. Andra användare kan följa din kanal, då kommer allt innehåll du laddar upp att synas i deras flöde. Om någon vill stötta dig extra, väljer personen dig som favoritkanal och blir då istället en supporter.

    Det är enbart möjligt att vara supporter till en kanal på VEO. Det som händer när du är supporter till en kanal är att 20% av den månatliga kostnaden för VEO kommer att gå till kanalen. Detta blir alltså ett bra sätt att visa får någon att du uppskattar deras kanal lite extra.
    `,
    paragraphs17: `VEO stödjer flera olika mediatyper. Användare har möjlighet att ladda upp och publicera inlägg (bild eller video), videos, musik, bilder, ljudböcker och podcasts. 

    Viktigt att komma ihåg är att det som laddas upp och publiceras på VEO inte bryter mot Användarvillkor (EULA)en och att den som laddar upp äger rättigheterna till innehållet. VEO tar inget upphovsrättsligt ansvar för vad som laddas upp. Detta är upp till personen själv, rättsliga påföljder kan bli aktuella vid brott mot upphovsrätt. 
    `,
    paragraphs18: `Max-pris är det belopp en användare maximalt kan få per innehåll och konsument. När innehåll visas eller spelas genereras en intäkt till innehållsägaren. Beloppet varierar och baseras på hur mycket innehåll den som tittat eller lyssnat redan konsumerat.

    Förenklat kan en säga att det månatliga beloppet för VEO portioneras ut i en pott som sträcker sig över en månad. Värdet av ett klick är sedan baserat på konsumtionstakten per dag under denna månad. Få klick på innehåll resulterar i ett högre värde per klick, och tvärtom. Max-priset är därmed ett mått på den maximala ersättningen som är möjlig att generera för ett klick. Summan du tjänar per klick kommer alltid att vara varierande, men du kommer alltid få en ersättning när någon konsumerar ditt innehåll.
    
    Intäkten för ditt innehåll redovisas i realtid och syns dagligen i dina intäkter.
    `,
    paragraphs19: `Fördela intäkter från innehåll till andra användare. Lägg till de användare du vill ge intäkter för ett visst innehåll. 

    Fördelning kommer att ske automatiskt direkt när ett innehåll genererar intäkter. 
    `,
    paragraphs20: `Om ett innehåll inte ska eller får visas i en viss geografisk region är det möjligt att begränsa detta under uppladdnings-processen. Ange vilken region innehållet inte ska visas i så kommer det inte finnas tillgängligt i den regionen.`,

    paragraphs22: `Ditt intjänade saldo visas i det som kallas intäkter. Beloppet är pengar genererade genom de intäktsmöjligheter som VEO erbjuder. Saldot som är synligt, kan från dag till dag variera. Anledningen till detta är att saldot som visas i användarens inhemska valuta är växlad mot ett flertal valutor i en samlad valutakorg. `,
    paragraphs23: `Du kan när som helst välja att göra ett uttag av dina intäkter hos VEO och överföra detta till ditt egna bankkonto. `,
    paragraphs24: `I ekosystemet av tjänster VEO tillhandahåller är VEO Partner en av dem. En av sakerna denna tjänst möjliggör är sponsorskap. Personer som genomför en sponsring, kommer att i realtid finna sin återbetalning under fliken »sponsorskap« i intäkter.`,
    paragraphs25: `Sponsorskap går även att utöka till att bli specifika för enskilda kanaler. Som tillägg finns möjligheten att som sponsor annonsera i dessa kanaler. Dina aktiva annonser, budget etc. går att återse under fliken »Annonser« i intäkter.`,
    paragraphs26: `Verktyget möjliggör för användare att se nyckeltal för det innehåll de väljer att ladda upp. Det går att se: intäkt, intäktsfördelning,  interaktioner (visningar, gilla-markeringar, kommentarer, delningar, repost & donationer). Det går även att se visningshistorik.`,
    paragraphs27: `Meddelanden samlar all kommunikation mellan användare i VEO. Det är möjligt att skriva meddelanden till andra men även att dela innehåll med hjälp av meddelanden.`,
    paragraphs28: `Alla händelser och interaktioner med din kanal eller ditt innehåll visas som en notis. Dessa notiser samlas under »Aktivitet«.`,
    paragraphs29: `Möjliggör för en kanal att ge en annan kanal tillåtelse att ladda upp innehåll i sin egen kanal. Detta kan vara lämpligt när exempelvis en större organisation, vill ge en person ansvar för innehållet, men inte tillgång till hela kontot. I detta scenario ger organisationen personen tillåtelse att ladda upp genom att bli innehållshanterare.`,
    paragraphs30: `Som användare kan du när som helst genomföra en donation till en annan användare. För att genomföra en donation går du till personen du avser ge en donations kanal. Där trycker du på ikonen som ser ut som ett paket, för att sedan bli vidarebefordrad till ett nytt fönster. Här väljer du belopp och om donationen ska vara löpande eller ske vid ett enskilt tillfälle. `,
    paragraphs31: `Säljare ansvarar alltid för kunden och har skyldighet att hjälpa kunden att komma igång med sin kanal. Arbetet innefattar bland annat att se till att kunden `,
    paragraphs32: `Detta görs genom att besöka profilen och klicka på profilbilden. Där kommer det finnas två val, byta omslagsbild eller profilbild.`,
    paragraphs33: `Se ovan`,
    paragraphs34: `Detta görs genom att besöka profilen och klicka på knappen »Redigera profil« Där finns möjligheten att lägga till beskrivande text, samt övrig information.`,
    paragraphs35: `Du klickar först på ikonen längst ner till höger (i navigationen i botten). Inne på kontosidan klickar du på första alternativ under saldot, knappen: »Ladda upp«. Därefter kommer du att få ett val för vilken typ av innehåll du vill ladda upp.

    Utöver att hjälpa kunden att komma i gång med sin kanal ska säljaren även finnas tillgänglig att svara på frågor och stötta kunden i sitt användande av VEO-tjänsten.
    `,
    paragraphs36: `Säljare ska informera kunden hur denna ska gå tillväga för att driva trafik till sin kanal och därmed börja attrahera både prenumeranter och följare. För att driva trafik till kanalen ska kunden dela och marknadsföra innehåll (alt. profilen) på sina redan befintliga plattformar och kanaler. `,
    paragraphs37: `VEO erbjuder en ersättning när du delar material som du själv inte är ägare av. En delning av ett material har likt traditionell digital annonsering avsikten att sprida och generera mer visningar av ett innehåll. I VEO är visningar och lyssningar värt pengar, och därmed skapar du som delar något en intäkt till en innehållsägaren vars material du delat. Som tack för detta får även du som delar något, en delningsintäkt.
    
    Vi brukar likna detta vid exempelvis köpt annonsering, men istället för att en av jättarna tjänar pengar på att generera mer trafik. Låter vi våra användare göra samma sak. 
    
    Delningsintäkten varierar, och bestäms av ägaren av materialet. Detta ställer ägaren in på samma ställe som delningsintäkten (§8.3).`,
  },
});
