import React, { useEffect, useState } from 'react';
import LocalizedStrings from 'react-localization';
import {
  StyleSheet,
  FlatList,
  View,
  Text,
} from 'react-native';
import { useLazyQuery } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { PROFILE_SEARCH } from '../../gql/gqls';
import WideButton from '../../components/misc/WideButton';
import { myAccountVar } from '../../reactiveVariables';
import SearchBar from '../../components/SearchBar';
import Avatar from '../../components/Avatar';
import SupportVeo from '../../components/SupportVeo';
import Roi from '../../components/Roi';
import Fetching from '../../components/Fetching';

import {
  colors,
  fonts,
  gutters,
  mainTitle,
  mediaQueries,
  screenStyles,
  useMobileSize,
  commonStyles,
} from '../../theme';

export default function DiscoverScreen() {
  const isMobile = useMobileSize();
  const history = useHistory();
  const { isSmallScreen } = useWindowDimensions();
  const user = useReactiveVar(myAccountVar);

  const [searchProfiles, { data, loading, error }] = useLazyQuery(PROFILE_SEARCH, {
    onError: (err) => console.error(err),
  });

  const [input, setInput] = useState('');

  useEffect(() => {
    let timer = null;
    if (input.length === 0) {
      searchProfiles({
        variables: {
          search: input,
          type: 'dist',
          notSelf: true,
        }
      });
    } else {
      timer = setTimeout(() => {
        searchProfiles({
          variables: {
            search: input,
            type: 'dist',
            notSelf: true,
          }
        });
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [input, searchProfiles]);

  return (
    <View style={screenStyles.main}>

      <FlatList
        style={{
          paddingHorizontal: gutters.pageGutter,

        }}
        data={(data ? data.profileSearch : [])}
        ListHeaderComponent={
          <View style={{
            maxWidth: mediaQueries.tablet,
            width: '100%',
          }}>
            {/* <Text style={mainTitle(isMobile)}>
              {strings.welcomeBack}
              {user.name}
            </Text> */}
            <View style={{
              padding: 40,
              backgroundColor: '#F4F5F9',
              marginTop: 10,
              borderRadius: 8
            }}>
              <Text style={{
                fontFamily: fonts.regular,
                fontSize: 32,
                marginBottom: 10,
              }}>
                {strings.discover}
              </Text>
              <Text style={styles.infoText}>
                {strings.discoverInfo}
              </Text>
              <SearchBar input={input} setInput={setInput} /></View>


          </View>
        }
        renderItem={({ item }) => (
          <ListItem item={item} isMobile={isMobile} history={history} />
        )}
        keyExtractor={(item) => item.id}
        ListEmptyComponent={<Fetching error={error} loading={loading} />}
        ListFooterComponent={<View style={{ height: 20 }} />}
      />
      <SupportVeo isMobile={isMobile} history={history} style={{ zIndex: 9999 }} />
    </View>
  );
};

const ListItem = ({ item, isMobile, history }) => <View style={{
  padding: 20,
  borderWidth: 1,
  borderColor: colors.greySolo,
  borderRadius: 4,
  flexDirection: isMobile ? 'row' : 'column',
  marginTop: 20
}}>
  {isMobile && <Avatar image={item.smlImage} size={40} extraStyles={{ marginRight: 15 }} />}
  <View style={{
    width: '100%',
    flexShrink: 1
  }}>
    <View style={{
      flexDirection: isMobile ? 'column' : 'row',
      width: '100%',
      alignItems: isMobile ? 'flex-start' : 'center',
      flexShrink: 1
    }}>
      <View style={{
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        height: 40,
      }}>

        {!isMobile && <Avatar image={item.smlImage} size={40} extraStyles={{ marginRight: 10, borderWidth: 1, borderColor: colors.greySolo }} />}
        <Text style={{
          fontSize: fonts.regular,

          fontFamily: fonts.medium
        }}>
          {item.name}
        </Text>

        <View style={{
          flexDirection: isMobile ? 'row' : 'row',
          flexGrow: 1,
        }}>
        </View>

        <WideButton
          onPress={() => history.push(`/discover/${item.id}`)}
          text={strings.supportUs}
          textStyle={{ fontFamily: fonts.medium, fontSize: 14, }}
          unfilled
          buttonStyle={{
            flexShrink: 1,
            flexGrow: 0,
            borderColor: colors.deepBlue,
            width: 'auto',
            paddingHorizontal: 20,
            height: 32,
            borderRadius: 4,
          }}
        />
        {/* {!isMobile && <Roi extraStyles={{
          marginLeft: 10,
          marginRight: 0
        }} />} */}
      </View>
      {/* {isMobile && <Roi extraStyles={{ marginVertical: 10 }} />} */}

    </View>



    {/* <View style={{
      flexDirection: 'row',
      alignItems: 'center',
    }}>
      <Text style={{
        marginTop: isMobile ? 0 : 10,
        fontFamily: fonts.regular,
        fontSize: 16,
      }}>
        {item.sponsored ? item.sponsored : 0}
      </Text>
      <Text style={{
        alignSelf: 'flex-end',
        marginLeft: 5,
        fontSize: 12,
        color: colors.greyLeia,
        fontFamily: fonts.regular,
      }}>
        {strings.sponsored}
      </Text>
    </View> */}
    <View style={{
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginVertical: 20
    }}>
      <View style={{
        justifyContent: 'space-between',
        flexDirection: isMobile ? 'column' : 'row',
        flexGrow: 1,
        alignItems: isMobile ? 'flex-start' : 'center',
      }}>
        <Stat
          label={strings.sponsored}
          value={item.sponsored ? item.sponsored : 0}
          extraStyles={{ marginTop: isMobile ? 20 : 0 }}
        />
        {!isMobile && <Bord extraStyles={{ height: isMobile ? 0 : 1 }} />}
        <Stat
          label={strings.followers}
          value={item.followers}
          extraStyles={{ marginTop: isMobile ? 20 : 0 }}
        />
        {!isMobile && <Bord extraStyles={{ height: isMobile ? 0 : 1 }} />}
        <Stat
          label={strings.uploads}
          value={item.uploads}
          extraStyles={{ marginTop: isMobile ? 20 : 0 }}
        />
        {!isMobile && <Bord extraStyles={{ height: isMobile ? 0 : 1 }} />}
        <Stat
          label={strings.subscribers}
          value={item.supporters}
          extraStyles={{ marginTop: isMobile ? 20 : 0 }}
        />
        {!isMobile && <Bord extraStyles={{ height: isMobile ? 0 : 1 }} />}
        <Stat
          label={strings.uniqueVisitors}
          value={item.uniqueVisitors ? item.uniqueVisitors : 0}
          extraStyles={{ marginTop: isMobile ? 20 : 0 }}
        />
      </View>
    </View>
    {/* <WideButton
      onPress={() => history.push(`/discover/${item.id}`)}
      text={strings.supportUs}
      unfilled
      buttonStyle={{
        flexShrink: 1,
        flexGrow: 0,
        borderColor: colors.greyLuke,
      }}
    /> */}
  </View>
</View>;

const Stat = ({ label, value, extraStyles }) =>
  <View
    style={{
    }}
  >
    <Text style={{
      color: colors.greyLeia,
      fontSize: 12,
      fontFamily: fonts.regular
    }}>
      {label}
    </Text>
    <Text style={{
      color: colors.black,
      fontSize: 24,
      fontFamily: fonts.mono,
    }}>
      {value ? value : 0}
    </Text>
  </View>;

const Bord = ({ extraStyles, isMobile }) =>
  <View
    style={{
      backgroundColor: colors.greySolo,
      width: 1,
      height: '100%'
    }}>
  </View>;


const styles = StyleSheet.create({
  infoText: {
    fontSize: 16,
    fontFamily: fonts.regular,
    color: colors.black,
    marginBottom: 20
  },
  boldText: {
    fontFamily: fonts.medium,
    color: colors.black,
    marginBottom: 10,
    fontSize: 14
  }
});

const strings = new LocalizedStrings({
  en: {
    welcomeBack: 'Welcome back, ',
    supportUs: 'Support',
    uniqueVisitors: 'Unique visitors / day',
    discover: 'Discover',
    sponsored: 'Sponsored',
    followers: 'Followers',
    subscribers: 'Supporters',
    uploads: 'Uploads',
    cpr: 'Cost Per Click',
    discoverInfo:
      'Discover artists, clubs, organizations and other Content Owners who have chosen to use VEO. Find your favorite and support their work. Missing someone? Send an Invite.',
  },
  sv: {
    welcomeBack: 'Välkommen tillbaka, ',
    supportUs: 'Stötta',
    discover: 'Plattformen för dig som vill sponsra & stötta innehållsägare.',
    uniqueVisitors: 'Unika besökare / dag',
    followers: 'Följare',
    subscribers: 'Supportrar',
    uploads: 'Uppladdningar',
    cpr: 'Kostnad per klick',
    sponsored: 'Sponsrat',
    discoverInfo:
      'Här hittar du artister, organisationer, vänner och alla andra som valt att använda VEO. Hitta din favorit eller nästa samarbetspartner och stöd deras arbete genom att sponsra.',
  }
});
