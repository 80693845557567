import React, { useState, useRef } from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import LocalizedStrings from 'react-localization';

import { myAccountVar } from '../../../src/reactiveVariables';
import { SIGNIN_MUTATION } from '../../gql/authMutations';
import WideButton from '../../components/misc/WideButton';
import {
  fonts,
  colors,
} from '../../theme';

export default function SignIn() {
  const location = useLocation();
  const history = useHistory();

  const sponsor = location.pathname.includes('sponsor');
  const festival = location.pathname.includes('festival');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  const [login, { loading, error }] = useMutation(SIGNIN_MUTATION, {
    onCompleted: (data) => {
      document.cookie = `userToken=${data.login.token};max-age=31536000;path=/;${process.env.REACT_APP_ENV !== 'development' && 'secure;'}`;
      myAccountVar(data.login);
      if (sponsor) {
        history.replace('/sponsor/payment');
      } 
      else if (festival) {
        history.replace('/festival/payment');
      } else {
        history.push('/start');
      }
    },
    onError: () => {
      alert(strings.incorrectCredentials);
    },
  });

  const handleLogin = () => {
    login({
      variables: {
        name,
        password,
        deviceId: 'partner',
        deviceName: navigator.userAgent,
        country: null,
        language: (navigator.language || navigator.userLanguage).split('-')[0],
      },
    });
  };

  return (
    <View
      style={{
        backgroundColor: colors.white,
        paddingHorizontal: 40,
        borderRadius: 8,
        boxShadow: '3px 2px 20px -3px rgba(0, 0, 0, 0.09)',
      }}
    >
      <Text style={styles.header}>{sponsor ? strings.loginSponsor : strings.login}</Text>
      <Text style={styles.label}>{strings.username}</Text>
      <TextInput
        textContentType="username"
        style={styles.input}
        placeholder={strings.username}
        value={name}
        onChangeText={(input) => {
          input !== '' && (input.match(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g) || input.match(/[<>%;$\\\/#^=.,"'+*?@&!(){|}=[\]]+$/)) ?
            alert(strings.illegalCharacter) && setName(input.substr(0, input.length - 1)) : setName(input)
        }}
      />
      <Text style={styles.label}>{strings.password}</Text>
      <TextInput
        textContentType="password"
        style={styles.input}
        placeholder={strings.password}
        value={password}
        secureTextEntry={true}
        autoCapitalize={'none'}
        onSubmitEditing={handleLogin}
        onChangeText={(input) => {
          setPassword(input);
        }}
      />
      <WideButton
        buttonStyle={styles.buttonStyle}
        loading={loading}
        disabled={loading || name.length < 2 || password.length < 8}
        onPress={handleLogin}
        text={strings.login}
      />
      {false && <TouchableOpacity
        style={{
          marginVertical: 20
        }}
        onPress={() => {
          if (sponsor) {
            history.push('/sponsor/signup');
          }
          else if (festival) {
              history.push('/festival/signup');
          } else {
            history.push('/signup');
          }
        }}
      >
        <Text style={styles.createAccountText}>
          {strings.createAccountText}{' '}
          <Text style={styles.createAccountBold}>
            {strings.createAccountBold}
          </Text>
        </Text>
      </TouchableOpacity>}
      <TouchableOpacity
        style={{
          marginBottom: 45
        }}
        onPress={() => {
          history.push('/forgotPassword');
        }}
      >
        <Text style={styles.createAccountText}>
          {strings.forgot}
        </Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    marginTop: 40,
    marginBottom: 13,
    fontFamily: fonts.regular,
    fontSize: 32,
    lineHeight: 25,
    color: colors.black,
  },
  label: {
    fontFamily: fonts.regular,
    fontSize: 14,
    marginBottom: 5,
    lineHeight: 12,
    color: colors.black,
    marginTop: 21,
  },
  input: {
    marginTop: 5,
    borderRadius: 8,
    backgroundColor: colors.greySolo,
    minHeight: 56,
    paddingHorizontal: 10,
    fontFamily: fonts.regular,
    fontSize: 16,
    color: colors.black,
  },
  buttonStyle: {
    marginTop: 20,
    marginBottom: 20,
  },
  createAccountText: {
    fontFamily: fonts.regular,
    color: colors.greyLeia,
    fontSize: 14,
    lineHeight: 17.5,
    textAlign: 'left',
  },
  createAccountBold: {
    fontFamily: fonts.regular,
    color: colors.black,
  },
});

const strings = new LocalizedStrings({
  en: {
    username: 'Channel name',
    password: 'Password',
    login: 'Sign in',
    loginSponsor: 'Sign in and Sponsor',
    createAccount: 'Sign up',
    createAccountText: "Don't have a VEO account?",
    createAccountBold: 'Create an account here for free.',
    incorrectCredentials: 'Wrong channel name or password',
    illegalCharacter: 'Only alphanumeric characters are allowed.',
    forgot: 'Forgot password?'
  },
  sv: {
    username: 'Kanalnamn',
    password: 'Lösenord',
    login: 'Logga in',
    loginSponsor: 'Logga in och sponsra',
    createAccount: 'Skapa konto',
    createAccountText: `Har du inte ett VEO konto?`,
    createAccountBold: 'Skapa ett konto här gratis',
    incorrectCredentials: 'Fel kanalnamn eller lösenord',
    illegalCharacter: 'Endast alphanumeriska tecken är tillåtna.',
    forgot: 'Glömt lösenord?'
  },
});
