import {
  makeVar,
} from '@apollo/client';

export const myAccountVar = makeVar(null);
export const festivalAmountVar = makeVar(500);
export const sponsorVar = makeVar({
  sponsorshipVisible: true,
  recurrent: false,
  VAT: '',
  currency: '',
  amount: '',
  minSponsor: '',
  maxSponsor: ''
});
export const shareLinkVar = makeVar({
  shortId: null,
  isSeller: false
});

export const consentCookieVar = makeVar(null);

export const resetReactiveVars = () => {
  myAccountVar(null);
  sponsorVar({
    sponsorshipVisible: true,
    recurrent: false,
    VAT: '',
    currency: '',
    amount: '',
    minSponsor: '',
    maxSponsor: ''
  });
  shareLinkVar({
    shortId: null,
    isSeller: false
  });
};
