import React from 'react';
import LocalizedStrings from 'react-localization';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { CheckBox } from 'react-native-web';

import {
  fonts,
  colors,
} from '../../theme';

export default function RecurrentCheckbox({
  recurrent,
  onChange,
  customText,
  fontSize = 10,
  marginTop = 23,
  marginBottom,
}) {
  return (
    <View style={[styles.checkBoxRow, {
      marginTop,
      marginBottom
    }]}>
      <CheckBox
        style={styles.checkBox}
        value={recurrent}
        onValueChange={onChange}
        color={colors.goGreen}
      />
      {customText ? (
        <Text style={[styles.reccurrentText, { fontSize }]}>{customText}</Text>
      ) : (
        <Text style={[styles.reccurrentText, { fontSize }]}>
          {strings.reccurrentText}
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  checkBoxRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 23,
  },
  checkbox: {
    height: 24,
    width: 24
  },
  reccurrentText: {
    marginLeft: 10,
    color: colors.black,
    fontFamily: fonts.regular,
    fontSize: 14,
  }
});

const strings = new LocalizedStrings({
  en: {
    reccurrentText: 'Recurrent montly payment',
  },
  sv: {
    reccurrentText: 'Återkommande månadsbetalning',
  },
});
