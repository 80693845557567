import React from 'react';
import LocalizedStrings from 'react-localization';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { CheckBox } from 'react-native-web';

import {
  fonts,
  colors,
} from '../../theme';

export default function TermsCheckbox({
  termsAccepted,
  onChange,
  customText,
  fontSize = 10,
  marginTop = 23,
  marginBottom,
}) {
  return (
    <View style={[styles.checkBoxRow, {
      marginTop,
      marginBottom
    }]}>
      <CheckBox
        style={styles.checkBox}
        value={termsAccepted}
        onValueChange={onChange}
        color={colors.goGreen}
      />
      {customText ? (
        <Text style={[styles.acceptTermsText, { fontSize }]}>{customText}</Text>
      ) : (
        <Text style={styles.acceptTermsText}>
          {strings.acceptTermsText}{' '}
          <TouchableOpacity
            onPress={() => {
              window.open('https://veoworld.org/org/termsofuse.html', '_blank');
            }}
          >
            <Text style={styles.termsLink}>{strings.termsLink}</Text>
          </TouchableOpacity>
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  checkBoxRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',

    marginTop: 23,
  },
  checkbox: {
    height: 24,
    width: 24
  },
  acceptTermsText: {
    marginLeft: 10,
    color: colors.black,
    fontFamily: fonts.regular,
    fontSize: 14,
  },
  termsLink: {
    textDecorationLine: 'underline',
    color: colors.deepBlue
  },
});

const strings = new LocalizedStrings({
  en: {
    acceptTermsText: 'I accept the',
    termsLink: 'VEO terms and conditions',
  },
  sv: {
    acceptTermsText: 'Jag accepterar',
    termsLink: 'VEOs villkor',
  },
});
