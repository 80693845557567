const config = {
  baseUrl:  process.env.REACT_APP_ENV === 'prod' ? 'https://veoworld.org'
      : process.env.REACT_APP_ENV === 'stage' ? 'https://stage.veoworld.org'
      : 'http://172.17.0.1:3000',
  foundationUrl: 'https://veofoundation.org',
  vgmUrl: 'https://veogm.org',
  showDiscover: true
}

export default config;
