import React from 'react';
import {
  View,
  Text,
  Image,
} from 'react-native';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import LocalizedStrings from 'react-localization';

import { colors, fonts } from '../../theme';

import linkIcon from '../../assets/icons/link.png';
import clipBoardIcon from '../../assets/icons/copy.png';

const CopyLink = ({ link, text }) => <CopyToClipboard text={link}
  onCopy={() => global.modalNotice(strings.copied)}>
  <View
    style={{
      cursor: 'pointer',
      backgroundColor: colors.lightBlue,
      flexDirection: 'row',
      paddingVertical: 10,
      alignItems: 'center',
      paddingHorizontal: 20,
      paddingVertical: 20,
      width: '100%',
      borderRadius: 8
    }}
  >
    <Image
      source={linkIcon}
      style={{
        height: 24,
        width: 24,
        marginRight: 10
      }}
    />
    <Text
      numberOfLines={1}
      style={{
        color: colors.deepBlue,
        fontSize: 16,
        fontFamily: fonts.regular,
        marginRight: 10
      }}>
      {text}
    </Text>
    <Image
      source={clipBoardIcon}
      style={{
        height: 24,
        width: 24,
        marginLeft: 'auto',
        marginRight: 0,
      }}
    />
  </View>
</CopyToClipboard>

export default CopyLink;

const strings = new LocalizedStrings({
  en: {
    copied: 'Copied to clipboard.'
  },
  sv: {
    copied: 'Kopierat till urklipp'
  }
});
