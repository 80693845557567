export const sponsorTypes = {
  GENERIC: 'sponsorGeneric',
  SPECIFIC: 'sponsorChannel',
  AD: 'sponsorAd',
};

export const targets = {
  SUBSCRIBERS: 'subscribers',
  SUBSCRIBERS_FOLLOWERS: 'subscribersFollowers',
  EVERYONE: 'everyone',
};
