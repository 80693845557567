import React, { useState } from 'react';
import LocalizedStrings from 'react-localization';
import {
StyleSheet,
View,
Text,
TouchableOpacity,
ScrollView,
} from 'react-native';
import { useReactiveVar, useMutation } from '@apollo/client';
import { myAccountVar } from '../../reactiveVariables';
import { commonStyles, colors, mediaQueries, gutters, screenStyles, useMobileSize, fontsize, fonts } from '../../theme';
import { useHistory } from 'react-router-dom';
import { SELLER_ACTIVATED } from '../../gql/gqls';

const Quiz = () => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [quizFinished, setQuizFinished] = useState(false);
    const [incorrectAnswers, setIncorrectAnswers] = useState([]);
    const questions = strings.questions;
    const progressPercentage = ((currentQuestionIndex / questions.length) * 100).toFixed(0);
    const [userSelections, setUserSelections] = useState(Array(questions.length).fill(null));
    const isOptionSelected = userSelections[currentQuestionIndex] !== null;
    const history = useHistory();
    
    const [setSellerActivated, { loading, error }] = useMutation(SELLER_ACTIVATED, {
        onCompleted: () => {},
        onError: (err) => {
          alert(err);
        },
      });

    const checkAllAnswersCorrect = () => {
        return questions.every((question, index) => 
            question.correctAnswer === userSelections[index]);
    };

    const getOptionPrefix = (index) => {
        const prefixes = ['A', 'B', 'C'];
        return prefixes[index];
    };

    const goToNextQuestion = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            if (checkAllAnswersCorrect()) {
                myAccountVar({ ...myAccountVar(), isSellerActivated: true });
                setSellerActivated();
                history.replace('/partner/start');
            }
            setQuizFinished(true);
        }
    };

    const goToPreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const currentQuestion = questions[currentQuestionIndex];

    const checkAnswer = (answer) => {
        const updatedSelections = [...userSelections];
        updatedSelections[currentQuestionIndex] = answer;
        setUserSelections(updatedSelections);

        if (answer !== currentQuestion.correctAnswer) {
            setIncorrectAnswers([...incorrectAnswers, currentQuestionIndex]);
        }
    };

    const restartQuiz = () => {
        setCurrentQuestionIndex(0);
        setIncorrectAnswers([]);
        setUserSelections(Array(questions.length).fill(null));
        setQuizFinished(false);
    };

    return (
        <View>

            {!quizFinished && (

                <View style={styles.progressContainer}>
                    <Text style={[commonStyles.h4, { marginBottom: gutters.spacing[2] }]}>{strings.title}</Text>
                    <View style={{flexDirection: 'row', justifyContent:'space-between', alignItems: 'center', marginBottom: gutters.spacing[2]}}>
                        <Text style={styles.progressText}>{progressPercentage}% {strings.complete}</Text>
                    </View>
                    <View style={styles.progressBarContainer}>
                        <View style={[styles.progressBar, { width: `${progressPercentage}%` }]} />
                    </View>
                </View>

            )}

            {quizFinished ? (

                <View>

                    {incorrectAnswers.length > 0 ? (

                        <View>
                            <Text style={commonStyles.h2}>{strings.result}</Text>
                            <Text style={styles.result}>{strings.wrong}</Text>
                            <View style={styles.wrongAnswerContainer}>

                            {incorrectAnswers.map(index => (

                                <Text 
                                style={styles.buttonTextList}
                                key={index}>
                                    {index + 1}. {questions[index].text}
                                </Text>

                            ))}

                            </View>
                            <TouchableOpacity 
                                style={styles.wikiButton} 
                                onPress={() => window.open('/partner/wiki', '_blank')}>
                                <Text style={styles.restartButtonText}>{strings.wiki}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity 
                                style={styles.restartButton} 
                                onPress={restartQuiz}>
                                <Text style={styles.restartButtonText}>{strings.restart}</Text>
                            </TouchableOpacity>
                        </View>

                    ) : (

                        <View style={styles.positiveResult}>
                            <Text style={styles.resultPositive}>{strings.congratulations}</Text>
                        </View>
                    )}
                </View>

            ) : (

                <View>
                    <Text style={[commonStyles.h2, { marginBottom: gutters.spacing[4] }]}>{strings.quest} {currentQuestionIndex + 1}: {currentQuestion.text}</Text>
                    
                    {currentQuestion.options.map((option, index) => (

                        <TouchableOpacity 
                            key={index} 
                            style={[
                                styles.button, 
                                userSelections[currentQuestionIndex] === option && styles.selectedOption
                            ]} 
                            onPress={() => checkAnswer(option)}
                        >
                            <Text style={[
                                commonStyles.h4, 
                                userSelections[currentQuestionIndex] === option && styles.selectedText
                            ]}>
                                <Text style={styles.labelPrefix}>{getOptionPrefix(index)}</Text>{` ${option}`}
                            </Text>
                        </TouchableOpacity>

                    ))}

                        </View>

                    )}

                    {!quizFinished && (

                        <View style={styles.navigationContainer}>

                        {currentQuestionIndex > 0 && (

                            <TouchableOpacity 
                                style={styles.navButton} 
                                onPress={goToPreviousQuestion}
                            >
                                <Text style={styles.navButtonText}>{strings.previous}</Text>
                            </TouchableOpacity>

                        )}

                            <TouchableOpacity 
                            style={[
                                styles.navButtonNext, 
                                !isOptionSelected ? styles.disabledButton : null
                            ]} 
                            onPress={goToNextQuestion}
                            disabled={!isOptionSelected}
                            >
                                <Text style={styles.navButtonText}>{strings.next}</Text>
                            </TouchableOpacity>
                        </View>

                    )}      
        </View>
    );
};

export default function QuizScreen() {
    const isMobile = useMobileSize();
    const myAccount = myAccountVar();
    const user = useReactiveVar(myAccountVar);
    

return (
    <View style={screenStyles.main}>
        <ScrollView
            scrollIndicatorInsets={{ right: 1 }}
            style={{
                maxWidth: mediaQueries.tablet,
                width: '100%',
                marginBottom: gutters.spacing[9],
                paddingHorizontal: isMobile ? gutters.pageGutter : 0,
                marginTop: gutters.spacing[5],
            }}>
            <Quiz />
        </ScrollView>
    </View>
    );
};

const styles = StyleSheet.create({
    quizContainer: {
        padding: gutters.spacing[4],
        backgroundColor: colors.lightBlue,
        borderRadius: gutters.spacing[1],
    },
    positiveResult: {
        backgroundColor: '#E9F8F1',
        padding: gutters.spacing[6],
        alignItems: 'center',
        verticalAlign: 'center',
        marginTop: gutters.spacing[2],
        borderRadius: gutters.spacing[1],
    },
    wrongAnswerContainer: {
        padding: gutters.spacing[4],
        backgroundColor: colors.lightBlue,
        borderRadius: gutters.spacing[1],
    },
    progressContainer: {
        padding: gutters.spacing[4],
        backgroundColor: colors.lightBlue,
        marginBottom: gutters.spacing[3],
        borderRadius: gutters.spacing[1],

    },
    progressBarContainer: {
        width: '100%',
        height: gutters.spacing[1],
        backgroundColor: colors.white,
        borderRadius: gutters.spacing[1],
        borderWidth: 1,
        borderColor: colors.greySolo    
    },
    progressBar: {
        height: '100%',
        backgroundColor: colors.goGreen,
        borderRadius: gutters.spacing[0],
    },
    progressText: {
        alignSelf: 'flex-end',
        fontFamily: fonts.mono,
        fontSize: fontsize.xsmall,
        paddingBottom: gutters.spacing[1],
        color: colors.blueGreyDark
    },
    button: {
        alignItems: 'center',
        verticalAlign: 'center',
        padding: gutters.spacing[2],
        flexDirection: 'row',
        width: '100%',
        backgroundColor: colors.greyBoba,
        borderRadius: gutters.spacing[0],
        marginBottom: gutters.spacing[1],
        borderColor: colors.greySolo,
        borderWidth: 1,
    },
    navigationContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: gutters.spacing[3],
        gap: gutters.spacing[3],
    },
    navButton: {
        borderWidth: 1,
        borderColor: colors.greyLuke,
        borderRadius: gutters.spacing[1],
        padding: gutters.spacing[2],
        flexGrow: 1,
        
    },
    navButtonNext: {
        fontFamily: fonts.regular,
        fontSize: fontsize.small,
        borderWidth: 1,
        borderColor: '#E9F8F1',
        borderRadius: gutters.spacing[1],
        padding: gutters.spacing[2],
        alignSelf: 'flex-end',
        flexGrow: 1,
        backgroundColor: colors.goGreen,
    },
    navButtonText: {
        color: colors.black,
        fontSize: fontsize.medium,
        fontFamily: fonts.medium,
        textAlign: 'center',
    },
    label: {
        fontFamily: fonts.mono,
        fontSize: fontsize.xsmall,
        padding: gutters.spacing[1],
        borderRadius: gutters.spacing[1],
        backgroundColor: colors.blueGreyDark,
        alignSelf: 'flex-start',
        color: colors.white,
    },
    labelPrefix: {
        fontFamily: fonts.mono,
        fontSize: fontsize.small,
        padding: gutters.spacing[0],
        paddingLeft: gutters.spacing[1],
        paddingRight: gutters.spacing[1],
        borderRadius: gutters.spacing[2],
        backgroundColor: colors.white,
        color: colors.black,
        marginRight: gutters.spacing[1],
    },
    question: {
        fontFamily: fonts.medium,
        fontSize: fontsize.xlarge,
        marginBottom: gutters.spacing[4]
    },
    buttonText: {
        color: colors.black,
        fontSize: fontsize.medium,
        fontFamily: fonts.regular,
    },
    buttonTextList: {
        color: colors.black,
        fontSize: fontsize.medium,
        fontFamily: fonts.regular,
        marginBottom: gutters.spacing[2],
    },
    selectedText: {
        color: colors.white,
        fontSize: fontsize.large,
    },
    result: {
        fontSize: fontsize.large,
        fontFamily: fonts.medium,
        color: colors.black,
        marginBottom: gutters.spacing[3],
    },
    resultPositive: {
        fontSize: fontsize.xlarge,
        fontFamily: fonts.regular,
        color: colors.black,
    },
    selectedOption: {
        backgroundColor: colors.black,
        color: colors.white
    },
    restartButton: {
        alignItems: 'center',
        marginTop: gutters.spacing[3],
        padding: gutters.spacing[2],
        width: '100%',
        backgroundColor: colors.redWarning,
        borderRadius: gutters.spacing[0],
    },
    wikiButton: {
        alignItems: 'center',
        marginTop: gutters.spacing[3],
        padding: gutters.spacing[2],
        width: '100%',
        backgroundColor: colors.deepBlue,
        borderRadius: gutters.spacing[0],
    },
    restartButtonText: {
        color: colors.white,
        textAlign: 'center',
        fontFamily: fonts.medium
    },
    disabledButton: {
        backgroundColor: colors.greyLuke,
        opacity: 0.3
    },
});

const strings = new LocalizedStrings({
    en: {
        questions: [
            { 
                text: 'What is VEO Partner?',
                options: [
                    'A platform for sharing content and generating revenue.',
                    'A social media platform for sharing videos.',
                    'A tool for sellers to invite customers and sponsors.',
                ],
                correctAnswer: 
                    'A tool for sellers to invite customers and sponsors.'
            },
            { 
                text: 'How do sellers make money with VEO Partner?',
                options: [
                    'By selling advertising space on the platform.',
                    'By offering paid consulting services to users.',
                    'By recruiting new users and receiving compensation based on how much has been sold.',
                ],
                correctAnswer: 
                    'By recruiting new users and receiving compensation based on how much has been sold.'
            },
            { 
                text: 'How does sponsorship work through VEO Partner?',
                options: [
                    'By sellers receiving a fixed percentage of all sponsored amounts.',
                    'By sellers using a personal sharing link to sponsor.',
                    'By sellers paying a fixed monthly fee for sponsorship opportunities.'
                ],
                correctAnswer: 
                    'By sellers using a personal sharing link to sponsor.'
            },
            { 
                text: 'How do you create a new user in VEO Partner?',
                options: [
                    'By entering the desired channel name and email address and clicking "Create new user".',
                    'By sending an invitation link to the person to become a user.',
                    'By creating an account on VEO and then inviting other users.'
                ],
                correctAnswer: 
                    'By entering the desired channel name and email address and clicking "Create new user".'
            },
            { 
                text: 'What is the function of "Events" in VEO Partner?',
                options: [
                    'To share content with other users.',
                    'To show all events and interactions with one’s shared links.',
                    'To play games with other users on the platform.'
                ],
                correctAnswer: 
                    'To show all events and interactions with one’s shared links.'
            },
            { 
                text: 'What does it mean to be a "Supporter" of a channel on VEO?',
                options: [
                    'To contribute to the channel’s financial revenues through a part of the monthly fee.',
                    'To get paid for displaying ads in the channel.',
                    'To get exclusive access to premium content on the channel.'
                ],
                correctAnswer: 
                    'To contribute to the channel’s financial revenues through a part of the monthly fee.'
            },
            { 
                text: 'How do you upload content on VEO?',
                options: [
                    'By sending the files via email to VEO’s support.',
                    'By clicking "Upload" and choosing the desired file type and then uploading it.',
                    'By copying and pasting the content directly on the platform.'
                ],
                correctAnswer: 
                    'By clicking "Upload" and choosing the desired file type and then uploading it.'
            },
            { 
                text: 'How does a seller get paid for sharing content?',
                options: [
                    'Through a fixed percentage of the content owner’s revenues.',
                    'Through a sharing revenue paid by the content owner.',
                    'By charging viewers each time the content is viewed.'
                ],
                correctAnswer: 
                    'Through a sharing revenue paid by the content owner.'
            },
            { 
                text: 'What is "Max Price" in relation to uploading content on VEO?',
                options: [
                    'The maximum amount a user can receive per content and consumer.',
                    'The highest price that can be paid to advertise on the platform.',
                    'The minimum price the content owner can set for their material.'
                ],
                correctAnswer: 
                    'The maximum amount a user can receive per content and consumer.'
            },
            { 
                text: 'What function does the "Content Manager" have on VEO?',
                options: [
                    'To manage content uploaded by users.',
                    'To allow other channels to upload content to one’s own channel.',
                    'To manage the rights to uploaded material and ensure compliance with user terms.'
                ],
                correctAnswer: 
                    'To allow other channels to upload content to one’s own channel.'
            },
        ],
        title: 'Knowledge Test',
        quest: 'Question',
        previous: 'Previous',
        next: 'Next',
        result: 'Result:',
        complete: 'completed',
        wrong: 'You answered the following questions incorrectly:',
        congratulations: '🎉 Congratulations! You answered all questions correctly! 🎉',
        restart: 'Take the knowledge test again',
        wiki: 'Read the wiki',
    },
    sv: {
        questions: [
        { 
            text: 
                'Vad är VEO Partner?',
            options: [
                'En plattform för att dela innehåll och generera intäkter.', 
                'En social medieplattform för att dela videoklipp.',
                'Ett verktyg för säljare att bjuda in kunder och sponsorer.', 
            ],
            correctAnswer: 
                'Ett verktyg för säljare att bjuda in kunder och sponsorer.'
        },
        { 
            text: 
                'Hur tjänar säljare pengar med hjälp av VEO Partner?',
            options: [
                'Genom att erbjuda betalda konsulttjänster till användarna.',
                'Genom att sälja annonseringsutrymme på plattformen.', 
                'Genom att rekrytera nya användare och få ersättning baserat på hur mycket man sålt.',
            ],
            correctAnswer: 
                'Genom att rekrytera nya användare och få ersättning baserat på hur mycket man sålt.'
        },
        { 
            text: 
                'Hur fungerar sponsring genom VEO Partner?',
            options: [
                'Genom att säljare får en fast procentandel av alla sponsrade belopp.',
                'Genom att säljare delar ut en personlig delningslänk till sponsorer.',
                'Genom att säljare betalar en fast månadsavgift för sponsringsmöjligheter.'
            ],
            correctAnswer: 
                'Genom att säljare delar ut en personlig delningslänk till sponsorer.'
        },
        { 
            text: 
                'Hur skapar man en ny användare i VEO Partner?',
            options: [
                'Genom att fylla i önskat kanalnamn och e-postadress och klicka på "Skapa ny användare".',
                'Genom att skicka en inbjudningslänk till personen som ska bli användare.',
                'Genom att skapa ett konto på VEO och sedan bjuda in andra användare.'
            ],
            correctAnswer: 
                'Genom att fylla i önskat kanalnamn och e-postadress och klicka på "Skapa ny användare".'
        },
        { 
            text: 
                'Vad är funktionen av "Händelser" i VEO Partner?',
            options: [
                'För att dela innehåll med andra användare.',
                'För att visa alla händelser och interaktioner med ens delade länkar.',
                'För att spela spel med andra användare på plattformen.'
            ],
            correctAnswer: 
                'För att visa alla händelser och interaktioner med ens delade länkar.'
        },
        { 
            text: 
                'Vad innebär det att vara "Supporter" till en kanal på VEO?',
            options: [
                'Att bidra till kanalens ekonomiska intäkter genom en del av månadsavgiften.',
                'Att få betalt för att visa annonser i kanalen.',
                'Att få exklusiv tillgång till premiuminnehåll på kanalen.'
            ],
            correctAnswer: 
                'Att bidra till kanalens ekonomiska intäkter genom en del av månadsavgiften.'
        },
        { 
            text: 
                'Hur laddar man upp innehåll på VEO?',
            options: [
                'Genom att skicka filerna via e-post till VEO:s support.',
                'Genom att klicka på "Ladda upp" och välja önskad filtyp och sedan ladda upp den.',
                'Genom att kopiera och klistra in innehållet direkt på plattformen.'
            ],
            correctAnswer: 
                'Genom att klicka på "Ladda upp" och välja önskad filtyp och sedan ladda upp den.'
        },
        { 
            text: 
                'Hur får en säljare betalt för att dela innehåll?',
            options: [
                'Genom en fast procentsats av innehållsägarens intäkter.',
                'Genom en delningsintäkt som betalas av innehållsägaren.',
                'Genom att ta betalt från tittarna varje gång innehållet visas.'
            ],
            correctAnswer: 
                'Genom en delningsintäkt som betalas av innehållsägaren.'
        },
        { 
            text: 
                'Vad är "Max-pris" i samband med laddning av innehåll på VEO?',
            options: [
                'Det maximala belopp en användare kan få per innehåll och konsument.',
                'Det högsta pris som kan betalas för att annonsera på plattformen.',
                'Det minsta pris som innehållsägaren kan sätta för sitt material.'
            ],
            correctAnswer: 
                'Det maximala belopp en användare kan få per innehåll och konsument.'
        },
        { 
            text: 
                'Vilken funktion har "Innehållshanterare" på VEO?',
            options: [
                'Att administrera innehåll som laddas upp av användare.',
                'Att tillåta andra kanaler att ladda upp innehåll i ens egen kanal.',
                'Att hantera rättigheterna till uppladdat material och säkerställa efterlevnad av användarvillkor.'
            ],
            correctAnswer: 
                'Att tillåta andra kanaler att ladda upp innehåll i ens egen kanal.'
        },
    ],
        title: 'Kunskapstest',
        quest: 'Fråga',
        previous: 'Tillbaka',
        next: 'Nästa',
        result: 'Resultat:',
        complete: 'avklarat',
        wrong: 'Tyvärr, du svarade fel på följande frågor:',
        congratulations: '🎉 Grattis! Du svarade rätt på alla frågor! 🎉',
        restart: 'Genomför kunskapstestet igen',
        wiki: 'Läs på i wiki:n',
    }
});