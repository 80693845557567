import React, { useState } from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import { colors, fonts, } from '../../theme'

const Select = ({ onChange, options, selected }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  return <View style={{
    position: 'relative',
    zIndex: 100
  }}>
    <TouchableOpacity
      onPress={() => setShowDropdown(!showDropdown)}
      activeOpacity={0.8}
      style={{
        position: 'relative',
        paddingHorizontal: 20,
        paddingVertical: 15,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: colors.greySolo,
        borderRadius: 2
      }}>
      <Text style={{
        color: colors.black,
        fontSize: 14,
        fontFamily: fonts.regular
      }}>
        {selected}
      </Text>
      <View style={{
        height: 10,
        width: 10,
        borderLeftWidth: 1.2,
        borderBottomWidth: 1.2,
        borderBottomColor: colors.black,
        borderLeftColor: colors.black,
        transform: [
          { rotate: showDropdown ? '135deg' : '-45deg' },
        ]
      }} />
    </TouchableOpacity>
    {showDropdown && <View style={{
      position: 'absolute',
      zIndex: 100,
      width: '100%',
      bottom: -options.length * 35,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: colors.greySolo
    }}>
      {options.map(o => <TouchableOpacity
        key={o.value}
        style={{
          height: 35,
          paddingHorizontal: 20,
          paddingVertical: 10,
          backgroundColor: colors.white,
        }}
        onPress={() => {
          onChange(o.value);
          setShowDropdown(false)
        }}>
        <Text style={{
          color: colors.black,
          fontSize: 14,
          fontFamily: fonts.regular
        }}>
          {o.text}
        </Text>
      </TouchableOpacity>)}
    </View>}
  </View>
}

export default Select