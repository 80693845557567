import React, { useEffect, useState } from 'react';
import { View, Text, Linking } from 'react-native';
import LocalizedStrings from 'react-localization';
import { consentCookieVar, shareLinkVar } from '../../reactiveVariables';

import WideButton from '../misc/WideButton';

import { colors, fonts, gutters, mediaQueries } from '../../theme';

const ConsentCookie = () => {
  return (
    <React.Fragment>
      <View
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          height: 'auto',
          backgroundColor: colors.white,
          alignItems: 'center',
          borderTopWidth: 1,
          borderTopColor: '#CCC'
        }}
      >
        <View
          style={{
            padding: gutters.pageGutter,
            maxWidth: mediaQueries.tablet,
            flexShrink: 1
          }}
        >
          <Text
            style={{
              fontFamily: fonts.medium,
              fontSize: 16,
              color: colors.black,
              marginBottom: 20,
            }}
          >
            {strings.title}
          </Text>
          <Text
            style={{
              fontFamily: fonts.regular,
              fontSize: 14,
              color: colors.black,
              marginBottom: 20,
            }}
          >
            {strings.text}
            <Text
              onPress={() =>
                window.open('https://veoworld.org/org/privacypolicy.html', '_blank')
              }
              style={{
                textDecorationLine: 'underline',
                color: colors.deepBlue,
                cursor: 'pointer',
              }}
            >
              {strings.privacyPolicy}
            </Text>
          </Text>
          <WideButton
            onPress={async () => {
              var res = document.cookie;
              var multiple = res.split(";");
              for (var i = 0; i < multiple.length; i++) {
                var key = multiple[i].split("=");
                document.cookie = key[0] + "=" + key[1] + `;max-age=31536000;path=/;${process.env.REACT_APP_ENV !== 'development' && 'secure;'}`;
              }
              document.cookie = `consentCookieKey=true;max-age=31536000;path=/;${process.env.REACT_APP_ENV !== 'development' && 'secure;'}`;
              consentCookieVar(true);
            }}
            buttonStyle={{
              backgroundColor: colors.black,
              flexShrink: 1,
              alignSelf: 'flex-start',
            }}
            textStyle={{
              color: colors.white,
            }}
            pill
            text={strings.accept}
          />
        </View>
      </View>
    </React.Fragment>
  );
};

export default ConsentCookie;

const strings = new LocalizedStrings({
  en: {
    title: "We don't sell your data or track you, we respect your privacy 🍪 ",
    text: "Many established services today sell or make your data available to others often without your knowledge or consent. We want to change this, with us your data remain yours. We use cookies and data only for: functionality, detecting interruptions, sharing, protection and security, anonymized measurement, improvement and allowing you to stay logged in between different sessions. Here you can read our ",
    accept: 'Only accept necessary',
    privacyPolicy: 'Privacy Policy.',
  },
  sv: {
    title: 'Vi säljer inte din data eller spårar dig, vi respekterar din integritet 🍪 ',
    text: 'Många etablerade tjänster idag säljer eller tillgängliggör din data till andra ofta helt utan din vetskap eller ditt medgivande. Detta vill vi ändra på, hos oss förblir din data fortsatt din. Vi använder cookies och data endast för att: säkerställa funktionalitet, identifiera avbrott, delning, skydd och säkerhet, anonym mätning, förbättring och för att hålla dig fortsatt inloggad. Här kan du läsa vår ',
    accept: 'Acceptera endast nödvändiga',
    privacyPolicy: 'integritetspolicy.',
  },
});
