import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  ActivityIndicator
} from 'react-native';
import { useMobileSize, fonts, colors, fontsize, gutters } from '../../theme';

export const WideButton = ({
  text,
  buttonStyle,
  textStyle,
  onPress,
  disabled,
  leftIcon,
  rightIcon,
  unfilled,
  small,
  borderColor = colors.deepBlue,
  loading,
  middleLeftImage,
  middleRightImage,
  pill
}) => {
  const isMobile = useMobileSize();

  const dynamicButtonStyle = {
    height: (isMobile || small) ? 48 : 56,
    backgroundColor: disabled ? colors.greyLuke : (unfilled ? colors.white : colors.deepBlue),
    borderWidth: unfilled ? 1 : 0,
    borderColor: disabled && unfilled ? colors.greyLuke : borderColor,
    ...loading && styles.loading,
    ...pill && styles.pill,
  };

  return (
    <TouchableOpacity
      activeOpacity={disabled ? 1 : 0.6}
      onPress={disabled || loading ? null : onPress}
      disabled={disabled}
      style={[styles.button, dynamicButtonStyle, buttonStyle]}
    >
      {loading ? (
        <View style={{width:'100%'}}><ActivityIndicator size='small' color={colors.white} /></View>
      ) : (
        <React.Fragment>
          <View style={{ flex: 1 }}>{leftIcon}</View>
          {middleLeftImage}
          <Text style={[styles.text, unfilled ? { color: colors.deepBlue } : { color: colors.white }, textStyle]}>
            {text}
          </Text>
          {middleRightImage}
          <View style={{ flex: 1 }}>{rightIcon}</View>
        </React.Fragment>
      )}
    </TouchableOpacity>
  );
};

export default WideButton;

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: colors.deepBlue,
    borderRadius: gutters.spacing[0],
  },
  pill: {
    flexGrow: 1,
    minHeight: 56,
    width: 'auto',
    borderRadius: gutters.spacing[1]
  },
  text: {
    fontSize: fontsize.medium,
    color: colors.white,
    fontFamily: fonts.medium
  },
  textPill: {
    paddingHorizontal: gutters.spacing[4],
    paddingVertical: gutters.spacing[1]
  },
  loading: {
    justifyContent: 'center'
  }
});
