import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
} from 'react-native';
import { colors, fonts, fontsize } from '../../theme';
import Avatar from '../Avatar';

const ProfileCard = ({ name, image, extrStyles, onPress, subText, subTextStyle, avatarSize = 60 }) => {
  const Wrapper = onPress ? TouchableOpacity : View;
  return <Wrapper
    onPress={onPress}>
  <View style={[styles.profileCard, extrStyles]}>
    <View style={{ marginRight: 10 }}>
      <Avatar size={avatarSize} image={image} />
    </View>
    <View>
      <Text
        numberOfLines={2}
        style={{
          color: colors.black,
          fontSize: fontsize.medium,
          fontFamily: fonts.medium,
          flexShrink: 1
        }}>
        {name}
      </Text>
      {subText && <Text style={[{
        color: colors.deepBlue,
        fontFamily: fonts.medium,
        fontSize: fontsize.small,
        marginTop: 3
      }, subTextStyle]}>
        {subText}
      </Text>}
    </View>
  </View>
</Wrapper>
}

const styles = StyleSheet.create({
  profileCard: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1
  },
  chevronRight: {
    marginLeft: 'auto',
    marginRight: 0,
    width: 24,
    height: 24
  }
});

export default ProfileCard;