import React, { useState, useEffect } from 'react';
import { Linking } from 'react-native';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  useMutation,
  useReactiveVar,
  createHttpLink,
  ApolloLink,
  concat,
} from '@apollo/client';
import { LOGIN_MUTATION } from './gql/authMutations';
import Router from './Router';
import PageLoader from './components/PageLoader';
import {
  myAccountVar,
  consentCookieVar,
  shareLinkVar,
} from './reactiveVariables';
import { getCookie } from './helpers/auth';
import config from './config';

const httpLink = createHttpLink({
  uri: `${config.baseUrl}/gql`,
  headers: {},
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const user = myAccountVar();
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authentication: user?.token || global.account?.token || null,
    },
  }));
  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          profile(_, { args, toReference }) {
            return toReference({
              __typename: 'Profile',
              id: args.id,
            });
          },
        },
      },
    },
  }),
  link: concat(authMiddleware, httpLink),
});

export default function App() {
  return (
    <ApolloProvider client={client}>
      <Initialize />
    </ApolloProvider>
  );
}

const Initialize = () => {
  const [init, setInit] = useState(true);
  const consentCookie = getCookie('consentCookieKey');
  const shareLinkData = useReactiveVar(shareLinkVar);

  const [loginToken, { loading, error }] = useMutation(LOGIN_MUTATION, {
    onCompleted: (data) => {
      let maxAge;
      if (consentCookie === 'true') maxAge = 31536000;
      document.cookie = `userToken=${data.loginToken.token};max-age=${maxAge};path=/;${process.env.REACT_APP_ENV !== 'development' && 'secure;'}`;
      if (data.loginToken.token && data.loginToken.token.trim() !== '') {
        myAccountVar(data.loginToken);
        setInit(false);
      }
    },
    onError: (err) => {
      console.log(err);
      document.cookie = `userToken=;Max-Age=0;expires=Thu, 01 Jan 1970 00:00:00;path=/;${process.env.REACT_APP_ENV !== 'development' && 'secure;'}`;
      setInit(false);
    },
  });

  useEffect(() => {
    const login = async () => {
      let userToken = getCookie('userToken');
      const initialUrl = await Linking.getInitialURL();
      const parsedUrl = new URL(initialUrl);
      const shortIdURL = parsedUrl.toString().split('/s/')[1];
      const teamShortIdURL = parsedUrl.toString().split('partner/team/')[1];
      const isSellerURL = teamShortIdURL ? true : false;
      const shortIdParam = teamShortIdURL ? teamShortIdURL : shortIdURL;
      const consentCookie = getCookie('consentCookieKey');
      const shortIdCookie = getCookie('shortId');
      const isSellerCookie = getCookie('isSeller');
      const isSeller = isSellerURL ? isSellerURL : isSellerCookie;
      const shortId = shortIdParam ? shortIdParam : shortIdCookie;
      consentCookieVar(consentCookie === 'true');
      let maxAge;
      if (consentCookie === 'true') maxAge = 31536000;
      if (shortId)
        document.cookie = `shortId=${shortId};max-age=${maxAge};path=/;${process.env.REACT_APP_ENV !== 'development' && 'secure;'}`;
      if (isSeller)
        document.cookie = `isSeller=${isSeller};max-age=${maxAge};path=/;${process.env.REACT_APP_ENV !== 'development' && 'secure;'}`;
      if (shortId || isSeller) shareLinkVar({ shortId: shortId ? shortId : shareLinkData.shortId, isSeller: isSeller });
      let userTokenParam = decodeURIComponent(
        parsedUrl.searchParams.get('userToken')
      );
      if (
        (userTokenParam && userTokenParam.length > 100) ||
        (userToken && userToken.length > 10)
      ) {
        loginToken({
          variables: {
            token: userTokenParam.length > 100 ? userTokenParam : userToken,
            deviceId: 'partner',
            deviceName: navigator.userAgent,
            country: null,
            language: (navigator.language || navigator.userLanguage).split(
              '-'
            )[0],
          },
        });
      } else {
        setInit(false);
      }
    };
    login();
  }, [loginToken]);

  if (error || loading || init) {
    return (
      <PageLoader error={error} noNavBar={true} loading={loading || init} />
    );
  } else {
    return <Router />;
  }
};
