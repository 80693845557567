import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
} from 'react-native';
import { colors, fonts, fontsize } from '../../theme'

const InputField = ({ input, placeholder, keyboardType = 'default', onChangeText, label = null, maxLength }) => <View>
  {label}
  <TextInput
    style={styles.input}
    placeholder={placeholder}
    keyboardType={keyboardType}
    value={input}
    onChangeText={onChangeText}
    placeholderTextColor={colors.greyDarth}
    maxLength={maxLength}
  />
</View>

export default InputField

const styles = StyleSheet.create({
  input: {
    borderRadius: 8,
    backgroundColor: colors.greySolo,
    minHeight: 48,
    paddingHorizontal: 10,
    fontFamily: fonts.mono,
    fontSize: fontsize.regular,
    color: colors.black,
  },
})