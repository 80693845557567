import React, { useState, useRef, useEffect } from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
} from 'react-native';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import gql from 'graphql-tag';

import { SIGNUP_MUTATION } from '../../gql/authMutations';
import { myAccountVar, shareLinkVar } from '../../../src/reactiveVariables';

import WideButton from '../../components/misc/WideButton';
import TermsCheckbox from '../../components/misc/TermsCheckbox';
import Hcaptcha from '../../components/Hcaptcha';

import { fonts, colors, fontsize, commonStyles } from '../../theme';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useReactiveVar } from '@apollo/client/index';

const CHECK_USERNAME = gql`
  query ($name: String!) {
    checkUsername(name: $name)
  }
`;

const VERIFICATION = gql`
  mutation ($to: String!, $type: String!, $hcaptchaToken: String) {
    verify(to: $to, type: $type, hcaptchaToken: $hcaptchaToken)
  }
`;

export default function SignUp() {
  const location = useLocation();
  const history = useHistory();

  const shareLinkData = useReactiveVar(shareLinkVar);
  const sponsor = location.pathname.includes('sponsor');
  const festival = location.pathname.includes('festival');
  const [captchaToken, setCaptchaToken] = useState('');
  const captchaFormRef = useRef();

  const { isSmallScreen } = useWindowDimensions();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verifyId, setVerifyId] = useState('');
  const [code, setCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showVerify, setShowVerify] = useState(false);
  const [verifySms, setVerifySms] = useState(false);

  const nameRef = useRef(null);
  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    // 👇️ Scroll to top on page load
    window.scrollTo({top: 0, left: 0});
  }, [history]);

  const [signupPartner, { loading }] = useMutation(SIGNUP_MUTATION, {
    onCompleted: (data) => {
      document.cookie = `isSeller=;Max-Age=0;expires=Thu, 01 Jan 1970 00:00:00;path=/;${process.env.REACT_APP_ENV !== 'development' && 'secure;'}`;
      document.cookie = `userToken=${data.signupPartner.token};max-age=31536000;path=/;${process.env.REACT_APP_ENV !== 'development' && 'secure;'}`;
      myAccountVar(data.signupPartner);
      if (sponsor) {
        history.replace('/sponsor/payment');
      }
      else if (festival) {
        history.replace('/festival/payment');
      } else {
        history.push('/share');
      }
    },
    onError: (err) => {
      if (err && err.graphQLErrors && err.graphQLErrors.length !== 0) {
        const message = err.graphQLErrors[0].message;
        if (message === 'errInvite') {
          alert(strings.errInvite);
        } else if (message === 'errVerify') {
          alert(strings.errVerify);
        } else {
          alert(strings.errSignUp);
        }
      } else {
        alert(err);
      }
    },
  });

  const [verify, { loading: verifyLoading }] = useMutation(VERIFICATION, {
    onCompleted: (data) => {
      setVerifyId(data.verify);
      setShowVerify(true);
    },
    onError: (err) => {
      if (err && err.graphQLErrors && err.graphQLErrors.length !== 0) {
        err.graphQLErrors?.[0].message === 'verifyUsed'
          ? alert(strings.errUsed)
          : alert(
            strings.errVerification + ' - ' + err.graphQLErrors[0].message
          );
      }
    },
  });

  const [checkUsername] = useLazyQuery(CHECK_USERNAME, {
    onCompleted: (data) => {
      if (data.checkUsername) {
        alert(name + ' ' + strings.nameTaken);
        nameRef.current.focus();
      }
    },
    onError: (err) => {
      alert(err);
    },
  });

  const handleSignup = () => {
    if (!termsAccepted) {
      alert(strings.notAcceptTerms);
    } else {
      signupPartner({
        variables: {
          name,
          email,
          password,
          verifyId,
          code,
          deviceId: 'partner',
          deviceName: navigator.userAgent,
          country: null,
          language: (navigator.language || navigator.userLanguage).split(
            '-'
          )[0],
          shortId: shareLinkData?.shortId,
          isSeller: shareLinkData?.isSeller ? true : false,
          hcaptchaToken: null,
        },
      });
    }
  };

  const handleVerify = () => { };

  const isDisabled = () =>
    loading ||
    name.length < 2 ||
    !email.includes('@') ||
    !email.includes('.') ||
    email.length < 5 ||
    password.length < 10 ||
    !termsAccepted;

  return (
    <View
      style={{
        backgroundColor: colors.white,
        paddingHorizontal: 40,
        paddingVertical: 40,
        borderRadius: 8,
        marginBottom: 100,
        boxShadow: '3px 2px 20px -3px rgba(0, 0, 0, 0.09)',
      }}
    >
      {showVerify ? (
        <View>
          <Text style={styles.createAccountHeader}>{strings.verify}</Text>
          <Text style={[styles.infoText, { marginTop: 20 }]}>
            {strings.verifyText}
          </Text>
          <Text style={styles.label}>{strings.enterCodeText}</Text>
          <TextInput
            style={styles.input}
            placeholder={strings.enterCode}
            keyboardType="numeric"
            value={code}
            ref={nameRef}
            onEndEditing={() => handleVerify()}
            onChangeText={(input) => {
              if (input !== '' && !input.match(/^[0-9]+$/)) {
                alert(strings.illegalCharacter);
              } else {
                setCode(input);
              }
            }}
          />
          <WideButton
            buttonStyle={styles.buttonStyle}
            text={strings.verify}
            loading={loading}
            onPress={() => {
              if (code) {
                handleVerify();
              } else {
                alert(strings.enterCode);
              }
            }}
          />
          <TouchableOpacity
            style={styles.alreadyAccount}
            onPress={() => {
              //setVerifySms(true);
              //setShowVerify(false);
            }}
          >
            <Text style={styles.alreadyAccountText}>
              {strings.notReceiveCode}
            </Text>
          </TouchableOpacity>
        </View>
      ) : verifySms ? (
        <View>
          <Text style={styles.createAccountHeader}>{strings.sendSMS}</Text>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.label}>{strings.sendSMSEnter}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <TextInput
              style={styles.input}
              placeholder="+XX NNN NNN NNN"
              keyboardType="numeric"
              value={phoneNumber}
              ref={nameRef}
              onSubmitEditing={() => handleSignup()}
              onChangeText={(input) => {
                if (input !== '' && !input.match(/^[0-9+]+$/))
                  alert(strings.illegalCharacter);
                setPhoneNumber(input);
              }}
            />
          </View>
          <WideButton
            buttonStyle={styles.buttonStyle}
            disabled={isDisabled()}
            text={strings.sendSMS}
            onPress={() => handleSignup()}
          />
        </View>
      ) : (
        <View>
          <Text style={commonStyles.boldText}>
            {sponsor ? strings.createAccountSponsor
              : festival ? strings.createAccountFestival
              : strings.createAccountHeader}
          </Text>
          {sponsor ? (
            <Text style={commonStyles.infoText}>{strings.stepTwo}</Text>
          ) : festival ? (
            <Text style={commonStyles.infoText}>{strings.stepTwo}</Text>
          ) : (
            <View />
          )}
          <View>
            <Text style={styles.label}>{strings.username}</Text>
            <TextInput
              style={styles.input}
              placeholder={strings.username}
              textContentType="username"
              value={name}
              ref={nameRef}
              onBlur={() => {
                if (name.length > 0) {
                  checkUsername({
                    variables: {
                      name: name,
                    },
                  });
                }
              }}
              autoFocus={true}
              onSubmitEditing={() => handleSignup()}
              onChangeText={(input) => {
                input !== '' &&
                  (input.match(
                    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
                  ) ||
                    input.match(/[<>%;$\\\/#^=.,"'+*?@&!(){|}=[\]]+$/))
                  ? alert(strings.illegalCharacter)
                  : setName(input);
              }}
            />
            <Text style={styles.label}>
              {strings.email}
            </Text>
            <TextInput
              textContentType="emailAddress"
              style={styles.input}
              placeholder={strings.email}
              autoCapitalize={'none'}
              value={email}
              onBlur={() => {
                if (email.length === 0) {
                  return;
                }
                if (
                  !email ||
                  email.match(
                    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
                  ) ||
                  !email.match(
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  )
                ) {
                  alert(strings.illegalCharacterEmail);
                }
              }}
              onChangeText={(input) => setEmail(input)}
            />
            <Text style={styles.label}>
              {strings.password}
              <Text style={styles.grey}>{strings.minPassword}</Text>
            </Text>

            <TextInput
              textContentType="password"
              style={styles.input}
              placeholder={strings.password}
              value={password}
              autoCapitalize={'none'}
              secureTextEntry={true}
              onChangeText={(input) => setPassword(input)}
            />
            <TermsCheckbox
              termsAccepted={termsAccepted}
              onChange={setTermsAccepted}
            />
          </View>

          {sponsor ? (
            <View style={styles.navRow}>
              <TouchableOpacity
                style={styles.backContainer}
                onPress={() => history.goBack()}
              >
                <Image
                  style={styles.chevronIcon}
                  source={{ uri: 'icons/nav/chevron.jpg' }}
                />
                <Text style={styles.back}>{strings.back}</Text>
              </TouchableOpacity>
              <WideButton
                buttonStyle={[
                  styles.nextButton,
                  isSmallScreen && { width: 150 },
                  {
                    backgroundColor: isDisabled()
                      ? colors.greyLeia
                      : colors.goGreen,
                  },
                ]}
                disabled={isDisabled()}
                loading={verifyLoading}
                text={strings.signUp}
                onPress={() => handleSignup()}
              />
            </View>
          )
          : festival ? (
            <View style={styles.navRow}>
              <TouchableOpacity
                style={styles.backContainer}
                onPress={() => history.goBack()}
              >
                <Image
                  style={styles.chevronIcon}
                  source={{ uri: 'icons/nav/chevron.jpg' }}
                />
                <Text style={styles.back}>{strings.back}</Text>
              </TouchableOpacity>
              <WideButton
                buttonStyle={[
                  styles.nextButton,
                  isSmallScreen && { width: 150 },
                  {
                    backgroundColor: isDisabled()
                      ? colors.greyLeia
                      : colors.goGreen,
                  },
                ]}
                disabled={isDisabled()}
                loading={verifyLoading}
                text={strings.signUp}
                onPress={() => handleSignup()}
              />
            </View>
          ) : (
            <WideButton
              buttonStyle={styles.buttonStyle}
              disabled={isDisabled()}
              loading={verifyLoading}
              text={strings.signUp}
              onPress={() => handleSignup()}
            />
          )}
          <TouchableOpacity
            style={styles.alreadyAccount}
            onPress={() => {
              if (sponsor) {
                history.push('/sponsor/signin');
              }
              else if (festival) {
                  history.push('/festival/signin'); 
              } else {
                history.push('/signin');
              }
            }}
          >
            <Text style={styles.alreadyAccountText}>
              {strings.alreadyAccountText}{' '}
              <Text style={styles.alreadyAccountBold}>
                {strings.alreadyAccountBold}
              </Text>
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  supportHeader: {
    fontFamily: fonts.regular,
    fontSize: 20,
    lineHeight: 25,
    color: colors.black,
  },
  header: {
    marginTop: 40,
    marginBottom: 13,
    fontFamily: fonts.regular,
    fontSize: 32,
    lineHeight: 25,
    color: colors.black,
  },
  createAccountHeader: {
    marginBottom: 30,
    fontFamily: fonts.regular,
    fontSize: 20,
    lineHeight: 25,
    color: colors.black,
  },
  label: {
    fontFamily: fonts.regular,
    fontSize: 14,
    lineHeight: 12,
    color: colors.black,
    marginTop: 24,
    marginBottom: 5,
  },
  grey: {
    fontFamily: fonts.regular,
    fontSize: 12,
    lineHeight: 12,
    color: colors.greyLeia,
  },
  input: {
    marginTop: 5,
    borderRadius: 8,
    backgroundColor: colors.greySolo,
    minHeight: 56,
    paddingHorizontal: 10,
    fontFamily: fonts.regular,
    fontSize: 16,
    color: colors.black,
  },
  inputCountry: {
    width: 50,
    marginTop: 5,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: colors.greyLuke,
    minHeight: 48,
    paddingHorizontal: 10,
    fontFamily: fonts.regular,
    fontSize: fontsize.small,
    lineHeight: 14,
    color: colors.black,
  },
  buttonStyle: {
    marginTop: 20,
    marginBottom: 40,
  },
  infoText: {
    fontFamily: fonts.regular,
    fontSize: fontsize.regular,
    color: colors.black,
    lineHeight: 17.5,
  },


  alreadyAccount: {
    marginTop: 20,
    marginBottom: 45,
  },
  alreadyAccountText: {
    fontFamily: fonts.regular,
    color: colors.black,
    fontSize: 14,
    lineHeight: 17.5,
    textAlign: 'center',
  },
  alreadyAccountBold: {
    fontFamily: fonts.regular,
    color: colors.black,
  },
  navRow: {
    marginTop: 20,
    marginBottom: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  backContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  chevronIcon: {
    width: 6,
    height: 12,
  },
  back: {
    marginLeft: 17,
    fontFamily: fonts.regular,
    color: colors.greyLeia,
    fontSize: 14,
  },
  nextButton: {
    width: 296.5,
    height: 40,
    backgroundColor: colors.deepBlue,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  nextButtonText: {
    color: colors.white,
    fontFamily: fonts.regular,
    fontSize: 16,
  },
});

const strings = new LocalizedStrings({
  en: {
    createAccountHeader: 'Create a VEO app account for free',
    createAccountSponsor: 'Create a VEO app account and sponsor',
    createAccountFestival: 'Create a VEO app account and get the ticket to the festival',
    username: 'Channel name',
    email: 'Email',
    password: 'Password',
    minPassword: ' (at least 10 characters long)',
    signUp: 'Sign up',
    signIn: 'Sign in',
    notAcceptTerms:
      'Please check the checkbox to accept the Terms and Conditions.',
    nameTaken: 'is already taken. Please choose another channel name.',
    alreadyAccountText: `Already have an VEO account?`,
    alreadyAccountBold: 'Sign in here',
    errSignUp: 'Your account could not be created. Please try again later.',
    errVerify: 'You have entered a wrong or expired code.',
    errVerification:
      'You have tried to verify to many times. Please try again later.',
    errUsed:
      'You have already used that email for verification. Please choose another email.',
    emailVerify: ' (we will send a verification code to this email)',
    sendSMS: 'Code through SMS',
    sendSMSEnter: 'Enter your phone number below',
    phoneNumber: 'Mobile phone number',
    countryCode: '+XX is your country code. For example UK is +44',
    verify: 'Verify',
    enterCode: 'Enter code',
    enterCodeText: 'Enter received code below:',
    verifyText:
      'A code has now been sent to your email. Please enter the code below and press the "Verify" button to create your account. Code is valid once and for 5 minutes only. Verification is a must to fight spam and AI bots.',
    notReceiveCode:
      'Did not receive any code? Check your spam folder.',
    notReceiveCodeBold: 'press here for sms verification.',
    back: 'Back',
    illegalCharacter: 'Only alphanumeric characters are allowed.',
    illegalCharacterEmail: 'Your email is not correct.',
    stepTwo: 'Step 2 of 3',
  },
  sv: {
    createAccountHeader: 'Skapa ett VEO app konto gratis',
    createAccountSponsor: 'Skapa ett VEO app konto och sponsra',
    createAccountFestival: 'Skapa ett VEO app konto och få festivalbiljetten skickad via epost',
    username: 'Kanalnamn',
    email: 'Epost',
    password: 'Lösenord',
    minPassword: ' (Minst 10 tecken långt.)',
    signUp: 'Skapa konto',
    signIn: 'Logga in',
    notAcceptTerms: 'Vänligen checka i checkboxen för att acceptera villkoren.',
    nameTaken: 'är redan taget. Vänligen välj ett annat kanalnamn.',
    alreadyAccountText: `Har du redan ett VEO konto?`,
    alreadyAccountBold: 'Logga in här',
    errSignUp: 'Ditt konto kunde inte skapas. Vänligen försök senare.',
    errVerify: 'Du har angivit en felaktig eller utgången kod.',
    errVerification:
      'Du har försökt verifiera dig för många gånger. Vänligen försök igen senare.',
    errUsed:
      'Du har redan använt denna email för verifiering. Vänligen välj en annan email.',
    emailVerify: ' (Vi kommer skicka en verifikationskod till denna epost)',
    sendSMS: 'Kod via SMS',
    sendSMSEnter: 'Ange ditt mobilnummer nedan',
    phoneNumber: 'Telefonnummer',
    verify: 'Verifiering',
    enterCode: 'Fyll i kod',
    enterCodeText: 'Fyll i kod nedan',
    verifyText:
      'En kod har nu skickats till dig. Vänligen fyll i koden nedan och tryck verifieraknappen för att skapa ditt konto. Koden är giltig en gång och i 5 minuter endast. Verifiering är ett måste numera för att bekämpa spam och AI bots.',
    notReceiveCode:
      'Fick du ingen kod? Kolla om det hamnat i skräppost.',
    notReceiveCodeBold: 'tryck här för sms verifiering.',
    back: 'Tillbaka',
    illegalCharacter: 'Endast alphanumeriska tecken är tillåtna.',
    illegalCharacterEmail: 'Din email är inte korrekt.',
    stepTwo: 'Steg 2 av 3',
  },
});
