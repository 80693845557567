import React from 'react';
import {
  View,
  Text,
} from 'react-native';
import { fonts, colors } from '../../theme';

const Stat = ({ label, value, extraStyles, labelStyle, valueStyle }) => <View style={extraStyles}>
  <Text style={[{
    color: colors.greyLeia,
    fontSize: 12,
    fontFamily: fonts.regular,
  }, labelStyle]} >
    {label}
  </Text>
  <Text style={[{
    color: colors.black,
    fontSize: 20,
    fontFamily: fonts.mono
  }, valueStyle]}>
    {value ? value : value === '' ? '' : 0}
  </Text>
</View>

export default Stat