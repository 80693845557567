import React, { useLayoutEffect } from 'react';
import { ImageBackground, View, useWindowDimensions } from 'react-native';
import {
  Route,
  Switch,
  BrowserRouter,
  Redirect,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import Footer from './components/Footer';
import SnackBar from './components/SnackBar';
import ConsentCookie from './components/ConsentCookie';
import { mediaQueries } from './theme';
import {
  myAccountVar,
  sponsorVar,
  consentCookieVar,
} from './reactiveVariables';
// import FAQScreen from './screens/FAQ';
import WikiScreen from './screens/Wiki';
import StartScreen from './screens/Start';
import KnowledgeScreen from './screens/Quiz';
import DiscoverScreen from './screens/DiscoverScreen';
import MySponsoshipsScreen from './screens/MySponsorships';
import ShareAnalyticsScreen from './screens/ShareAnalytics';
import SigninScreen from './screens/Auth/Signin';
import SignUpScreen from './screens/Auth/Signup';
import StatHelper from './screens/Helpers/StatHelper';
import ForgotPasswordScreen from './screens/Auth/ForgotPassword';
import SellerScreen from './screens/Seller';
import { useMobileSize, colors } from './theme';
import TransparentTopBar from './components/TransparentTopBar';
import TopBar, { NAVBAR_HEIGHT } from './components/TopBar';
import ShareScreen from './screens/ShareScreen';
import SponsorDistScreen from './screens/SponsorDist';
import SponsorScreen from './screens/Sponsor/Sponsor';
import ReceiptScreen from './screens/Sponsor/Receipt';
import SponsorPaymentScreen from './screens/Sponsor/SponsorPayment';
import FestivalScreen from './screens/Festival/Festival';
import FestivalPaymentScreen from './screens/Festival/FestivalPayment';
import config from './config';

import oceanBg from './assets/images/bg.jpg';
import festivalBg from './assets/images/festival.jpg';
const { showDiscover } = config;

export default function Router() {
  const user = useReactiveVar(myAccountVar);
  const consentCookie = useReactiveVar(consentCookieVar);
  const isSignedIn = user && !user?.hasNotSignedUp && user.name;
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        {showDiscover && (
          <Redirect exact from={`/`} to={isSignedIn ? '/start' : 'signin'} />
        )}
        <Route path="/sponsor" component={GenericSponsorLayout} />
        <Route path="/festival" component={FestivalLayout} />
        {isSignedIn && showDiscover && (
          <PrivateRoute>
            <Route component={AppLayout} />
          </PrivateRoute>
        )}
        {!isSignedIn && showDiscover && <Route component={SigninLayout} />}
        <Redirect to={showDiscover ? '/' : 'sponsor'} />
      </Switch>
      <SnackBar />
      {!consentCookie && <ConsentCookie />}
    </BrowserRouter>
  );
}

function AppLayout() {
  const { height } = useWindowDimensions();
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <View
      style={{
        backgroundColor: colors.white,
        flex: 1,
      }}
    >
      <TopBar />
      <View style={{ height: NAVBAR_HEIGHT }} />
      <View
        style={{
          alignItems: 'center',
          width: '100%',
          minHeight: height,
        }}
      >
        <Switch>
          <Route path="/start" exact component={StartScreen} />
          <Route path="/knowledge-test" exact component={KnowledgeScreen} />
          <Route path="/share" exact component={ShareScreen} />
          <Route path="/mySponsorships" exact component={MySponsoshipsScreen} />
          <Route
            path="/shareAnalytics"
            exact
            component={ShareAnalyticsScreen}
          />
          <Route path="/discover" exact component={DiscoverScreen} />
          <Route path="/discover/:id" exact component={SponsorDistScreen} />
          <Route path="/seller/:id" exact component={SellerScreen} />
          {/* <Route path="/faq" exact component={FAQScreen} /> */}
          <Route path="/wiki" exact component={WikiScreen} />

          <Route path="/stat-helper" exact component={StatHelper} />
          <Redirect to={'/'} />
        </Switch>
        <Footer />
      </View>
    </View>
  );
}

function SigninLayout() {
  const user = useReactiveVar(myAccountVar);
  const isMobile = useMobileSize();
  return (
    <ImageBackground
      source={
        process.env.REACT_APP_ENV === 'prod'
          ? { uri: 'https://prod.veoworld.me/org/images/bg.jpg' }
          : process.env.REACT_APP_ENV === 'stage'
          ? { uri: 'https://stage.veoworld.me/org/images/bg.jpg' }
          : oceanBg
      }
      style={{ height: '100vh' }}
    >
      <TransparentTopBar user={user} />
      <View
        style={{
          maxWidth: mediaQueries.modalMax,
          width: '100%',
          alignSelf: 'center',
          marginTop: isMobile ? 10 : 60,
          paddingHorizontal: 20,
        }}
      >
        <Switch>
          <Route path="/signin" exact component={SigninScreen} />
          <Route path="/signup" exact component={SignUpScreen} />
          <Route
            path="/forgotPassword"
            exact
            component={ForgotPasswordScreen}
          />
          <Route path="/s/:id" exact component={SignUpScreen} />
          <Route path="/:team/:id" exact component={SignUpScreen} />
          <Redirect to={'/'} />
        </Switch>
      </View>
    </ImageBackground>
  );
}

function GenericSponsorLayout() {
  const user = useReactiveVar(myAccountVar);
  const isSignedIn = user && !user?.hasNotSignedUp && user.name;
  const isMobile = useMobileSize();
  const sponsorData = useReactiveVar(sponsorVar);
  const { path, url } = useRouteMatch();

  return (
    <ImageBackground
      source={
        process.env.REACT_APP_ENV === 'prod'
          ? { uri: 'https://prod.veoworld.me/org/images/bg.jpg' }
          : process.env.REACT_APP_ENV === 'stage'
          ? { uri: 'https://stage.veoworld.me/org/images/bg.jpg' }
          : oceanBg
      }
      style={{ height: '100%' }}
    >
      <TransparentTopBar user={user} />
      <View
        style={{
          maxWidth: mediaQueries.modalMax,
          width: '100%',
          alignSelf: 'center',
          marginTop: isMobile ? 10 : 60,
          paddingHorizontal: 20,
        }}
      >
        <Switch>
          <Route path="/sponsor" exact component={SponsorScreen} />
          <Route path="/sponsor/s/:shortId" exact component={SponsorScreen} />
          <Route exact path="/receipt/:id" component={ReceiptScreen} />
          {!isSignedIn && (
            <React.Fragment>
              <Route path={`${url}/signin`} exact component={SigninScreen} />
              <Route path={`${url}/signup`} exact component={SignUpScreen} />
              <Route
                path={`${url}/forgotPassword`}
                exact
                component={ForgotPasswordScreen}
              />
            </React.Fragment>
          )}
          {isSignedIn && (
            <React.Fragment>
              {!sponsorData.amount && (
                <Redirect exact from={`${url}/payment`} to={path} />
              )}
              {!sponsorData.amount && (
                <Redirect exact from={`${url}/confirmed`} to={path} />
              )}
              <Route path={`${url}/payment`} component={SponsorPaymentScreen} />
            </React.Fragment>
          )}
          <Redirect to={'sponsor'} />
        </Switch>
      </View>
    </ImageBackground>
  );
}

function FestivalLayout() {
  const user = useReactiveVar(myAccountVar);
  const isSignedIn = user && !user?.hasNotSignedUp && user.name;
  const isMobile = useMobileSize();
  const sponsorData = useReactiveVar(sponsorVar);
  const { path, url } = useRouteMatch();

  return (
    <ImageBackground
      source={
        process.env.REACT_APP_ENV === 'prod'
          ? { uri: 'https://prod.veoworld.me/org/images/festival.jpg' }
          : process.env.REACT_APP_ENV === 'stage'
          ? { uri: 'https://stage.veoworld.me/org/images/festival.jpg' }
          : festivalBg
      }
      style={{ height: '100%' }}
    >
      <TransparentTopBar user={user} />
      <View
        style={{
          maxWidth: mediaQueries.desktop,
          width: '100%',
          alignSelf: 'center',
          marginTop: isMobile ? 10 : 60,
          paddingHorizontal: 20,
        }}
      >
        <Switch>
          <Route path="/festival" exact component={FestivalScreen} />
          <Route path="/festival/s/:shortId" exact component={FestivalScreen} />
          {!isSignedIn && (
            <React.Fragment>
              <Route path={`${url}/signin`} exact component={SigninScreen} />
              <Route path={`${url}/signup`} exact component={SignUpScreen} />
              <Route
                path={`${url}/forgotPassword`}
                exact
                component={ForgotPasswordScreen}
              />
            </React.Fragment>
          )}
          {isSignedIn && (
            <React.Fragment>
              <Route
                path={`${url}/payment`}
                component={FestivalPaymentScreen}
              />
            </React.Fragment>
          )}
          <Redirect to={'festival'} />
        </Switch>
      </View>
    </ImageBackground>
  );
}

function PrivateRoute({ children, ...rest }) {
  const user = useReactiveVar(myAccountVar);
  const isSignedIn = user && !user?.hasNotSignedUp && user.name;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isSignedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
