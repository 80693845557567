import React, { useRef, useState } from 'react';
import {
  View,
  StyleSheet,
  Text,
  Image,
  ActivityIndicator,
  TouchableOpacity
} from 'react-native';
import LocalizedStrings from 'react-localization';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import gql from 'graphql-tag';
import { isMobile as isMobileDevice } from 'react-device-detect';

import {
  colors,
} from '../../theme';

import { myAccountVar } from '../../../src/reactiveVariables';

import veoIcon from '../../assets/images/veoLogo-black.png';
import trustlyIcon from '../../assets/icons/trustly.png';
import swishIcon from '../../assets/icons/swish.png';
import chevronRightIcon from '../../assets/icons/chevron-right-black.png';
import masterCardIcon from '../../assets/icons/mastercard.png';
import visacardIcon from '../../assets/icons/visacard.jpg';
import creditCardIcon from '../../assets/icons/credit-card.png';

const providerIcon = {
  default: creditCardIcon,
  veo: veoIcon,
  trustly: trustlyIcon,
  ecommpay: creditCardIcon,
  swish: swishIcon
};

const QUERY = gql`
  query ($country: String!, $paymentType: String!) {
    payProviders(country: $country, paymentType: $paymentType) {
      savedProviders {
        id
        provider
        providerName
        accNumber
        accHolder
        cardType
        expiryMonth
        expiryYear
        feeAmount
        feeCurrency
        feeOutProc
        feeOutAmount
        maxAmount
        isCard
      }
      countryProviders {
        provider
        providerName
        feeAmount
        feeCurrency
        feeOutProc
        feeOutAmount
        maxAmount
        isCard
      }
    }
  }
`;

const SAVE_PAY_PROVIDER = gql`
  mutation (
    $id: ID
    $provider: String!
    $type: String!
    $amount: Float!
    $currency: String!
    $months: Float
    $itemId: String
    $name: String!
    $email: String
    $orgNr: String
    $vars: String
    $locale: String!
    $recurring: Boolean!
    $visibleOthers: Boolean!
    $verificationOnly: Boolean!
    $password: String
    $shareId: String
  ) {
    savePayProvider(
      id: $id
      provider: $provider
      type: $type
      amount: $amount
      currency: $currency
      months: $months
      itemId: $itemId
      name: $name
      email: $email
      orgNr: $orgNr
      vars: $vars
      locale: $locale
      visibleOthers: $visibleOthers
      recurring: $recurring
      verificationOnly: $verificationOnly
      password: $password
      shareId: $shareId
    ) {
      id
      uri
      providerName
    }
  }
`;


export default ({
  type,
  amount,
  currency,
  name,
  itemId,
  recurring,
  verificationOnly,
  visibleOthers,
  orgNr,
  setMakePayment,
  setConfirmItem,
  setConfirmTitle,
  setConfirm,
  setConfirmLoading,
  setWaitLoading,
  setConfirmMonths,
  setTransactionId,
  isMonthly,
  disabled,
  shareId
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const account = useReactiveVar(myAccountVar) || {};
  const [months, setMonths] = useState(1);
  const [providerName, setProviderName] = useState(null);

  const { error, data, loading } = useQuery(QUERY, {
    variables: {
      country: account.country ? account.country : 'se',
      paymentType: type,
    },
    onCompleted: async (data) => {
      setMakePayment(() => makePayment);
      // alert(JSON.stringify(data));
    },
  });

  const [savePayProvider, { }] = useMutation(SAVE_PAY_PROVIDER, {
    onError: (err) => {
      setIsLoading(false);
      setWaitLoading(false);
      setConfirmLoading(false);
      alert(err.message);
    },
    onCompleted: async (data) => {
      setTransactionId(data.savePayProvider.id);
      if (data.savePayProvider.uri === '') {
        setIsLoading(false);
        setWaitLoading(true);
        setConfirmLoading(true);
      } else {
        var x = null;
        try {
          x = window.open(data.savePayProvider.uri, '_self');
        } catch (e) {
          alert(strings.payMethodError);
        } finally {
          if (!x) {
            alert(strings.payMethodError);
          }
          setIsLoading(false);
          setWaitLoading(false);
          setConfirmLoading(false);
        }
      }
    },
  });

  const makePayment = (
    item,
    type,
    amount,
    currency,
    months,
    itemId,
    name,
    email,
    orgNr,
    vars,
    password,
    shareId
  ) => {
    setIsLoading(true);
    savePayProvider({
      variables: {
        id: item.id ? item.id : null,
        provider: item.provider,
        type: type,
        amount: Number(amount),
        currency: currency,
        months: months,
        itemId: itemId,
        name: name,
        email: email,
        orgNr: orgNr,
        vars: JSON.stringify(vars),
        locale: account.language,
        visibleOthers: visibleOthers,
        recurring: recurring,
        verificationOnly: verificationOnly,
        password: password,
        shareId: shareId
      },
    });
  };

  const handlePayment = (item) => {
    setConfirmItem(item);
    setConfirmTitle(item.providerName);
    setConfirmMonths(months);
    setProviderName(item.providerName);
    setMakePayment(() => makePayment);
    if (item.provider !== 'trustly'
      && (item.provider === 'swish' && isMobileDevice)
      && type !== 'payout'
    ) {
      // Has signed up already no need to show confirm and ask for email
      makePayment(
        item,
        type,
        amount,
        currency,
        months,
        itemId,
        name,
        null,
        orgNr,
        {},
        null,
        shareId
      );
    } else {
      // Has not signed up or more fields are required
      setConfirm(true);
    }
    window.scrollTo(0, 0);
  };

  if (loading || isLoading)
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator style={{ marginRight: 10 }} />
        {providerName && <Text>{strings.waiting + ' ' + providerName} ...</Text>}
      </View>
    );
  if (error) {
    return (
      <View style={styles.error}>
        <Text>Check your internet connection</Text>
        <Text>{`${error}`}</Text>
      </View>
    );
  }
  const { countryProviders, savedProviders } = data.payProviders;

  return (
    <View>
      {savedProviders.map((item, index) => (
        <TouchableOpacity
          disabled={disabled}
          key={'saved' + index}
          onPress={() => handlePayment(item)}
        >
          <PayMethod
            item={item}
            isSaved={true}
            isPayout={type === 'payout'}
            verificationOnly={verificationOnly}
          />
        </TouchableOpacity>
      ))}
      {countryProviders.map((item, index) => (
        <TouchableOpacity
          disabled={disabled}
          key={'country' + index}
          onPress={() => handlePayment(item)}
        >
          <PayMethod
            item={item}
            isSaved={false}
            isPayout={type === 'payout'}
            verificationOnly={verificationOnly}
          />
        </TouchableOpacity>
      ))}
    </View>
  );
};

const PayMethod = ({ item, isSaved, isPayout, verificationOnly }) =>
  isSaved ? (
    <View style={styles.payMethod}>
      <View style={{ flex: 2 }}>
        {item.cardType ? (
          <Image
            style={
              item.cardType === 'mastercard'
                ? {
                  width: 37,
                  height: 23,
                }
                : {
                  width: 37,
                  height: 21,
                }
            }
            source={
              item.cardType === 'mastercard' ? masterCardIcon : visacardIcon
            }
          />
        ) : (
          <Image
            style={
              styles[item.provider] ? styles[item.provider] : styles['default']
            }
            source={
              providerIcon[item.provider]
                ? providerIcon[item.provider]
                : providerIcon['default']
            }
          />
        )}
      </View>
      <View style={{ flex: 4 }}>
        {item.accNumber ? (
          <Text
            style={{
              fontSize: 16,
              flex: 4,
              marginTop: 2,
            }}
          >
            {item.providerName}{' '}
            {`****${item.accNumber ? item.accNumber.substr(-4) : ''}`}
          </Text>
        ) : (
          <Text
            style={{
              fontSize: 16,
              marginTop: 2,
            }}
          >
            {item.providerName}
          </Text>
        )}
      </View>
      <View style={{ flex: 1 }}>
        <Image style={styles.chevronRightThinIcon} source={chevronRightIcon} />
      </View>
    </View>
  ) : (
    <View style={styles.payMethod}>
      <View style={{ flex: 2 }}>
        {item.isCard ? (
          <Image style={styles.creditCardIcon} source={creditCardIcon} />
        ) : (
          <Image
            style={
              styles[item.provider] ? styles[item.provider] : styles['default']
            }
            source={
              providerIcon[item.provider]
                ? providerIcon[item.provider]
                : providerIcon['default']
            }
          />
        )}
      </View>
      <View style={{ flex: 4 }}>
        <Text
          style={{
            fontSize: 16,
            marginBottom: item.isCard ? 5 : 0,
          }}
        >
          {isPayout
            ? strings[`${item.provider}Out`]
              ? strings[`${item.provider}Out`]
              : ''
            : verificationOnly
              ? strings[`${item.provider}Verify`]
                ? strings[`${item.provider}Verify`]
                : ''
              : strings[`${item.provider}`]
                ? strings[`${item.provider}`]
                : ''}
        </Text>
        {item.isCard && (
          <View style={styles.creditCards}>
            <Image style={styles.mastercardIcon} source={masterCardIcon} />
            <Image style={styles.visaIcon} source={visacardIcon} />
          </View>
        )}
      </View>
      <View style={{ flex: 1 }}>
        <Image style={styles.chevronRightThinIcon} source={chevronRightIcon} />
      </View>
    </View>
  );

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
    paddingBottom: 15,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
  },
  payMethod: {
    marginTop: 10,
    flexDirection: 'row',
    padding: 15,
    backgroundColor: colors.greyBoba,
    alignItems: 'center',
    borderRadius: 8,
  },
  creditCards: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  default: {
    width: 99,
    height: 30,
  },
  defaultIcon: {
    width: 99,
    height: 30,
  },
  error: {
    flex: 1,
    padding: 50,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  payTitle: {
    marginTop: 20,
    fontSize: 12,
    color: colors.greyLeia,
  },
  periodContainer: {
    width: '100%',
    maxWidth: 300,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  periodBox: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    height: 50,
    width: 50,
  },
  txtNum: {
    fontSize: 16,
    fontWeight: '500',
  },
  topText: {
    color: colors.white,
    fontSize: 16,
  },
});

const strings = new LocalizedStrings({
  en: {
    veo: 'VEO Account',
    ecommpay: 'Pay with card',
    ecommpayOut: 'Payout to card',
    ecommpayVerify: 'Verify with card',
    amountIsToSmall: 'Amount is too small, choose another provider or a larger amount',
    amountIsToBig: 'Amount is too big, choose another provider or a smaller amount',
    selectPayment: 'Select Payment Method',
    periodMonths: 'Months',
    month: 'month',
    total: 'Total',
    waiting: 'Waiting for response from',
  },
  sv: {
    veo: 'VEO Konto',
    ecommpay: 'Betala med kort',
    ecommpayOut: 'Utbetalning till kort',
    ecommpayVerify: 'Verifiera med kort',
    amountIsToSmall: 'Beloppet är för litet, välj en annan inlösare eller ange ett större belopp',
    amountIsToBig: 'Beloppet är för stort, välj en annan inlösare eller ange ett mindre belopp',
    selectPayment: 'Välj Betalningsmetod',
    periodMonths: 'Månader',
    month: 'månad',
    total: 'Totalt',
    waiting: 'Väntar på respons ifrån',
  },
});
