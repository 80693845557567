import {
  Platform,
  useWindowDimensions,
  StyleSheet
} from 'react-native';

export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  greyLeia: '#595959',
  greyLeia: '#8E8E93',
  greyLuke: '#DADADA',
  greySolo: '#EFEFF0',
  greyBoba: '#FAFAFA',
  orange: '#FC7B18',
  deepBlue: '#0B46AA',
  lightBlue: '#F1F3F5',
  blueGrey: '#EEF2F5',
  blueGreyDark: '#8794A1',
  redWarning: '#721C24',
  pink: '#f2d2c9',
  backgroundRed: '#FCE9ED',
  green: '#17B039',
  goGreen: '#3AC376',
};

export const mediaQueries = {
  mobile: 500,
  tablet: 850,
  modalMax: 700,
  desktop: 1024
};

export const screenStyles = {
  main: {
    flex: 1,
    alignItems: 'center',
    width: '100%'
  },
  body: {
    flex: 1,
    backgroundColor: colors.white,
    width: '100%',
  },
  innerBody: {
    flex: 1,
    width: '100%',
    maxWidth: mediaQueries.tablet,
    alignSelf: 'center'
  }
};

export const gutters = {
  pageGutter: 24,
  spacing: [4, 8, 16, 24, 32, 40, 48, 56, 64, 72]
};

export const fonts = {
  regular: 'Karla-Regular',
  medium: 'Karla-Medium',
  lightItalic: 'Karla-Regular',
  light: 'Karla-Regular',
  italic: 'Karla-Regular',
  mono: 'SplineSansMono-Regular',
  familjen: 'Familjen',
};

export const fontsize = {
  xsmall: 10,
  small: 12,
  regular: 14,
  medium: 16,
  large: 20,
  xlarge: 32,
  mega: 40,
};

export const useMobileSize = () => {
  const { width } = useWindowDimensions();
  return width < mediaQueries.tablet;
};

export const mainTitle = (isMobile, largerText = false) => {
  let baseFontSize = isMobile ? 24 : 32;

  if (largerText) {
    baseFontSize += isMobile ? 0 : 16;
  }

  return {
    fontFamily: fonts.medium,
    fontSize: baseFontSize,
    marginBottom: 10,
    marginTop: isMobile ? 10 : 24,
  };
};

const baseFontSize = gutters.spacing[2];

export const typo = {
  h1: baseFontSize * (1.618 ** 2), 
  h2: baseFontSize * (1.618 ** 1.5), 
  h3: baseFontSize * 1.618,      
  h4: baseFontSize * (1.618 ** 2 / 2),
  body: baseFontSize,              
  small: baseFontSize / 1.618,     
  tiny: baseFontSize / (1.618 ** 2) 
};

export const commonStyles = StyleSheet.create({
  infoText: {
    fontSize: fontsize.medium,
    lineHeight: 24,
    fontFamily: fonts.regular,
    color: colors.black,
    marginBottom: 20,
    whiteSpace: 'pre-line',
  },
  h1: {
    fontFamily: fonts.medium,
    fontSize: typo.h1,
  },
  h2: {
    fontFamily: fonts.medium,
    fontSize: typo.h2,
  },
  h3: {
    fontFamily: fonts.medium,
    fontSize: typo.h3,
  },
  h4: {
    fontFamily: fonts.medium,
    fontSize: typo.h4,
    letterSpacing: -0.2,
  },
  p: {
    fontFamily: fonts.regular,
    fontSize: typo.body,
    lineHeight: 24,
    whiteSpace: 'pre-line',
  },
  link: {
    fontFamily: fonts.regular,
    fontSize: typo.body,
    lineHeight: 24,
    whiteSpace: 'pre-line',
    color: colors.black, 
    textDecorationLine: 'underline',
    marginTop: gutters.spacing[0]
  },
  small: {
    fontFamily: fonts.regular,
    fontSize: typo.small,
  },
  tiny: {
    fontFamily: fonts.regular,
    fontSize: typo.tiny,
  },
  subTitle: {
    fontFamily: fonts.medium,
    fontSize: typo.h3,
    marginBottom: 10,
  },
  boldText: {
    fontFamily: fonts.regular,
    color: colors.black,
    marginBottom: 10,
    fontSize: fontsize.large,
    lineHeight: 24,
  }
});
