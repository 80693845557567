import React from 'react';
import LocalizedStrings from 'react-localization';
import {
  View,
  Text,
} from 'react-native';

import { colors, fonts, fontsize } from '../../theme';

const Roi = ({ extraStyles }) => <View style={[{
  flexDirection: 'row',
  alignItems: 'center',
  paddingVertical: 5,
  paddingHorizontal: 10,
  borderRadius: 8,
  backgroundColor: '#D05320',
  boxShadow: '3px 2px 20px -3px rgba(0, 0, 0, 0.05)',
  zIndex: 666,
}, extraStyles]}>
  <Text style={{
    fontSize: fontsize.small,
    color: colors.white,
    fontFamily: fonts.mono,
    marginRight:3,
  }}>
    {'200%'}
  </Text>
  <Text style={{
    fontSize: fontsize.xsmall,
    color: colors.white,
    fontFamily: fonts.regular
  }}>
    {strings.ROI}
  </Text>
</View>

export default Roi

const strings = new LocalizedStrings({
  en: {
    ROI: 'ROI',
  },
  sv: {
    ROI: 'ROI'
  }
});
