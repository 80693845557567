import React, { useRef, useState } from 'react';
import LocalizedStrings from 'react-localization';
import {
  View,
  Text,
  Switch,
  ScrollView,
  ActivityIndicator,
  StyleSheet
} from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import SponsorInput from '../../components/SponsorInput';
import ExpectedReturn from '../../components/ExpectedReturn';
import { GET_CURRENCY, PROFILE, ADD_SPONSORSHIP } from '../../gql/gqls';
import Avatar from '../../components/Avatar';
import RadioButton from '../../components/RadioButton';
import { myAccountVar } from '../../reactiveVariables';
import InputField from '../../components/InputField';
import WideButton from '../../components/misc/WideButton';
import ListPaymentMethods from '../../components/payment/ListPaymentMethods';
import { useQueryParams } from '../../helpers/utils';
import ProfileCard from '../../components/ProfileCard';
import { uploadFile } from '../../helpers/upload';
import Info from '../../components/Info';
import { sponsorTypes, targets } from '../../helpers/constants';
import config from '../../config';
import Ad from '../../components/Ad';
import PageLoader from '../../components/PageLoader';

import {
  mediaQueries,
  gutters,
  screenStyles,
  useMobileSize,
  fonts,
  fontsize,
  colors,
  commonStyles
} from '../../theme';
import { fontStyle, fontStyleLight } from '../../styles/fonts';
import { darkGrey } from '../../styles/colors';

export default function SponsorDistScreen() {

  const queryParams = useQueryParams();
  const expand = queryParams.get('expand');

  const user = useReactiveVar(myAccountVar);
  const currency = user.currency;

  const filePickRef = useRef(null);

  // Select sponsor type
  const [repayment, setRepayment] = useState(expand === sponsorTypes.SPECIFIC);
  const [ad, setAd] = useState(expand === sponsorTypes.AD);

  // REPAYMENT
  const [recurring, setRecurring] = useState(false);
  const [amount, setAmount] = useState(0);
  const [externalUrl, setExternalUrl] = useState('');

  // AD
  const [adTarget, setAdTarget] = useState(targets.SUBSCRIBERS);
  const [cpc, setCpc] = useState((100 * user.veoRate).toFixed(2).replace(/[.,]00$/, ""));
  const [adsAmount, setAdsAmount] = useState((5000 * user.veoRate).toFixed(0));
  const [maxDaily, setMaxDaily] = useState(adsAmount);

  // AD MESSAGE
  const [adImage, setAdImage] = useState(null);
  const [adTitle, setAdTitle] = useState('');
  const [adDescription, setAdDescription] = useState('');
  const [adUrl, setAdUrl] = useState('');
  const [adCta, setAdCta] = useState('');
  const [adPrivateMsg, setAdPrivateMsg] = useState('');

  // PAYMENT
  const [isCompany, setIsCompany] = useState(false);
  const [orgNr, setOrgNr] = useState(null);

  const openVeoWorldLink = (link) => {
    window.open(`https://veoworld.org/${link}.html`, '_blank');
  };

  const { data: currencyData } = useQuery(GET_CURRENCY);

  const isMobile = useMobileSize();
  const history = useHistory();
  const { id } = useParams();

  const { error, data, loading } = useQuery(PROFILE, {
    variables: {
      id: id,
    },
  });

  const [addSponsorship, { loading: addSponsorshipLoading, error: addSponsorshipError }] = useMutation(ADD_SPONSORSHIP);

  console.log(data);
  console.log(currency, 'currencycurrency');
  const {
    smlImage,
    name,
    followers = 0,
    supporters = 0,
    uploads = 0,
    visitorsDaily = 0,
    likes = 0,
    description,
    sellerId,
    sellerName,
    sellerImage,
    infoLink } = data?.profile || {};
  const { maxSponsor } = currencyData?.getOnlyCurrency || {
    maxSponsor: 10000
  };

  const invalid = repayment ? Number.parseFloat(amount) <= 0 : (Number.parseFloat(cpc) <= 0 || adTitle.length === 0 || (Number.parseFloat(maxDaily) < (5000 * user.veoRate).toFixed(0)))

  return <PageLoader loading={loading} error={error} >
    <View style={screenStyles.main}>
      <ScrollView
        scrollIndicatorInsets={{ right: 1 }}
        style={{
          maxWidth: mediaQueries.tablet,
          width: '100%',
          paddingHorizontal: isMobile ? gutters.pageGutter : 0,
          paddingBottom: 90
        }}>
        <ProfileCard
          image={smlImage}
          avatarSize={40}
          name={name}
          extrStyles={{ marginVertical: 20 }}
        />
        <View style={{
          backgroundColor: colors.greyBoba,
          padding: 20,
          paddingTop: isMobile ? 0 : 20
        }}>
          <Info label={strings.followers} value={followers} isMobile={isMobile} />
          <Info label={strings.subscribers} value={supporters} isMobile={isMobile} />
          <Info label={strings.uploads} value={uploads} isMobile={isMobile} />
          <Info label={strings.likes} value={likes} isMobile={isMobile} />
          <Info label={strings.uniqueVisitors} value={visitorsDaily ? visitorsDaily : 0} isMobile={isMobile} />
        </View>
        {description?.length > 0 && <View
          style={{
            marginVertical: 20
          }}>
          <Text style={styles.label}>
            {strings.description}
          </Text>
          <Text style={{
            backgroundColor: colors.greyBoba,
            padding: 20,
            borderRadius: 8,
            fontSize: fontsize.regular,
            color: colors.black,
            fontFamily: fonts.regular
          }}>
            {description}
          </Text>
        </View>}
        {infoLink?.length > 0 && <View style={{ flexShrink: 1 }} >
          <Text style={styles.label}>
            {strings.website}
          </Text>
          <a style={{ flexShrink: 1 }} rel="noreferrer" target="_blank" href={infoLink}>
            {infoLink}
          </a>
        </View>}
        {sellerId && <View
          style={{
            marginTop: 20
          }}>
          <Text style={styles.label}>
            {strings.seller}
          </Text>
          <ProfileCard
            image={sellerImage}
            avatarSize={40}
            name={sellerName}
            subText={strings.showProfile}
            subTextStyle={{ color: colors.deepBlue }}
            onPress={() => history.push(`/seller/${sellerId}`)}
            extrStyles={{ marginBottom: 20 }}
          />
        </View>}
        <Text style={{
          fontSize: fontsize.large,
          fontFamily: fonts.medium,
          marginTop: 20,
          marginBottom: isMobile ? 20 : 10
        }}>
          {strings.sponsor}
        </Text>
        <InfoBox text={strings.sponsorInfo} />
        <ExpandableCard
          profile={data?.profile}
          title={strings.repayment}
          infoText={`${strings.repaymentInfo1} ${name} ${strings.repaymentInfo2} ${name} ${strings.repaymentInfo3}`}
          expanded={repayment}
          setExpanded={() => {
            setAd(false);
            setRecurring(false);
            setRepayment(!repayment);
          }}
          extraStyles={{ marginBottom: 20 }}
        >
          <View style={{
            marginTop: 20,
            backgroundColor: colors.greyBoba,
            padding: 20,
            paddingTop: isMobile ? 0 : 20
          }}>
            <Info label={strings.sponsorGoal} value={"todo"} isMobile={isMobile} />
            <Info label={strings.leftToSponsor} value={"todo"} isMobile={isMobile} />
            <Info label={strings.expectedUsers} value={"todo"} isMobile={isMobile} />
            <Info label={strings.ros} value={"todo"} isMobile={isMobile} />
            <Info label={strings.nrSponsors} value={"todo"} isMobile={isMobile} />
          </View>
          <View style={!isMobile ? {
            marginTop: 20,
            alignItems: 'center',
            flexDirection: 'row'
          } : null}>
            <RadioButton
              buttonStyle={{ marginTop: isMobile ? 30 : 0 }}
              selected={!recurring}
              label={strings.oneTimePayment}
              onPress={() => setRecurring(false)}
            />
            <RadioButton
              buttonStyle={{
                marginTop: isMobile ? 20 : 0,
                marginLeft: isMobile ? 0 : 20
              }}
              selected={recurring}
              label={strings.monthlyPayment}
              onPress={() => setRecurring(true)}
            />
          </View>
          <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 20
          }}>
            <Text style={{
              color: colors.black,
              fontFamily: fonts.regular,
              fontSize: fontsize.small
            }}>
              {strings.sponsorAmount}
            </Text>
            <Text style={{
              color: colors.greyLeia,
              fontFamily: fonts.regular,
              fontSize: fontsize.small
            }}>
              {strings.max} {maxSponsor} {currency}
            </Text>
          </View>
          <SponsorInput
            keyboardType={'numeric'}
            currency={currency}
            input={amount + ''}
            onChangeText={input => {
              const inputAmount = parseFloat(input);
              const numericRegex = /^([0-9]{1,100})+$/;
              if (input.length === 0) {
                setAmount(0 + '');
              } else {
                if (numericRegex.test(inputAmount)) {
                  if (inputAmount <= maxSponsor) {
                    setAmount(inputAmount);
                  } else {
                    alert(strings.sponsorToLarge);
                  }
                } else {
                  setAmount(0);
                }
              }
            }} />
          <ExpectedReturn currency={currency} amount={amount} isMobile={isMobile} recurrent={recurring} />
          {/* <Text style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10
          }}>
            <Text style={{
              color: colors.black,
              fontFamily: fonts.regular,
              fontSize: fontsize.small
            }}>
              {strings.url}
            </Text>
            <Text style={{
              color: colors.greyLeia,
              fontFamily: fonts.regular,
              fontSize: fontsize.small
            }}>
              {strings.urlText}
            </Text>
          </Text>
          <InputField onChangeText={setExternalUrl} input={externalUrl}
            placeholder={strings.url} /> */}
        </ExpandableCard>
        <ExpandableCard
          profile={data?.profile}
          title={strings.sponsorAds}
          infoText={strings.sponsorAdsInfo}
          expanded={ad}
          setExpanded={() => {
            setAd(!ad);
            setRepayment(false);
            setRecurring(true);
          }}
        >
          <View>
            <Text style={[commonStyles.boldText, {
              fontSize: fontsize.medium,
              marginTop: 20
            }]}>
              {strings.budget}
            </Text>
            <Text style={commonStyles.infoText}>
              {strings.budgetInfo}
            </Text>
            <View
              style={{
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'flex-start' : 'center',
                justifyContent: isMobile ? 'flex-start' : 'space-between',
              }}>
              <SponsorInput
                keyboardType={'numeric'}
                label={
                  <Text
                    style={[commonStyles.infoText, { marginBottom: 0 }]}>{strings.costPerClick + " *"}
                  </Text>
                }
                currency={currency}
                input={cpc + ''}
                onChangeText={input => {
                  const inputAmount = parseFloat(input);
                  const numericRegex = /^([0-9]{1,100})+$/;
                  if (input.length === 0) {
                    setCpc(0 + '');
                  } else {
                    if (numericRegex.test(inputAmount)) {
                      if (inputAmount <= maxSponsor) {
                        setCpc(inputAmount);
                      } else {
                      }
                    } else {
                      setCpc(0);
                    }
                  }
                }
                }
              />
              <SponsorInput
                label={<Text
                  style={[commonStyles.infoText, { marginBottom: 0 }]}>{strings.maxBudget + " *"}</Text>}
                currency={currency}
                input={maxDaily + ''}
                invalid={Number.parseFloat(maxDaily) < cpc}
                invalidText={strings.invalidMaxDaily + ` ${cpc} ${currency}`}
                onChangeText={input => {
                  const inputAmount = parseFloat(input);
                  const numericRegex = /^([0-9.]{1,100})+$/;
                  if (input.length === 0) {
                    setMaxDaily(0 + '');
                  } else {
                    if (numericRegex.test(inputAmount)) {
                      if (inputAmount <= maxSponsor) {
                        setMaxDaily(inputAmount);
                      } else {
                      }
                    } else {
                      setMaxDaily(0);
                    }
                  }
                }
                }
              />
            </View>
            <Text style={[commonStyles.boldText, {
              fontSize: fontsize.medium,
              marginTop: 20
            }]}>
              {strings.targetAudience}
            </Text>
            <Text style={commonStyles.targetAudienceInfo}>
              {strings.targetAudienceInfo}
            </Text>
            <View style={{ marginVertical: 20 }}>
              <RadioButton
                selected={adTarget === targets.SUBSCRIBERS}
                label={`${strings.subscribers} (${supporters ? supporters : 0})`}
                onPress={() => setAdTarget(targets.SUBSCRIBERS)}
              />
              <RadioButton
                buttonStyle={{ marginTop: 10 }}
                selected={adTarget === targets.SUBSCRIBERS_FOLLOWERS}
                label={`${strings.subscribers} + ${strings.followers} (${supporters + followers})`}
                onPress={() => setAdTarget(targets.SUBSCRIBERS_FOLLOWERS)}
              />
              <RadioButton
                buttonStyle={{ marginTop: 10 }}
                selected={adTarget === targets.EVERYONE}
                label={strings.everyone}
                onPress={() => setAdTarget(targets.EVERYONE)}
              />
            </View>
            <Text style={[commonStyles.boldText, {
              fontSize: fontsize.medium,
              marginTop: 20
            }]}>
              {strings.sponsorMessage}
            </Text>
            <Text style={commonStyles.targetAudienceInfo}>
              {strings.sponsorMessageInfo}
            </Text>
            <Text style={{
              fontFamily: fonts.regular,
              color: colors.black,
              fontSize: fontsize.xsmall,
              marginTop: 20,
              marginBottom: 5
            }}>
              {strings.uploadCover}
            </Text>
            <Text style={{
              fontFamily: fonts.regular,
              color: colors.greyLeia,
              fontSize: fontsize.regular,
              marginBottom: 10,
            }}>
              {strings.minReq}
            </Text>
            <WideButton
              text={strings.browse}
              unfilled
              onPress={() => filePickRef?.current?.click()}
            />
            <input id="myInput"
              type="file"
              accept="image/*"
              ref={filePickRef}
              style={{ display: 'none' }}
              onChange={(event) => {
                event.stopPropagation();
                event.preventDefault();
                const res = event.target.files[0];
                setAdImage({
                  type: res.type,
                  name: res.name,
                  uri: URL.createObjectURL(res),
                  size: res.size
                });
              }}
            />
            <InputLabel label={strings.title} desc={strings.titleText} />
            <InputField onChangeText={setAdTitle} input={adTitle}
              placeholder={strings.titlePlaceholder} />
            <InputLabel label={strings.description} desc={strings.descriptionText} />
            <InputField onChangeText={setAdDescription} input={adDescription}
              placeholder={strings.description} />
            <InputLabel label={strings.adUrl} desc={strings.adUrlText} />
            <InputField onChangeText={setAdUrl} input={adUrl} placeholder={strings.adUrl} />
            <InputLabel label={strings.cta} desc={strings.ctaText} />
            <InputField onChangeText={setAdCta} input={adCta} placeholder={strings.cta}
              maxLength={30} />
            <InputLabel label={strings.privateMessage} desc={strings.privateMessageText} />
            <InputField onChangeText={setAdPrivateMsg} input={adPrivateMsg}
              placeholder={strings.privateMessage} />
            <Text style={{
              fontSize: fontsize.small,
              fontFamily: fonts.regular,
              marginBottom: 5,
              marginTop: 20,
              color: colors.black,
            }}>
              {strings.preview}
            </Text>
            <Ad
              adCta={adCta}
              adDescription={adDescription}
              adTitle={adTitle}
              sponsorImage={user.smlImage}
              sponsorName={user.name}
              adImage={adImage?.uri}
              adPrivateMsg={adPrivateMsg}
              adUrl={adUrl}
            />
          </View>
        </ExpandableCard>

        <Text style={{
          fontSize: fontsize.large,
          fontFamily: fonts.medium,
          marginTop: 50,
          marginBottom: isMobile ? 20 : 10
        }}>
          {strings.payment}
        </Text>
        {(repayment || ad) && <View style={[{
          padding: 20,
          borderColor: colors.greySolo,
          borderWidth: 1,
          marginTop: 20
        }]}>
          {repayment ?
            <View>
              <View style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                marginBottom: 10,
              }}>
                <Text style={[commonStyles.infoText, {
                  color: colors.deepBlue,
                  marginBottom: 0
                }]}>
                  {recurring ? strings.repayment + ' - ' + strings.monthlyPayment : strings.repayment}
                </Text>
                <Text style={[commonStyles.infoText, {
                  color: colors.deepBlue,
                  marginBottom: 0
                }]}>
                  {amount + ' ' + currency}
                </Text>
              </View>
              <View style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                marginBottom: 10,
              }}>
                <Text style={[commonStyles.infoText, {
                  color: colors.deepBlue,
                  marginBottom: 0
                }]}>
                  {strings.expectedReturn}
                </Text>
                <Text style={[commonStyles.infoText, {
                  color: colors.deepBlue,
                  marginBottom: 0
                }]}>
                  {amount * 2 + ' ' + currency}
                </Text>
              </View>
              <View style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row'
              }}>
                <Text style={{
                  fontSize: fontsize.medium,
                  fontFamily: fonts.medium,
                  color: colors.deepBlue,
                }}>
                  {strings.amountToPay}
                </Text>
                <Text style={{
                  fontSize: fontsize.large,
                  fontFamily: fonts.medium,
                  color: colors.deepBlue,
                }}>
                  {amount + ' ' + currency}{recurring ? strings.perMonth : ''}
                </Text>
              </View>
            </View>
            : ad ?
              <View>
                <View style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginBottom: 10,
                }}>
                  <Text style={[commonStyles.infoText, {
                    color: colors.deepBlue,
                    marginBottom: 0
                  }]}>
                    {strings.estimatedImpressionsPerDay}
                  </Text>
                  <Text style={[commonStyles.infoText, {
                    color: colors.deepBlue,
                    marginBottom: 0
                  }]}>
                    {cpc ? (maxDaily / cpc).toFixed(0) : 0}
                  </Text>
                </View>
                <View style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginBottom: 10,
                }}>
                  <Text style={[commonStyles.infoText, {
                    color: colors.deepBlue,
                    marginBottom: 0
                  }]}>
                    {strings.cpc}
                  </Text>
                  <Text style={[commonStyles.infoText, {
                    color: colors.deepBlue,
                    marginBottom: 0
                  }]}>
                    {cpc} {currency}
                  </Text>
                </View>
                <View style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginBottom: 10,
                }}>
                  <Text style={[commonStyles.infoText, {
                    color: colors.deepBlue,
                    marginBottom: 0
                  }]}>
                    {strings.maxBudget}
                  </Text>
                  <Text style={[commonStyles.infoText, {
                    color: colors.deepBlue,
                    marginBottom: 0
                  }]}>
                    {maxDaily} {currency}
                  </Text>
                </View>
                <View style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row'
                }}>
                  <Text style={{
                    fontSize: fontsize.medium,
                    fontFamily: fonts.medium,
                    color: colors.deepBlue,
                  }}>
                    {strings.recurringAmountToPayLater}
                  </Text>
                  <Text style={{
                    fontSize: fontsize.large,
                    fontFamily: fonts.medium,
                    color: colors.deepBlue,
                  }}>
                    {adsAmount > maxDaily ? adsAmount : maxDaily} {currency}
                  </Text>
                </View>
                {user.VAT && <View style={{
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                  marginBottom: 10,
                }}>
                  <Text style={[commonStyles.infoText, {
                    color: colors.deepBlue,
                    marginBottom: 0
                  }]}>
                    {strings.VAT} {user.VAT}%
                  </Text>
                </View>
                }
              </View>
              :
              <View />
          }

        </View>}
        <ExpandableCard
          profile={data?.profile}
          title={strings.isCompany}
          infoText={''}
          expanded={isCompany}
          setExpanded={() => {
            setIsCompany(!isCompany);
          }}
          extraStyles={{ marginBottom: 20 }}
        >
          <InputField onChangeText={setOrgNr} input={orgNr}
            placeholder={strings.enterOrgNr} />
        </ExpandableCard>
        <InfoBox text={strings.paymentInfo} />
        {invalid && (repayment || ad) &&
          <ErrorBox text={repayment ? strings.amountMissing : strings.invalid} />
        }
        <ListPaymentMethods
          amount={repayment ? amount : adsAmount > maxDaily ? adsAmount : maxDaily}
          currency={currency}
          type={repayment ? sponsorTypes.SPECIFIC : sponsorTypes.AD}
          orgNr={orgNr}
          VAT={user.VAT}
          name={repayment ? strings.repayment : strings.sponsorAds}
          visibleOthers={true}
          locale={user.language}
          recurring={recurring}
          verificationOnly={false}
          sponsoredId={id}
          externalUrl={externalUrl}
          adImage={adImage}
          adTitle={adTitle}
          adDescription={adDescription}
          adUrl={adUrl}
          adCta={adCta}
          adPrivateMsg={adPrivateMsg}
          adTarget={adTarget}
          cpc={Number.parseFloat(cpc)}
          maxDaily={Number.parseFloat(maxDaily)}
          repayment={repayment}
          user={user}
          disabled={true} //invalid
        />
        {/*<WideButton
          disabled={invalid}
          buttonStyle={{ marginTop: 40 }}
          loading={addSponsorshipLoading}
          text={'Pay'}
          onPress={async () => {
            if (repayment) {
              addSponsorship({
                variables: {
                  sponsoredId: id,
                  type: sponsorTypes.SPECIFIC,
                  recurring,
                  externalUrl,
                  sponsored: Number.parseFloat(amount),
                  currency,
                }
              });
            } else {
              const res = await addSponsorship({
                variables: {
                  sponsoredId: id,
                  type: sponsorTypes.AD,
                  recurring,
                  externalUrl,
                  currency,
                  adTitle,
                  adDescription,
                  adUrl,
                  adCta,
                  adPrivateMsg,
                  adTarget,
                  cpc: Number.parseFloat(cpc),
                  maxDaily: Number.parseFloat(maxDaily),
                }
              });
              console.log(res, 'res.addSponsorship,');
              if (adImage?.uri) {
                try {
                  await uploadFile({
                    file: adImage,
                    url: `${config.baseUrl}/adimage`,
                    headers: {
                      authentication: user?.token || global.account?.token || null,
                      id: res.data.addSponsorship,
                      xdate: new Date().getTime()
                    }
                  });
                } catch (e) {
                  console.log(e);
                }
              }
            }
          }}
        />*/}

        <View style={{
          marginTop: 20,
          marginBottom: 40,
        }}>
          <Text style={{
            ...fontStyleLight,
            fontSize: fontsize.small,
            color: darkGrey,
          }}>
            {strings.agreement}{' '}
            <Text
              onPress={() => openVeoWorldLink('termsofuse')}
              style={{
                ...fontStyle,
                fontSize: fontsize.small,
                color: darkGrey,
                textDecorationLine: 'underline',
              }}
            >
              {strings.termsOfUse}
            </Text>
            {', '}
            <Text
              onPress={() => openVeoWorldLink('privacypolicy')}
              style={{
                ...fontStyle,
                fontSize: fontsize.small,
                color: darkGrey,
                textDecorationLine: 'underline',
              }}
            >
              {strings.privacyPolicy}
            </Text>
            {` ${strings.and} `}
            <Text
              onPress={() => openVeoWorldLink('cof')}
              style={{
                ...fontStyle,
                fontSize: fontsize.small,
                color: darkGrey,
                textDecorationLine: 'underline',
              }}
            >
              {strings.cof}
            </Text>
            {'.'}
          </Text>
        </View>
      </ScrollView>
    </View>
  </PageLoader>
};

const InfoBox = ({ text }) => <View style={{
  backgroundColor: colors.lightBlue,
  padding: 10,
}}>
  <Text style={{
    fontFamily: fonts.regular,
    color: colors.deepBlue,
    fontSize: fontsize.regular
  }}>
    {text}
  </Text>
</View>;

const ErrorBox = ({ text }) => <View style={{
  backgroundColor: colors.pink,
  padding: 10,
  marginTop: 5
}}>
  <Text style={{
    fontFamily: fonts.regular,
    color: colors.redWarning,
    fontSize: fontsize.regular
  }}>
    {text}
  </Text>
</View>;

const ExpandableCard = ({ title, infoText, children, expanded, setExpanded, profile, extraStyles }) =>
  <View style={[{
    padding: 20,
    borderColor: colors.greySolo,
    borderWidth: 1,
    marginTop: 20
  }, extraStyles]}>
    <View style={{
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: 10,
    }}>
      <Text style={{
        fontSize: fontsize.medium,
        fontFamily: fonts.regular,
      }}>
        {title}
      </Text>
      <Switch
        trackColor={{
          true: colors.goGreen,
          false: colors.greyLeia
        }}
        onValueChange={() => setExpanded(!expanded)}
        value={expanded}
      />
    </View>
    <Text style={[commonStyles.infoText, { marginBottom: 0 }]}>
      {infoText}
    </Text>
    {expanded && children}
  </View>;

const InputLabel = ({ label, desc }) => <Text style={{
  marginTop: 20,
  marginBottom: 5,
  fontSize: fontsize.small,
  fontFamily: fonts.regular
}}>
  <Text style={{
    color: colors.black
  }}>
    {label}
  </Text>
  <Text style={{
    color: colors.greyLeia
  }}>
    {desc}
  </Text>
</Text>;

const styles = StyleSheet.create({
  label: {
    marginBottom: 10,
    fontSize: fontsize.regular,
    color: colors.black,
    fontFamily: fonts.regular
  }
})

const strings = new LocalizedStrings({
  en: {
    followers: 'Followers',
    subscribers: 'Subscribers',
    uploads: 'Uploads',
    cpc: 'Cost Per Click',
    uniqueVisitors: 'Visits',
    website: 'Website',
    likes: 'Likes',
    sponsor: 'Sponsor',
    sponsorInfo: 'Choose to “Sponsor with re-payment” or “Sponsor with Ads”.',
    seller: 'Connected Seller/Manager',
    repayment: 'Sponsor with re-payment:',
    sponsorAds: 'Sponsor with ads',
    repaymentMonthly: 'Monthly sponsor with re-payment',
    repaymentInfo1: 'You get 2 times the sponsored amount back. 30% of your sponsored amount goes to',
    repaymentInfo2: '. You will also get showcased as a partner in',
    repaymentInfo3: 'channel.',
    sponsorAdsInfo: 'Advertise in collaboration with the channel owner. Your message reaches all of the channel\'s subscribers in the form of a push notification. Via the push notification, it is possible to direct traffic to any URL.',
    oneTimePayment: 'One-time payment',
    monthlyPayment: 'Recurring monthly payment',
    sponsorAmount: 'Sponsor amount:',
    url: 'URL',
    urlText: ' - As a sponsor you will be seen as a Partner. This will be available in the channel owners partner section. Your channel picture and name will be visible. Here you can add an external link. If no link is provided we will redirect any traffic to your VEO-profile.',
    payment: 'Payment',
    paymentInfo: 'Once you pick a payment option, you will be redirected from this page.',
    max: 'max',
    budget: 'Budget',
    budgetInfo: 'Enter your daily maximum budget and cost per click. You will be charged recurrently every time your balance gets below your max daily budget.',
    costPerClick: 'Cost per Click:',
    maxBudget: 'Max Daily Budget:',
    everyone: 'Everyone',
    targetAudience: 'Target Audience',
    targetAudienceInfo: 'Subscribers receive the ad as a push notification. For others, the ad is available under Partners on the profile page.',
    sponsorMessage: 'Sponsor Message',
    sponsorMessageInfo: 'Create your sponsor message. This is what recipients will see when they choose to take part in your message. ',
    browse: 'Browse',
    uploadCover: 'Upload Cover Image',
    minReq: 'Minimum requirements: 295px x 158px',
    title: 'Title *',
    titleText: ' - Short title for your message.',
    titlePlaceholder: 'E.g 20% discount',
    description: 'Description',
    descriptionText: ' - Will be visible for your target audience.',
    adUrl: 'URL',
    adUrlText: ' - If left empty, we will link to your VEO profile.',
    cta: 'Call to Action',
    ctaText: ' - Maximum 30 characters.',
    privateMessage: 'Private Message',
    privateMessageText: ' - Will only be visible for the channel owner.',
    sponsorToLarge: 'Sponsor amount is to large. Please contact us directly at finance@veoworld.org if you want to sponsor a larger amount.',
    error: 'Something went wrong. Try to refresh the page',
    preview: 'Preview',
    isCompany: 'I\'m making this payment as a company',
    enterOrgNr: 'Enter Your Company VAT Number',
    amountToPay: 'Amount to pay:',
    perMonth: '/month',
    expectedReturn: 'Expected return per payment:',
    recurringAmountToPayLater: 'Recurring amount to pay if approved by channel owner:',
    estimatedImpressionsPerDay: 'Estimated Impressions per Day:',
    agreement: `By continuing with this purchase and selecting a payment method you agree to VEO's`,
    termsOfUse: 'Terms of Use (EULA)',
    privacyPolicy: 'Privacy Policy',
    and: 'and',
    cof: 'COF',
    invalid: 'Required fields missing (*)',
    amountMissing: 'Fill in amount to sponsor',
    invalidMaxDaily: 'Must be higher or equal to:',
    VAT: 'Price including VAT tax',
    showProfile: 'Show channel',
    sponsorGoal: 'Sponsorship Goal',
    leftToSponsor: 'Left to Sponsor',
    expectedUsers: 'Expected Number of Users',
    ros: 'Return of Sponsorship',
    nrSponsors: 'Number of sponsors',
  },
  sv: {
    followers: 'Följare',
    subscribers: 'Prenumeranter',
    uploads: 'Uppladdningar',
    cpc: 'Kostnad per klick',
    sponsor: 'Sponsra',
    uniqueVisitors: 'Besökare',
    website: 'Webbplats',
    likes: 'Gillar',
    sponsorInfo: 'Välj "Sponsra med återbetalning" eller "Sponsra med annonser".',
    seller: 'Ansluten säljare',
    repayment: 'Sponsra med återbetalning',
    repaymentMonthly: 'Månadssponsring med återbetalning',
    repaymentInfo1: 'Du får två gånger det sponsrade beloppet tillbaka. 30% av ditt sponsrade belopp går till',
    repaymentInfo2: '. Du kommer också att presenteras som en partner i',
    repaymentInfo3: 'kanalen.',
    sponsorAds: 'Sponsra med annonser',
    sponsorAdsInfo: 'Annonsera i samarbete med kanalägaren. Ditt meddelande når alla kanalens prenumeranter i form av en push-avisering. Via push-meddelandet är det möjligt att dirigera trafik till valfri URL.',
    oneTimePayment: 'Engångsbetalning',
    monthlyPayment: 'Återkommande månadsbetalning',
    sponsorAmount: 'Sponsor belopp:',
    url: 'URL',
    urlText: ' - Som sponsor kommer du att ses som en partner. Detta kommer att visas på kanalägarens sida. Din profilbild och ditt namn kommer att synas. Här kan du lägga till en extern länk. Om ingen länk är angedd kommer vi skicka trafiken till din VEO-profil.',
    payment: 'Betalning',
    paymentInfo: 'När du väl har valt ett betalningsalternativ kommer du att omdirigeras från den här sidan.',
    max: 'max',
    budget: 'Budget',
    budgetInfo: 'Ange din dagliga högsta budget och kostnad per klick. Du debiteras per dag och beloppet baseras på varje unik mottagare som väljer att ta emot ditt meddelande.',
    costPerClick: 'Kostnad per klick, Maxbudget',
    maxBudget: 'Max daglig budget',
    everyone: 'Alla',
    targetAudience: 'Målgrupp',
    targetAudienceInfo: 'Prenumeranter får annonsen som en push-avisering. För andra är annonsen tillgänglig under Partners på profilsidan.',
    sponsorMessage: 'Sponsor Meddelande',
    sponsorMessageInfo: 'Skapa ditt sponsormeddelande. Detta är vad mottagarna kommer att se när de väljer att delta i ditt budskap.',
    browse: 'Bläddra',
    uploadCover: 'Ladda upp bild',
    minReq: 'Minimikrav: 295 pixlar x 158 pixlar',
    title: 'Title*',
    titleText: ' - kort titel för ditt erbjudande.',
    titlePlaceholder: 'T ex: 20% rabatt',
    description: 'Beskrivning',
    descriptionText: ' - synlig för din målgrupp.',
    adUrl: 'URL',
    adUrlText: ' - Vi länkar till din VEO profil ifall detta fältet är tomt.',
    cta: 'Call to Action',
    ctaText: ' - Max 30 tecken',
    privateMessage: 'Privat meddelande',
    privateMessageText: ' - Endast synlig för kanal ägaren.',
    sponsorToLarge: 'Sponsrat belopp är för stort. Vänligen kontakta oss direkt via finance@veoworld.org om du vill sponsra ett större belopp.',
    error: 'Något blev fel. Pröva ladda om sidan.',
    preview: 'Förhandsvisning',
    isCompany: 'Jag gör denna betalning som företag',
    enterOrgNr: 'Fyll i Ditt Företags VAT/momsnummer',
    amountToPay: 'Belopp att betala',
    perMonth: '/månad',
    expectedReturn: 'Förväntad återbetalning per betalning',
    recurringAmountToPayLater: 'Återkommande belopp att betala om godkänd av kanalägaren',
    estimatedImpressionsPerDay: 'Uppskattat antal impressions per dag',
    agreement: `Genom att fortsätta med detta köp och välja betalmetod godkänner du VEO's`,
    termsOfUse: 'Terms of Use (EULA)',
    privacyPolicy: 'Privacy Policy',
    and: 'och',
    cof: 'COF',
    invalid: 'Fyll i obligatoriska fält (*)',
    amountMissing: 'Lägg till belopp att sponsra',
    invalidMaxDaily: 'Måste vara lika eller mer än:',
    VAT: 'Pris är inklusive moms',
    showProfile: 'Visa kanal',
    sponsorGoal: 'Sponsringsmål',
    leftToSponsor: 'Kvar att sponsra',
    expectedUsers: 'Förväntade användare',
    ros: 'Vinst av sponsring',
    nrSponsors: 'Antal sponsorer',
  }
});
