import React, { useRef, useEffect, useState } from 'react';
import LocalizedStrings from 'react-localization';
import {
  View,
  Text,
  ScrollView,
  StyleSheet
} from 'react-native';
import { useReactiveVar } from '@apollo/client';
import WideButton from '../../components/misc/WideButton';
import { myAccountVar } from '../../reactiveVariables';
import { commonStyles, colors, mediaQueries, gutters, useMobileSize, screenStyles, fonts } from '../../theme';
import { useHistory } from 'react-router-dom';
import LinearGradient from 'react-native-web-linear-gradient';

export default function StartScreen() {
  const myAccount = myAccountVar();
  const user = useReactiveVar(myAccountVar);
  const history = useHistory();

  return (
    <LinearGradient style={{flex: 1, width: '100%'}} colors={['rgba(240, 238, 248, 1)', 'rgba(255, 255, 255, 1)']}>
    <View style={screenStyles.main}>
      <ScrollView
        scrollIndicatorInsets={{ right: 1 }}
        style={styles.wrapper}>
        {myAccount.isSeller && myAccount.isSellerActivated ? (
          <>
            <Text style={commonStyles.h3}>{strings.sellerMessage}</Text>
            <View style={styles.container}>
              <Text style={[commonStyles.h4, { marginBottom: gutters.spacing[0] }]}>{strings.guideTitle1}</Text>
              <Text style={[commonStyles.p, { marginBottom: gutters.spacing[2] }]}>{strings.guideText1}</Text>
              <WideButton
              unfilled
              small
                  onPress={() => history.push(`/share`)}
                  text={strings.share}
                  buttonStyle={{ marginBottom: gutters.spacing[4] }}
                />
              <Text style={[commonStyles.h4, { marginBottom: gutters.spacing[0] }]}>{strings.guideTitle2}</Text>
              <Text style={[commonStyles.p, { marginBottom: gutters.spacing[2] }]}>{strings.guideText2}</Text>
              <WideButton
              unfilled
              small
                onPress={() => history.push(`/shareAnalytics`)}
                text={strings.shareAnalytics}
                buttonStyle={{ marginBottom: gutters.spacing[4], fontFamily: fonts.medium }}
              />
              <Text style={[commonStyles.h4, { marginBottom: gutters.spacing[0] }]}>{strings.guideTitle3}</Text>
              <Text style={[commonStyles.p, { marginBottom: gutters.spacing[2] }]}>{strings.guideText3}</Text>
            </View>
          </>
        ) 
        : myAccount.isSeller && !myAccount.isSellerActivated ? (
          <>
            <Text style={[commonStyles.h2, {marginBottom: gutters.spacing[2]}]}>
              {strings.title}<Text style={[commonStyles.h2, {color: colors.deepBlue}]}>{' ' + myAccount.name}</Text>{' ' + strings.title2}
            </Text>
            <Text style={commonStyles.h3}>{strings.rookieMessage}</Text>
            <View style={styles.container}>
              <Text style={[commonStyles.h2, { marginBottom: gutters.spacing[4] }]}>{strings.rookieTitle}</Text>
              <Text style={[commonStyles.h4, { marginBottom: gutters.spacing[0] }]}>{strings.rookieTitle1}</Text>
              <Text style={[commonStyles.p, { marginBottom: gutters.spacing[2] }]}>{strings.rookieText1}</Text>
              <WideButton
              unfilled
              small
                  onPress={() => history.push(`/wiki`)}
                  text={strings.wiki}
                  buttonStyle={{ marginBottom: gutters.spacing[4] }}
                />
              <Text style={[commonStyles.h4, { marginBottom: gutters.spacing[0] }]}>{strings.rookieTitle2}</Text>
              <Text style={[commonStyles.p, { marginBottom: gutters.spacing[2] }]}>{strings.rookieText2}</Text>
              <WideButton
              unfilled
              small
                onPress={() => history.push(`/knowledge-test`)}
                text={strings.quiz}
                buttonStyle={{ marginBottom: gutters.spacing[1], fontFamily: fonts.medium }}
              />
              <Text style={[commonStyles.small, { fontFamily: fonts.mono, color: colors.greyLeia }]}>{strings.rookieText3}</Text>
            </View>
          </>
        ) : (
          <>
            <Text style={commonStyles.h3}>{strings.outsiderMessage}</Text>
            <View style={styles.container}>
              <Text style={[commonStyles.h4, { marginBottom: gutters.spacing[0] }]}>{strings.guideTitle1}</Text>
              <Text style={[commonStyles.p, { marginBottom: gutters.spacing[2] }]}>{strings.guideText1}</Text>
              <WideButton
              unfilled
              small
                  onPress={() => history.push(`/share`)}
                  text={strings.share}
                  buttonStyle={{ marginBottom: gutters.spacing[4] }}
                />
              <Text style={[commonStyles.h4, { marginBottom: gutters.spacing[0] }]}>{strings.guideTitle2}</Text>
              <Text style={[commonStyles.p, { marginBottom: gutters.spacing[2] }]}>{strings.guideText2}</Text>
              <WideButton
              unfilled
              small
                onPress={() => history.push(`/shareAnalytics`)}
                text={strings.shareAnalytics}
                buttonStyle={{ marginBottom: gutters.spacing[4], fontFamily: fonts.medium }}
              />
            </View>
          </>
        )
        }
      </ScrollView>
    </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    maxWidth: mediaQueries.tablet,
    width: '100%',
    marginBottom: gutters.spacing[10],
    marginTop: gutters.spacing[5],
    paddingHorizontal: gutters.pageGutter,
},
container: {
  backgroundColor: colors.white, 
  padding: gutters.spacing[4], 
  marginTop: gutters.spacing[3], 
  marginBottom: gutters.spacing[3], 
  borderRadius: gutters.spacing[1],
  shadowColor: colors.black,
  shadowOffset: {
      width: 0,
      height: 10,
  },
  shadowOpacity: 0.05,
  shadowRadius: gutters.spacing[3],
  elevation: 4,
},
});



const strings = new LocalizedStrings({
  en: {
    title: 'Greetings',
    title2: 'welcome to VEO Partner!',
    titleInfoFeatures: 'VEO Partner is a tool for sales',
    titleHelp: 'How can our sales team assist you effectively?',
    rookieMessage: `Great to see you here! You are not yet approved as a sales representative. To unlock all features, let's start with a quick training session.`,
    outsiderMessage: 'Great to see you here! Veopartner allow you to sponsor and invite users through link sharing. For full access to all features you need access to a sales account.',
    rookieTitle: 'How to become a sales representative',
    rookieTitle1: 'Step 1: Educate Yourself',
    rookieText1: `To be a successful representative, it's essential to pass our knowledge test.`,
    rookieTitle2: 'Step 2: Take Our Knowledge Test',
    rookieText2: 'Becoming an esteemed representative requires passing our test.',
    rookieText3: `Don’t worry if you make mistakes during the test; you'll have the opportunity to retake it.`,
    wiki: 'Wiki',
    quiz: 'Knowledge Test',
    sellerMessage: `🎉 Congratulations! You are approved as a sales representative.`,
    guideTitle: 'Step By Step Guide',
    guideTitle1: 'Step 1: Create new users and share links',
    guideText1: `Invite new customers to the platform through the links on the Share page and get rewarded. Content creators, content owners, people that can invite others and sponsors.`,
    guideTitle2: 'Step 2: Check your progress',
    guideText2: 'Keep track through Analytics how much you have earned and the impact your sales have had.',
    guideTitle3: 'Step 3: Follow up, your customers success is your success',
    guideText3: `To be a successful sales representative, it's essential to help your customers. It's your responsibility to answer their questions, help them to setup their channel and to continously follow up on their progress.`,
    shareAnalytics: 'Analytics',
    share: 'Share',
  },
  sv: {
    title: 'Välkommen',
    title2: 'till VEO Partner!',
    sellerMessage: 'Kul att du är tillbaka! Glöm inte att att följa din framgång genom "Analys" och att dela dina personliga länkar genom "Dela".',
    rookieMessage: 'Kul att du är här! För full tillgång till alla funktioner, börja med en kort introduktionsutbildning.',
    outsiderMessage: 'Kul att du är här! Veopartner låter dig sponsra och bjuda in användare via länkdelning. För full tillgång till alla funktioner behöver du tillgång till ett säljkonto.',
    rookieTitle: 'Kom Igång Guide',
    rookieTitle1: 'Steg 1: Lär dig grunderna',
    rookieText1: 'Vi rekommenderar att du bekantar dig med vår wiki för att förbereda dig inför kunskapstestet.',
    rookieTitle2: 'Steg 2: Genomför kunskapstestet',
    rookieText2: 'Ett genomfört kunskapstest är nödvändigt för att bli en fullfjädrad representant och kunna representera VEO på bästa sätt.',
    rookieText3: 'Om du skulle svara fel på testet, har du möjlighet att göra om det.',
    wiki: 'Wiki',
    quiz: 'Kunskapstest',
    sellerMessage: `🎉 Grattis! Du är godkänd som sälj-representant.`,
    guideTitle: 'Steg För Steg Guide',
    guideTitle1: 'Steg 1: Skapa nya användare och dela länkar',
    guideText1: `Bjud in utvalda nya användare till plattformen via länkarna på Dela sidan och bli belönad. Innehållsakapare, innehållsägare, personer som kan bjuda in andra, och sponsorer.`,
    guideTitle2: 'Step 2: Följ dina framgångar',
    guideText2: 'Håll koll på hur mycket du har tjänat via Analys och se vilka resultat din försäljning genererat.',
    guideTitle3: 'Step 3: Följ upp, dina kunders framgång är din framgång',
    guideText3: `För att bli en framgångsrik sälj-representant är det viktigt att du hjälper dina kunder komma igång. Det är ditt ansvar att svara på deras frågor, sätta upp deras kanaler samt kontinuerligt följa upp.`,
    shareAnalytics: 'Analys',
    share: 'Dela',
  },
});