import { userFragment } from './gqlFragments';
import gql from 'graphql-tag';

export const LOGIN_MUTATION = gql`
  mutation ($token: String, $deviceId: String!, $deviceName: String!, $country: String, $language: String) {
    loginToken(token: $token, deviceId: $deviceId, deviceName: $deviceName, country: $country, language: $language) {
        ${userFragment}
    }
  }
`;

export const SIGNIN_MUTATION = gql`
  mutation ($name: String!, $password: String!, $deviceId: String!, $deviceName: String!, $country: String, $language: String) {
    login(name: $name, password: $password, deviceId: $deviceId, deviceName: $deviceName, country: $country, language: $language) {
        ${userFragment}
    }
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation ($name: String!, $email: String!, $password: String!, $verifyId: String, $code: String, $deviceId: String, $deviceName: String, $country: String, $language: String, $hcaptchaToken: String, $shortId: String, $isSeller: Boolean) {
    signupPartner(name: $name, email: $email, password: $password, verifyId: $verifyId, code: $code, deviceId: $deviceId, deviceName: $deviceName, country: $country, language: $language, hcaptchaToken: $hcaptchaToken, shortId: $shortId, isSeller: $isSeller) {
        ${userFragment}
    }
  }
`;
