import React from 'react';
import {
  Image
} from 'react-native';

import fallback from '../../assets/icons/user-icon-round.png'
import { myAccountVar } from '../../reactiveVariables';
import config from '../../config';

const Avatar = ({ image, size, localUserImage, extraStyles }) => {
  const account = myAccountVar();
  return <Image style={[{
    height: size,
    width: size,
    borderRadius: size / 2
  }, extraStyles]}
    source={localUserImage ?
      { uri: localUserImage } :
      image ?
        { uri: `${config.baseUrl}/u/${image}/${account.accessToken}` }
        : fallback
    }
  />
}
export default Avatar;