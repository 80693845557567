import App from './App';
import { AppRegistry } from 'react-native';
import './assets/fonts.css'

AppRegistry.registerComponent('App', () => App);

AppRegistry.runApplication('App', {
  initialProps: {},
  rootTag: document.getElementById('root'),
});
