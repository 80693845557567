export const userFragment = `
  id
  hasNotSignedUp
  wId
  shortId
  channelId
  channelName
  channelImage
  image
  smlImage
  cover
  fullName
  email
  address
  name
  infoEmail
  infoPhone
  infoAddress
  infoLink
  description
  quality
  token
  accessToken
  country
  language
  currency
  veoFeeId
  veoFeePrice
  veoRate
  VAT
  hasPin
  hasKyc
  hasPaid
  isDist
  isFollow
  isSponsor
  isSponsorVisible
  isVerified
  isFoundation
  collections
  offers
  services
  supporters
  uploads
  followers
  following
  date
  percent
  prAuth
  veoFeeRenew
  veoFeeExpire
  veoFeeEndDate
  fullEarningSum
  fullEarningShareSum
  totalPayout
  notifyAll
  notifyNewSubscriber
  notifyLike
  notifyFollowInquiry
  notifyAcceptedFollowInquiries
  notifyComments
  notifyShare
  notifyTags
  notifyPayment
  notifyCharging
  notifyNewUpload
  notifyService
  notifyCollection
  notifyVoting
  countries {
    code
    currency
    currencyRate
    veoRate
  }
  toOldVersion
  apiToken
  apiWhitelist
  isSeller
  isSellerActivated
  isSellerAdmin
  sellerId
`;

export const profileFragment = `
  id
  smlImage
  name
  region
  country
  followers
  uploads
  supporters
  isSeller
  isSponsorVisible
  isVerified
  isFoundation
  sellerId
  sellerName
  sellerImage
  likes
  description
  infoLink
  infoPhone
  infoEmail
`

export const partnerFragment = `
  id
  
`
