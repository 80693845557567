import React, { useState } from 'react';
import LocalizedStrings from 'react-localization';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Text,
  ScrollView,
  useWindowDimensions
} from 'react-native';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';
import { signout } from '../../helpers/auth';

import { myAccountVar } from '../../reactiveVariables';
import { colors, fonts, useMobileSize, mediaQueries, gutters } from '../../theme';

import logoutIcon from '../../assets/icons/sign-out.png';
import hamburgIcon from '../../assets/icons/hamburger.png';
import closeIcon from '../../assets/icons/close-black.png';
import logo from '../../assets/images/veoLogo-black.png';

export const NAVBAR_HEIGHT = 61;

export default function AppBar() {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const isMobile = useMobileSize();
  const location = useLocation();
  const myAccount = myAccountVar();
  const client = useApolloClient();
  const { path } = useRouteMatch();

  const { width: screenWidth, height: screenHeight } = useWindowDimensions();

  return (<React.Fragment>
    <View
      nativeID='topbar'
      style={[
        styles.container,
        {
          top: 0,
          position: 'fixed',
          backgroundColor: colors.white,
          zIndex: 10,
          borderBottomWidth: 1,
          borderBottomColor: colors.greyLuke
        },
      ]}
    >
      <TouchableOpacity onPress={() => history.push('/start')}>
        <Image
          style={{
            width: 43.5,
            height: 16.5,
            marginLeft: 20
          }}
          source={logo}
        />
      </TouchableOpacity>
      {!isMobile && <React.Fragment>
        <View style={{
          flexDirection: 'row',
          alignItems: 'center'
        }}>
          <NavItems
            location={location}
            history={history}
            isMobile={isMobile}
            setExpanded={setExpanded}
          />
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 'auto', marginRight: 20 }}>
          <UserItems
            myAccount={myAccount}
            client={client}
            history={history}
            path={path}
            isMobile={isMobile}
          />
        </View>
      </React.Fragment>}
      {isMobile && <TouchableOpacity
        style={{
          marginLeft: 'auto',
          marginRight: 0
        }}
        hitSlop={{ top: 10, bottom: 10, left: 10, right: 20 }}
        onPress={() => setExpanded(!expanded)}
      >
        <Image
          style={{
            width: expanded ? 16 : 20,
            height: expanded ? 16 : 16
          }}
          source={expanded ? closeIcon : hamburgIcon}
        />
      </TouchableOpacity>}
    </View>
    {expanded && isMobile && <View
      style={{
        position: 'fixed',
        top: NAVBAR_HEIGHT,
        backgroundColor: colors.white,
        flex: 1,
        zIndex: 100,
        height: screenHeight * 2,
        width: screenWidth,
        paddingHorizontal: gutters.pageGutter
      }}>
      <NavItems
        location={location}
        history={history}
        isMobile={isMobile}
        setExpanded={setExpanded}
      />
      <UserItems
        myAccount={myAccount}
        client={client}
        history={history}
        path={path}
        isMobile={isMobile}
      />
      <View style={{
        backgroundColor: colors.white,
        flex: 1,
        zIndex: 100,
        minHeight: screenHeight,
      }} />
    </View>}
  </React.Fragment>
  );
};

const NavItems = ({ location, history, isMobile, setExpanded }) => <React.Fragment>
  <TopBarItem
    onPress={() => {
      history.push('/start');
      setExpanded(false);
    }}
    text={strings.start}
    selected={location.pathname.includes('start')}
    isMobile={isMobile}
  />
  <TopBarItem
    onPress={() => {
      history.push('/share');
      setExpanded(false);
    }}
    text={strings.share}
    selected={location.pathname.includes('share') && !location.pathname.includes('shareAnalytics')}
    isMobile={isMobile}
  />
  <TopBarItem
    onPress={() => {
      history.push('/shareAnalytics');
      setExpanded(false);
    }}
    text={strings.shareAnalytics}
    selected={location.pathname.includes('shareAnalytics')}
    isMobile={isMobile}
  />
  {/*<TopBarItem
    onPress={() => {
      history.push('/discover');
      setExpanded(false);
    }}
    text={strings.discover}
    selected={location.pathname.includes('discover')}
    isMobile={isMobile}
  />*/}
  <TopBarItem
    onPress={() => {
      history.push('/mySponsorships');
      setExpanded(false);
    }}
    text={strings.mySponsor}
    selected={location.pathname.includes('mySponsorships')}
    isMobile={isMobile}
  />
  <TopBarItem
    onPress={() => {
      history.push('/wiki');
      setExpanded(false);
    }}
    text={strings.wiki}
    selected={location.pathname.includes('wiki')}
    isMobile={isMobile}
  />
</React.Fragment>

const UserItems = ({
  myAccount,
  client,
  history,
  path,
  isMobile
}) => <React.Fragment>
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: isMobile ? 10 : 0,
      paddingTop: isMobile ? 20 : 0
    }}>
      <Text style={{
        color: isMobile ? colors.black : colors.greyLeia,
        fontSize: isMobile ? 20 : 14,
        fontFamily: fonts.regular
      }}>
        {strings.loggedInAs}
      </Text>
      <Text style={{
        color: colors.black,
        fontSize: isMobile ? 20 : 14,
        fontFamily: fonts.mono,
      }}>
        {myAccount.name}
      </Text>
    </View>
    <TouchableOpacity
    
      style={[{
        marginLeft: isMobile ? 0 : 20,
      }, isMobile ? {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 10,
        marginRight: 10
      } :
        null]}
      hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
      onPress={() => signout({ client, history, path })
      }>
      {isMobile && <Text style={{
        color: colors.black,
        fontSize: 20,
        fontFamily: fonts.regular
      }}>
        {strings.logout}
      </Text>}
      <Image
        style={{ height: 20, width: 20 }}
        source={logoutIcon}
      />
    </TouchableOpacity>
  </React.Fragment>

const TopBarItem = ({ onPress, selected, text, isMobile }) => <TouchableOpacity onPress={onPress}>
  <Text style={[{
    paddingVertical: isMobile ? 20 : 0,
    fontSize: isMobile ? 20 : 16,
    marginLeft: isMobile ? 0 : 40,
    fontFamily: fonts.regular,
    color: colors.black,
    textDecorationLine: selected ? 'underline' : 'none'
  }, isMobile ? {
    borderBottomColor: colors.greyLuke,
    borderBottomWidth: 1
  } :
    null]}>
    {text}
  </Text>
</TouchableOpacity>

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    width: '100%',
    padding: 20,
    height: NAVBAR_HEIGHT
  },
  hamburger: {
    width: 20,
    height: 14,
  },
});

const strings = new LocalizedStrings({
  en: {
    start: 'Start',
    discover: 'Discover',
    loggedInAs: 'Logged in as: ',
    signOut: 'Log out',
    mySponsor: 'Sponsorships',
    shareAnalytics: 'Analytics',
    share: 'Share',
    logout: 'Log out',
    wiki: 'Wiki'
  },
  sv: {
    start: 'Start',
    share: 'Dela och tjäna',
    discover: 'Utforska',
    loggedInAs: 'Inloggad som: ',
    signOut: 'Logga ut',
    mySponsor: 'Sponsringar',
    shareAnalytics: 'Analys',
    share: 'Dela',
    logout: 'Logga ut',
    wiki: 'Wiki'
  }
});
