import React from 'react';
import { useHistory } from 'react-router-dom';
import { StyleSheet, View, Text, Image, TouchableOpacity } from 'react-native';
import LocalizedStrings from 'react-localization';
import {
  colors,
  fonts,
  fontsize,
  gutters,
  mediaQueries,
  useMobileSize,
} from '../../theme';

export default function Footer() {
  const history = useHistory();
  const isMobile = useMobileSize();

  return (
    <View
      style={{
        backgroundColor: '#1A1535',
        width: '100%',
        alignItems: 'center',
        paddingVertical: 40,
      }}
    >

      <View
        style={{
          width: '100%',
          maxWidth: mediaQueries.tablet,
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          flexShrink: 1,
          marginTop: 40,
          paddingHorizontal: isMobile ? gutters.pageGutter * 2 : 0,
        }}
      >
        <View>
          <Text style={styles.sectionTitle}>{strings.learn}</Text>
          {/* <TouchableOpacity onPress={() => history.push(`/faq`)}>
            <Text style={styles.linkStyle}>{strings.faq}</Text>
          </TouchableOpacity> */}
          <TouchableOpacity onPress={() => history.push(`/wiki`)}>
            <Text style={styles.linkStyle}>{strings.wiki}</Text>
          </TouchableOpacity>
        </View>
        <View style={{ marginTop: isMobile ? 40 : 0 }}>
          <Text style={styles.sectionTitle}>{strings.policies}</Text>
          <TouchableOpacity
            onPress={() =>
              window.open('https://veoworld.org/org/privacypolicy.html', '_blank')
            }
          >
            <Text style={styles.linkStyle}>{strings.privacyPolicy}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              window.open('https://veoworld.org/org/termsofuse.html', '_blank')
            }
          >
            <Text style={styles.linkStyle}>{strings.termsOfUse}</Text>
          </TouchableOpacity>
        </View>
        <View style={{ marginTop: isMobile ? 40 : 0 }}>
          <Text style={styles.sectionTitle}>{strings.other}</Text>
          <TouchableOpacity
            onPress={() =>
              window.open('https://veoworld.org/contactus.html', '_blank')
            }
          >
            <Text style={styles.linkStyle}>{strings.contact}</Text>
          </TouchableOpacity>
        </View>
      </View>
      <Text
        style={{
          color: colors.white,
          fontSize: fontsize.small,
          fontFamily: fonts.regular,
          marginTop: 40,
        }}
      >
        {strings.veoRightsReserved}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  linkStyle: {
    color: colors.white,
    fontSize: fontsize.medium,
    textDecorationLine: 'underline',
    fontFamily: fonts.medium,
    marginTop: 10,
  },
  sectionTitle: {
    color: colors.white,
    fontSize: fontsize.xsmall,
    fontFamily: fonts.regular,
  },
  logo: {
    width: 44,
    height: 16.5,
  },
});

const strings = new LocalizedStrings({
  en: {
    learn: 'Learn',
    faq: 'FAQ',
    wiki: 'Wiki',
    policies: 'Policies',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use (EULA)',
    other: 'Other',
    contact: 'Contact',
    veoRightsReserved: 'VEO World Europe LTD © 2024 All Rights Reserved',
  },
  sv: {
    learn: 'Information',
    faq: 'Vanliga frågor',
    wiki: 'Wiki',
    policies: 'Villkor och policy',
    privacyPolicy: 'Integritetspolicy',
    termsOfUse: 'Användarvillkor (EULA)',
    other: 'Övrigt',
    contact: 'Kontakt',
    veoRightsReserved: 'VEO World Europe LTD © 2024 All Rights Reserved',
  },
});
