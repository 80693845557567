import React from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import LocalizedStrings from 'react-localization';
import { signout } from '../../helpers/auth';
import config from '../../config';

import { fonts, colors } from '../../theme';

import veoLogo from '../../assets/icons/veoLogo-orange.png';
import logoWhite from '../../assets/images/veoLogo-white.png';
const { showDiscover } = config;

const TransparentTopBar = ({ user }) => {
  const client = useApolloClient();
  const history = useHistory();
  const { path } = useRouteMatch();
  const location = useLocation();
  const isFestival = location.pathname.includes('festival');

  return (
    <View
      style={{
        paddingVertical: 20,
        paddingHorizontal: 20,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <TouchableOpacity onPress={() => history.push('/')}>
        <Image
          style={{
            width: 44,
            height: 16.5,
          }}
          source={isFestival ? logoWhite : veoLogo}
        />
      </TouchableOpacity>
      {user && !user.hasNotSignedUp && (
        <TouchableOpacity
          style={{
            backgroundColor: colors.black,
            alignItems: 'center',
            justifyContent: 'center',
            padding: 5,
            borderRadius: 4,
          }}
          onPress={() =>
            user && !user.hasNotSignedUp
              ? signout({ client, history, path })
              : history.push(showDiscover ? '/signup' : '/sponsor/signup')
          }
        >
          <Text
            style={{
              fontFamily: '',
              color: colors.white,
              fontSize: 14,
            }}
          >
            {user && !user.hasNotSignedUp ? strings.signOut : strings.signup}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

const strings = new LocalizedStrings({
  en: {
    signin: 'Sign in',
    signup: 'Sign up',
    signOut: 'Sign out',
  },
  sv: {
    signin: 'Logga in',
    signup: 'Skapa konto',
    signOut: 'Logga ut',
  },
});

export default TransparentTopBar;
