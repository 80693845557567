import React from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { fonts, colors } from '../../theme';

const RadioButton = ({ selected, label, onPress, buttonStyle, txtStyle }) => (
  <TouchableOpacity onPress={onPress} style={[styles.inputRow, buttonStyle]}>
    <View
      style={{
        borderColor: colors.black,
        height: 16,
        width: 16,
        borderRadius: 8,
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {selected && (
        <View
          style={{
            height: 10,
            width: 10,
            borderRadius: 5,
            backgroundColor: colors.black,
          }}
        />
      )}
    </View>
    <Text
      style={[
        {
          colo: colors.black,
          fontFamily: fonts.regular,
          fontSize: 14,
          marginLeft: 10,
        },
        txtStyle
      ]}
    >
      {label}
    </Text>
  </TouchableOpacity>
);

export default RadioButton;

const styles = StyleSheet.create({
  inputRow: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
});
